import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import sale from "../../assets/icon/list-for-sale.svg";
import transfer from "../../assets/icon/transfer.svg";
import copyLink from "../../assets/icon/link.svg";
import view from "../../assets/icon/eye.svg";
import edit from "../../assets/icon/edit.svg";
import share from "../../assets/icon/share.svg";
import report from "../../assets/icon/report.svg";
import refresh from "../../assets/icon/refresh-metadata.svg";
import { useNavigate } from "react-router-dom";
import art from "../../assets/images/artwork2.png";
import Modal from "react-bootstrap/Modal";
import Button from "../button";
import Input from "../inputs";
import wallet from "../../assets/icon/wallet.svg";
import error from "../../assets/icon/close-small.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import tick from "../../assets/icon/tick-small-white.svg";

import Web3 from "web3";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import { handleCollectionHistory } from "../../firebase/firebase";
import { useSwitchNetwork, useNetwork } from "wagmi";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useAccount } from "wagmi";
import { NFTContext } from "../context/appContext";
import {
  getCollectionDetailsFirebase,
  saveArtworkHistory,
  getUserData,
  getAllUsers,
} from "../../firebase/firebase";

import { toast } from "react-toastify";
import { add } from "lodash";
import InputBox from "./inputBox";
const Dropdown = ({
  listed,
  item,
  nftDetails,
  row,
  owner,
  tokenId,
  LocationArt,
}) => {
  console.log(nftDetails, "owner in dropdown");

  const { address } = useAccount();
  const [contractAddress, setContractAddress] = useState("");
  const [price, setPrice] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [Address, setAddress] = useState("");
  const [ArtName, setArtName] = useState(null);
  const [ArtImage, setArtImage] = useState(null);
  const [transferStatus, setTransferStatus] = useState("");
  const [user, setUser] = useState(null);
  const { reloadAfterTransfer, updateTransfer } = useContext(NFTContext);
  const [details, setDetails]=useState([]);
  useEffect(()=>{
    if(nftDetails){
      console.log(nftDetails,"nft detailssssssssss");
      setDetails(nftDetails);
    }

  },[nftDetails])
  const getUser = async () => {
    let res = await getUserData(address);
    console.log(res);
    setUser(res);
  };

  useEffect(() => {
    getUser();
  }, [address]);

  useEffect(() => {
    if (LocationArt === "singleArt") {
      if (item?.metadata?.data.image) {
        setArtImage(item?.metadata?.data.image);
      } else {
        setArtImage(item?.metadata?.data.previewImg);
      }
      setArtName(item?.metadata?.data.artName);
    } else {
      if (item?.data.image) {
        setArtImage(item?.data.image);
      } else {
        setArtImage(item?.data.previewImg);
      }
      setArtName(item?.data.artName);
    }
  }, [item, nftDetails, LocationArt]);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const getCollectionDetails = async () => {
    console.log(item?.selectedCollectionId, "selected collection id");
    try {
      try {
        let result;
        if (LocationArt === "singleArt") {
          // alert("here");
          result = await getCollectionDetailsFirebase(
            item?.selectedCollectionId
          );
          // alert(result?.contractAddress);
          setContractAddress(result?.contractAddress);
        } else {
          result = await getCollectionDetailsFirebase(
            item?.data.selectedCollectionId || item?.selectedCollectionId
          );
        }

        console.log(result, "tesult");
        if (result) {
          if (owner === address) {
            setIsOwner(true);
            setContractAddress(result?.contractAddress);
          }
        } else {
          setIsOwner(false);
          console.log("Error: No result returned");
        }
      } catch (error) {
        console.log("Error fetching collection details:", error);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getCollectionDetails();
  }, [item, Address]);

  const location = useLocation();

  const Backend_url = process.env.REACT_APP_BACKEND_URL;
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = mintContractABI;
  // const web3 = new Web3(window.ethereum);

  const [web3, setWeb3] = useState(null);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const transferOwner = async (item) => {
    console.log(item, "item from list");

    setArtTransfer(true);
  };
  const transferArt = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();

      let contractAddress;
      let collectionId;

      if (LocationArt === "singleArt") {
        const result = await getCollectionDetailsFirebase(
          item?.metadata?.data.selectedCollectionId
        );
        collectionId = item?.metadata?.data.selectedCollectionId;
        contractAddress = result?.contractAddress;
      } else {
        const result = await getCollectionDetailsFirebase(
          item?.data.selectedCollectionId
        );
        collectionId = item?.data.selectedCollectionId;
        contractAddress = result?.contractAddress;
      }

      console.log(collectionId);
      console.log(contractAddress);
      console.log(address);
      console.log(Address);
      console.log(tokenId);
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      const transaction = contract.methods.transferFrom(
        address,
        Address,
        tokenId
      );

      const txResult = await transaction.send({
        from: accounts[0],
        to: Address,
        data: transaction.encodeABI(),
        gasLimit: 3000000,
      });

      let transactionHash = txResult.transactionHash;

      const data = {
        action: "transfer",
        artworkUri: item?.metadata?.data,
        from: accounts[0],
        to: Address,
        tokenId: tokenId,
        transactionHash: transactionHash,
      };

      let action = "transfer";
      let artId = item?.metadata?.data.artId;
      let username = user.userName;
      let to = Address;

      saveArtworkHistory(
        transactionHash,
        address,
        action,
        username,
        artId,
        null,
        to
      );
      handleCollectionHistory(collectionId, data);
      setTransferStatus("success");
      console.log("hererrrrrrrrrr");
      updateTransfer(true);
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error("Transaction Failed!");
    }
  };

  useEffect(() => {
    console.log(transferStatus, "transfer status");
  }, [transferStatus]);
  const handleCancelList = async () => {
    const web3 = new Web3(window.ethereum);
    let MarketplaceAddress;
    const accounts = await web3.eth.getAccounts();

    let auctionListId;

    if (LocationArt === "singleArt") {
      if (item?.metadata?.data.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (item?.metadata?.data.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      auctionListId = item.listCount;
    } else {
      if (item?.data.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (item?.data.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      auctionListId = item.listCount;
    }

    console.log(auctionListId);

    const contract = new web3.eth.Contract(
      marketplaceContractABI,
      MarketplaceAddress
    );

    try {
      const transaction = contract.methods.CancelListForSale(auctionListId);
      console.log("data", transaction.encodeABI());
      const result = await transaction.send({
        from: accounts[0],
      });
      console.log("result", result);
      setEditListModal(false);
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };

  const pathToHideDiv = "/artwork";
  const shouldHideDiv = location.pathname === pathToHideDiv;
  const navigate = useNavigate();
  const [artTransfer, setArtTransfer] = useState(false);

  const [editListModal, setEditListModal] = useState(false);
  const [changePrice, setChangePrice] = useState(false);

  const handleEditListModal = () => setEditListModal(false);
  const handleArtTransfer = () => {
    setArtTransfer(!artTransfer);
    setTransferStatus("");
  };

  const showEditListing = () => {
    setEditListModal(true);
  };
  const navigateToSingleArtwork = (item) => {
    console.log(item, "item");

    console.log("navigateToSingleArtwork", item.data);

    const formatData = {
      ...item,
      meta: {
        data: item.data,
      },
    };

    console.log("formatData", formatData);

    navigate(
      `/artwork/${item.data.selectedBlockchain}/${item.data.selectedCollectionId}/${tokenId}`
    );
  };

  const copyLinkToClipboard = () => {
    const link = `${window.location.href}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied");
      })
      .catch((error) => {
        toast.warning("Link copying Failed", error);
      });
  };

  const HandlechangePrice = async (item) => {
    console.log(item, "value of item");

    const web3_1 = new Web3(window.ethereum);
    const accounts = await web3_1.eth.getAccounts();
    console.log(accounts[0], "accounts");

    let MarketplaceAddress;
    if (item.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (item.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    const contract = new web3_1.eth.Contract(
      marketplaceContractABI,
      MarketplaceAddress
    );

    console.log(price);
    const priceInwei = web3_1.utils.toWei(price.toString(), "ether");
    console.log(priceInwei);

    const data = contract.methods.editListForSale(item.listCount, priceInwei);

    const transaction = contract.methods.editListForSale(
      item.listCount,
      priceInwei
    );

    console.log("data", transaction.encodeABI());

    try {
      const result = await transaction.send({
        from: accounts[0],
        to: MarketplaceAddress,
        data: data.encodeABI(),
        gasLimit: 3000000,
      });
      console.log("result", result);
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };

  const [artistName, setArtistName] = useState("");

  const getArtistNamebyAdress = async (address) => {
    let artistName;
    let res = await getAllUsers();
    res?.forEach((user) => {
      if (user?.id === address) {
        artistName = user?.userName;
      }
    });
    setArtistName(artistName);
  };
  useEffect(() => {
    if (transferStatus === "success") {
      getArtistNamebyAdress(Address);
    }
  }, [transferStatus, Address]);
  useEffect(() => {
    console.log(isOwner, "is owner");
    console.log(owner, "owner");
    console.log(address, "addres");
    console.log(nftDetails, "nft details");
  }, [isOwner, owner, address,nftDetails]);
  return (
    <div>
      {owner !== address && row !== "row" ? (
        <div className="collection-option">
          <div className="collection-item">
            <img src={report} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Report
            </label>
          </div>

          <div
            onClick={() => copyLinkToClipboard(item)}
            className="collection-item"
          >
            <img src={share} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Share
            </label>
          </div>
          <div className="collection-item  ">
            <img src={refresh} alt="sale" className="img-18" />
            <label className="text-black no-text-transform ms-2 pointer">
              Refresh Metadata
            </label>
          </div>
          {row == "row" && (
            <div
              onClick={() => navigateToSingleArtwork(item)}
              className="collection-item"
            >
              <img src={view} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                View item
              </label>
            </div>
          )}
          {/* <div onClick={() => copyLinkToClipboard(item)} className="collection-item">
            <img src={copyLink} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Copy link
            </label>
          </div> */}
          {isOwner && (
            <div onClick={showEditListing} className="collection-item">
              <img src={edit} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Edit Listing
              </label>
            </div>
          )}
        </div>
      ) : (
        <div className="collection-option">
          {row !== "row" && owner === address && listed === "noListed" && (
            <>
              <div
                onClick={() => {
                  navigate(`/list-forSale`, {
                    state: {
                      data: LocationArt === "singleArt" ? item?.metadata?.data : item?.data,
                      nftDetails: details
                    },
                  });
                }}

                className="collection-item"
              >
                <img src={sale} alt="sale" />
                <label className="text-black no-text-transform ms-2 pointer">
                  List for sale
                </label>
              </div>
            </>
          )}
          {row == "row" && listed === "noListed" && (
            <>
              <div
                onClick={() => {
                  navigate(`/list-forSale`, {
                    state: {
                      data: LocationArt === "singleArt" ? item?.metadata?.data : item?.data,
                      nftDetails: details,
                    },
                  });
                }}
                className="collection-item"
              >
                <img src={sale} alt="sale" />
                <label className="text-black no-text-transform ms-2 pointer">
                  List for sale
                </label>
              </div>
            </>
          )}
          {listed === "listed" && (
            <div onClick={showEditListing} className="collection-item">
              <img src={edit} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Edit Listing
              </label>
            </div>
          )}
          {listed === "listed" && (
            <div className="collection-item  ">
              <img src={refresh} alt="sale" className="img-18" />
              <label className="text-black no-text-transform ms-2 pointer">
                Refresh Metadata
              </label>
            </div>
          )}
          {listed === "noListed" && (
            <div
              onClick={() => transferOwner(item)}
              className="collection-item"
            >
              <img src={transfer} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Transfer
              </label>
            </div>
          )}

          <div
            onClick={() => copyLinkToClipboard(item)}
            className="collection-item"
          >
            <img src={copyLink} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Copy link
            </label>
          </div>
          {row == "row" && (
            <div
              onClick={() => navigateToSingleArtwork(item)}
              className="collection-item"
            >
              <img src={view} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                View item
              </label>
            </div>
          )}
          {/* <div onClick={() => navigateToSingleArtwork(item)} className="collection-item">
            <img src={view} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              View item
            </label>
          </div> */}
        </div>
      )}

      {/* Edit listing */}

      <Modal
        show={editListModal}
        onHide={handleEditListModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              {/*  -----  Edit Listing   ----- */}
              EDIT LISTING
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div
            className={`buy-now-modal w-431 m-auto ${changePrice ? "d-none" : " "
              }`}
          >
            <div className="listed-item  ">
              <div className="v-center">
                <div className="left">
                  <img src={ArtImage} alt="img" className="img-100px" />
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    {ArtName}
                  </label>
                  <p className="body-medium">Untitled Collection #7156351271</p>
                  <h6 className="fw-bold fst-normal mt-2">7.51k FLR</h6>
                </div>
              </div>
            </div>

            <p className="mt-30 text-center">
              Would you like to change the price of the listing or cancel the
              listing and remove it from sale?
            </p>
            {/* User has the option to CHANGE PRICE or CANCEL LISTING	 */}
            <div className="v-center h-center gap-3 mt-5 w-100">
              <Button
                text="Change price"
                className="btn-prime btn-primary"
                width="156px"
                height="36px"
                onClick={() => setChangePrice(true)}
              />
              <Button
                text="Cancel listing"
                className="btn-prime btn-secondary"
                width="156px"
                height="36px"
                onClick={handleCancelList}
              />
            </div>
          </div>

          {/* Cancel Listing */}

          <div className="buy-now-modal w-431 m-auto d-none">
            <div className="h-center ">
              <img
                src={art}
                alt="img"
                className="img-65 border-solid-black pad-2"
              />
            </div>
            <p className="mt-40 text-center">
              Cancel listing:
              <strong> Artwork</strong>
            </p>
            <p className="mt-30 text-center">
              Are you sure you want to cancel this listing?
            </p>

            {/*  listing	cancelantion warinng */}
            <div className="v-center h-center gap-3 mt-5 w-100 ">
              <Button
                text="Go back"
                className="btn-prime btn-primary"
                width="156px"
                height="36px"
                onClick={(e) => toast("Go Back")}
              />
              <Button
                text="Yes, cancel listing"
                className="btn-prime btn-secondary text-error bg-error-20 border-0"
                width="156px"
                height="36px"
                onClick={(e) => toast("Yes, cancel listing")}
              />
            </div>
          </div>

          {/* Changing price */}

          {
            <div
              className={`buy-now-modal w-431 m-auto ${changePrice ? " " : "d-none"
                }`}
            >
              <div className="h-center ">
                <img
                  src={ArtImage}
                  alt="img"
                  className="img-65 border-solid-black pad-2"
                />
              </div>
              <p className="mt-40 text-center">
                Set new price for <strong> {ArtName}:</strong>
              </p>
              {/* price should only be displayed once the user has inputed a numerical
            value into the field */}
              <div className="pricing bg-transparent border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between ">
                <Input
                  className="body-medium  ps-2 text-black bg-transparent br-30  w-100  "
                  placeholder="Price"
                  type="number"
                  value={price}
                  onChange={handlePriceChange}
                />
                <span>FLR</span>
              </div>
              <p className="mt-2 body-medium text-medium-grey">~ $187.52</p>
              {/* User has the option to CHANGE PRICE or CANCEL LISTING	 */}
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Continue"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => HandlechangePrice(item)}
                />
              </div>
            </div>
          }

          {/* Waiting for blockchain wallet confirmation… */}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={wallet} alt="wallet" className="img-100" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Confirm this transaction in your wallet.
            </p>

            <p className="body-medium fw-bold text-center mt-30">
              Waiting for blockchain confirmation…
            </p>

            <Button
              text=""
              className="btn-prime btn-primary mt-50"
              width="138px"
              height="36px"
              imageSrc={loader}
              imageClassName="rotate-360"
            />
          </div>

          {/* if error*/}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={error} alt="wallet" className="img-100" />
            </div>

            {/* onChanging price */}
            <p className=" text-center body-medium  mt-3 pt-1 ">
              Sorry, we could change the price for{" "}
              <span className="fw-bold"> "Artwork Name"</span>
            </p>
            {/* on caneling listing error */}
            <p className=" text-center body-medium  mt-3 pt-1 ">
              Sorry, we couldnt couldn't cancel your listing
              <span className="fw-bold"> "Artwork Name"</span>
              at this time.
            </p>
            <Button
              text="Try again"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              imageSrc={refresh}
              imageClassName="me-2 img-18"
              onClick={() => toast("Trying Again")}
            />
          </div>

          {/* on success */}

          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none ">
            <div className="wallet h-center m-auto">
              <img src={tick} alt="tick" className="img-100 invert1" />
            </div>
            {/* on updateing price */}
            <p className=" text-center body-medium  mt-3 pt-1 ">
              Successfully updated price for
              <span className="fw-bold"> "Artwork Name" </span>!
            </p>

            {/* on Canceling price */}
            <p className=" text-center body-medium  mt-3 pt-1 ">
              Successfully cancelled your listing
              <span className="fw-bold"> "Artwork Name" </span>!
            </p>
            <Button
              text="Done"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              onClick={handleEditListModal}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Transfer Owner */}

      <Modal
        show={artTransfer}
        onHide={handleArtTransfer}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Transfer
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {transferStatus === "" && (
            <div className="art-transfer v-center flex-column pt-5 mt-2">
              <div className="upload-file mt-40">
                <img src={ArtImage} alt="art" className="img-100px border" />
              </div>

              <p className="body-medium mt-40 mb-3 pb-3">
                Transfer <span className="fw-bold">“{ArtName}” </span> to
              </p>
              {/* <p className="body-medium mt-40 mb-3 pb-3">
                Transfer <span className="fw-bold">“{ArtName}” </span> to
              </p> */}

              <div style={{ maxWidth: "487px" }} className="w-100  ">
                <InputBox
                  type="text"
                  placeholder="Address e.g 0x1H3a..."
                  labelClass="d-none"
                  value={Address}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="h-center gap-3 mt-5 mb-70">
                <Button
                  text="Cancel"
                  className="btn-prime btn-secondary"
                  width="131px"
                  height="36px"
                  onClick={handleArtTransfer}
                />{" "}
                {row == "row" ? (
                  <Button
                    text="Transfer"
                    className={`btn-prime border-0 ${Address.length > 0 ? "btn-primary" : "btn-ternary"
                      }`}
                    width="131px"
                    height="36px"
                    onClick={transferArt}
                    disabled={!Address.length}
                  />
                ) : (
                  <Button
                    text="Transfer"
                    className={`btn-prime border-0 ${Address.length > 0 ? "btn-primary" : "btn-ternary"
                      }`}
                    width="131px"
                    height="36px"
                    onClick={transferArt}
                    disabled={!Address.length}
                  />
                )}
              </div>
            </div>
          )}

          {transferStatus === "success" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center  ">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Successfully tranfered your listing {ArtName} to {artistName}
              </p>

              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleArtTransfer}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dropdown;
