import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import Button from "../button";
import Accordion from "react-bootstrap/Accordion";
import tick from "../../assets/icon/tick-small-white.svg";
import wallet from "../../assets/icon/wallet.svg";
import error from "../../assets/icon/close-small.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import refresh from "../../assets/icon/refresh-metadata-white.svg";
import art from "../../assets/images/artwork2.png";
import art1 from "../../assets/images/artwork.jpg";
import like from "../../assets/icon/likes-large 1.svg";
import liked from "../../assets/icon/liked.svg";
import FLR from "../../assets/icon/FLR.svg";
import SGB from "../../assets/icon/SGB.svg";
import option from "../../assets/icon/more-horizontal.svg";
import offerMade from "../../assets/icon/offer-made.svg";
import listed from "../../assets/icon/list-for-sale.svg";
import arrow from "../../assets/icon/arrow-angle-right-grey.svg";
import verified from "../../assets/icon/verified-artist.svg";
import Modal from "react-bootstrap/Modal";
import Input from "../inputs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import UserProfile from "../shared/userProfile";
import thumb from "../../assets/icon/profile-picture.svg";
import { useTagContext } from "./TagContext";
import Dropdown from "../shared/dropdown";
import ArtistPopUp from "../shared/artistpopup";
import CustomCheckBox from "../shared/customTags";
import useScrollToTop from "../../customHooks/scrollToTop";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/marketplace.json";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import axios, { all } from "axios";
import { useSearchParams } from "react-router-dom";
import { NFTContext } from "../context/appContext";
import _, { add } from "lodash";
import mintAddress from "../../abis/erc721.json";

import Web3 from "web3";

import {
  getNftDetailsFirebase,
  handleFavorite,
  handleNotifications,
  handleCollectionHistory,
  getCollectionStats,
  saveCollectionStats,
  saveLikedArtwork,
  getAllLikedArtwork,
  saveArtistStats,
  getCollectionDetailsFirebase,
  getAllUsers,
  saveArtworkHistory,
  getArtworkHistoryById,
  getUserDataByUserName,
  saveArtLastPriceToFirebase,
  getArtLastPriceFromFirebase,
  saveOfferHash,
  getOffersByArtId,
  getArtIdByUrl,
  getUserData,
} from "../../firebase/firebase";

import { useSwitchNetwork, useNetwork, useFeeData, useBalance } from "wagmi";
import { useAccount } from "wagmi";
import { Popover, Skeleton } from "antd";
import { Image } from "antd";
import InputBox from "../shared/inputBox";
import erc20abi from "../../abis/erc20.json";
import { transactionType } from "viem";

const SingleArt2 = () => {
  useScrollToTop();


  const { setTagName } = useTagContext();
  const { artModal, udpateArtModal } = useContext(NFTContext);
  const { showModal, updateModal } = useContext(NFTContext);
  const { reloadAfterTransfer, updateTransfer, updateCreated } =
    useContext(NFTContext);

  const location = useLocation();
  const { address } = useAccount();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [createFinish, setCreateFinish] = useState(false);
  const [transferAddress, setTransferAddress] = useState("");
  const [artTransfer, setArtTransfer] = useState(false);
  const [buyArtModal, setBuyArtModal] = useState(false);
  const [makeOfferModal, setMakeOfferModal] = useState(false);
  const [bidModal, setBidModal] = useState(false);
  const [editListModal, setEditListModal] = useState(false);
  const [finishListing, setFinishListing] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [offerStatus, setOfferStatus] = useState("");
  const [disableOfferBtn, setDisableOfferBtn] = useState(true);
  const [buyStatus, setBuyStatus] = useState("");
  const [lastPrice, setLastPrice] = useState(null);
  const [listingModal, setListingModal] = useState("initial");
  const [connectedAccount, setConnectedAccount] = useState(false);
  const [offerHistory, setOfferHistory] = useState([]);
  const [listedType, setListedType] = useState(0);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectUserAddress, setSelectUserAddress] = useState(null);
  const [isClaimUser, setIsClaimUser] = useState(false);
  const [selectedbid, setSelectedBid] = useState(null);
  const [owner, setOwner] = useState(false);
  const [nftUri, setNftUri] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [state, setState] = useState([]);
  const [isNftApproved, setIsNftApproved] = useState(false);
  const [bidUserDetail, setBidUserDetail] = useState([]);
  const [inCompleteProfile, setInCompleteProfile] = useState(true);
  const [artId, setArtId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [artworkHistory, setArtworkHistory] = useState([]);
  const web3_1 = new Web3(window.ethereum);
  const [isNewtworkConnected, setIsNewtworkConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [offerPrice, setOfferPrice] = useState(0);
  const [searchParams] = useSearchParams();
  const [collectionId, setCollectionId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [biddingCount, setBiddingCount] = useState(null);
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { id } = useParams();
  const username = localStorage?.getItem("userName");
  const [isLiked, setIsLiked] = useState(false);
  const [nftDetails, setNftDetails] = useState(null);
  const [nftCollectionDetails, setNftCollectionDetails] = useState([]);
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);

  const [approveTokens, setApproveTokens] = useState(false);

  const [isSaleListed, setIsSaleListed] = useState(null);
  const [isOfferListed, setIsOfferListed] = useState(null);

  // marketplace contract address
  const Backend_url = process.env.REACT_APP_BACKEND_URL;
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  const coston_Token = process.env.REACT_APP_COSTON_TOKEN_CONTRACTADDRESS;
  const coston2_Token = process.env.REACT_APP_COSTON2_TOKEN_CONTRACTADDRESS;

  useEffect(() => {
    console.log(coston2_Token, coston_Token);
  }, [coston_Token, coston2_Token]);

  // setting rpc url
  useEffect(() => {
    if (nftData[0]?.metadata?.data?.selectedBlockchain === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    }
    if (nftData[0]?.metadata?.data?.selectedBlockchain === "Coston2") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [nftData, location.pathname]);

  const handleCreateFinish = () => {
    udpateArtModal();
    setCreateFinish(!createFinish);
  };

  const handleFinishListing = () => {
    updateModal(true);
    setFinishListing(false);
  };

  const handleArtTransfer = () => setArtTransfer(!artTransfer);

  useEffect(() => {
    console.log(artModal);
    if (artModal === true) {
      setCreateFinish(true);
    }

    if (showModal === true) {
      setFinishListing(true);
    }
  }, [artModal, showModal]);

  const handleBuyArtModal = () => {
    if (inCompleteProfile) {
      toast.info("Your profile is not completed!");
      return;
    }
    setBuyStatus("");
    setBuyArtModal(!buyArtModal);
  };
  const handleOfferModal = () => {
    setOfferStatus("");
    setMakeOfferModal(!makeOfferModal);
    setOfferPrice(null);
  };

  const handleBidModal = () => setBidModal(!bidModal);
  const handleEditListModal = () => {
    setEditListModal(!editListModal);
    setListingModal("initial");
  };

  // getting user data from firebase

  const [newPrice, setNewPrice] = useState("");
  useEffect(() => {
    console.log(bidUserDetail, "bid details");
  }, [bidUserDetail]);

  // get artwork history from firebase
  useEffect(() => {
    const getHistory = async () => {
      let res = await getArtworkHistoryById(artId);
      const reversedHistory = res.history.reverse();
      setArtworkHistory(reversedHistory);
    };

    getHistory();
  }, [artId]);

  // get all user data from firebase
  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      if (users) {
        for (let a of users) {
          if (a?.id === address) {
            if (a.userName) {
              setInCompleteProfile(false);
            }
          }
        }
      }


      setUserData(users);
    };
    fetchUserData();
  }, []);

  // set selected blockchain
  useEffect(() => {
    if (nftData[0]?.metadata.data?.selectedBlockchain === chain?.name) {
      setIsNewtworkConnected(true);
    } else {
      setIsNewtworkConnected(false);
    }
  }, [chain, state]);

  // switch network
  const handleSwitchChain = async () => {
    const switchTo = chains.find(
      (c) => c.name === nftData[0]?.metadata.data.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };

  useEffect(() => {
    if (location.pathname === '/mint-art') {
      navigate('/');
    }
  }, [location, navigate]);

  window.onpopstate = () => {
    // alert("here");
    navigate("/");
  }

  useEffect(() => {
    setConnectedAccount(address);
  }, [state, nftData]);

  const handlePriceChange = (e) => {
    console.log(e.target.value < 0, "testing");
    setShowError(false);
    setOfferPrice(e.target.value);

    setDisableOfferBtn(e.target.value <= 0);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const toggleFullScreen = () => {
    if (windowWidth >= 1024) {
      setIsFullScreen(!isFullScreen);
    } else setIsFullScreen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // get collection id and token id from path
  useEffect(() => {
    let result1 = location.pathname.split("/")[3];
    let result2 = location.pathname.split("/")[4];

    setCollectionId(result1);
    setTokenId(result2);
  }, [location.pathname]);

  // check if the nft is approved or not from blockchain
  const checkIfNftsApproved = async () => {
    let web3_1;

    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    let MarketplaceAddress;
    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    if (web3_1 !== null) {
      const contract = new web3_1.eth.Contract(
        mintContractABI,
        collectionDetails?.contractAddress
      );

      const data = await contract.methods
        .isApprovedForAll(address, MarketplaceAddress)
        .call();
      return data;
    }
  };

  // update price function
  const HandlechangePrice = async () => {
    const accounts = await web3_1.eth.getAccounts();
    console.log(accounts[0], "accounts");

    let MarketplaceAddress;
    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    console.log(newPrice);
    const priceInwei = web3_1.utils.toWei(newPrice.toString(), "ether");
    console.log(priceInwei);

    const data = contract.methods.editListForSale(
      state[0]?.saleCountIndex,
      priceInwei
    );

    const transaction = contract.methods.editListForSale(
      state[0]?.saleCountIndex,
      priceInwei
    );

    console.log("data", transaction.encodeABI());

    try {
      const result = await transaction.send({
        from: accounts[0],
        to: MarketplaceAddress,
        data: data.encodeABI(),
        gasLimit: 3000000,
      });

      setListingModal("successPrice");
      getAllListNFTData();
      handlecollectionStatsListing(
        accounts[0],
        newPrice,
        nftData[0]?.metadata.data.selectedBlockchain,
        nftData[0]?.metadata.data.artId
      );

      console.log("result", result);
    } catch (error) {
      setListingModal("error");

      toast.error("Transaction Failed!");
    }
  };

  // check if nft is approved or not
  const getstatus = async () => {
    let res = await checkIfNftsApproved();
    console.log(res, "response for approved data");
    setIsNftApproved(res);
  };

  useEffect(() => {
    getstatus();
  }, [collectionDetails]);

  useEffect(() => {
    console.log(nftData[0]?.metadata.data, "listed nft data in single art");
  }, [state[0]]);

  // approve nft from blockchain

  const ApproveAllNFT = async () => {
    setLoading(true);
    const web3_1 = new Web3(window.ethereum);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    if (web3_1) {
      const contract = new web3_1.eth.Contract(
        mintContractABI,
        collectionDetails?.contractAddress
      );

      const data = contract.methods.setApprovalForAll(MarketplaceAddress, true);

      const transaction = contract.methods.setApprovalForAll(
        MarketplaceAddress,
        true
      );

      setLoading(false);

      const result = await transaction.send({
        from: accounts[0],
        to: MarketplaceAddress,
        data: data.encodeABI(),
        gasLimit: 3000000,
      });

      setLoading(false);

      if (result) {
        checkIfNftsApproved();
        toast.success("Nfts Approved successfully!");
        setLoading(false);

        getAllListNFTData();

        getstatus();
      }
    }
  };

  // handle buy nft function
  const handleBuyNow = () => {
    if (BuyNow()) {
      // navigate("/explore");
    }
  };

  // make offer function
  const handleMakeAnOffer = () => {
    setOfferStatus("confirming");
    let minimumBid = 0;

    // Convert offerPrice and minimumBid to numbers
    let price = parseFloat(offerPrice);

    if (price > Number(data?.formatted)) {
      toast.info("Insufficient balance!");
      return;
    }

    minimumBid = parseFloat(minimumBid);

    // Check if both offerPrice and minimumBid are valid numbers
    if (!isNaN(price) && !isNaN(minimumBid)) {
      // Compare offerPrice with minimumBid
      if (price < minimumBid) {
        // Show error message
        toast.error(
          `Offer price ${price} is lower than the minimum bid ${minimumBid}`
        );
        // Set showError state to true if needed
        setShowError(true);
        // Return or perform any other necessary action
        return;
      } else {
        // Offer price meets the minimum bid requirement
        // Proceed with further actions if needed

        if (makeOffer()) {
          // window.location.reload();
        }
      }
    } else {
      toast.error(`Invalid offer price ${price} or minimum bid ${minimumBid}`);
    }
  };

  useEffect(() => {
    console.log(offerStatus, "offer status");
  }, [offerStatus]);

  useScrollToTop();

  // get like data
  const getAllLikes = async () => {
    const result = await getAllLikedArtwork(address);
  };

  useEffect(() => {
    getAllLikes();
    const checkLike = async () => {
      if (username) {
        const result = await getAllLikedArtwork(address);
        if (result?.likedArtworks?.includes(nftData[0]?.uriData)) {
          setIsLiked(true);
        } else {
          setIsLiked(false);
        }
      }
    };
    checkLike();
  }, [username]);

  // add nft to favourite

  const handleLikeClick = async () => {
    try {
      const result = await saveLikedArtwork(address, state[0]?.uri);

      if (result) {
        getAllLikes();
        toast.success("Artwork added to your favourites");
        setIsLiked(true);
      } else {
        getAllLikes();

        setIsLiked(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while processing your request.");
    }
  };

  const getNftMetadata = async (tokens_uri) => {
    const response = await fetch(`${Backend_url}/getNFTMetadata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uri: tokens_uri,
      }),
    });
    const json = await response.json();

    return {
      data: json?.data?.data,
    };
  };
  const getAllListNFTData = async () => {
    const web3_1 = new Web3(window.ethereum);

    // const accounts = await web3.eth.getAccounts();
    if (collectionDetails && web3 != null) {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      console.log(contract.methods, "contract methods in getlisted function");
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "get listed nfts data-==============");

      setListedNfts(data);
    } else {
      setListedNfts([]);
    }
  };

  useEffect(() => {
    let res = getAllListNFTData();
  }, [collectionDetails]);

  // get toked ids of each collection

  useEffect(() => {
    const getArtId = async () => {
      let res = await getArtIdByUrl(nftUri);
      console.log(res, "art id response");
      setArtId(res);
    };
    if (nftUri) {
      getArtId();
    }
  }, [nftUri]);
  const getUserNftsFromContract = async () => {
    let web3;
    if (collectionDetails?.selectedNetwork === "Coston") {
      web3 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    let contractAddress;
    let Address;

    if (collectionDetails) {
      contractAddress = collectionDetails?.contractAddress;
      Address = collectionDetails?.address;
    }
    let singleNft = [];

    try {
      if (web3 !== null) {
        const contract = new web3.eth.Contract(
          mintContractABI,
          contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        const result = await contract.methods
          .getTokenIdsByCollection(collectionId)
          .call({ from: contractOwner });

        if (result?.length > 0) {
          for (let a of result) {
            if (a.tokenId == tokenId) {
              const tokens_uri = a.uri;
              setNftUri(tokens_uri);

              try {
                // Fetch NFT metadata
                const metadata = await getNftMetadata(tokens_uri);

                // Push the metadata to singleNft array
                singleNft.push({ uri: a.uri, metadata });

                // Set nftData to singleNft array
                console.log(singleNft, "singleNft");
                setNftData(singleNft);

                // setArtId(singleNft[0].metadata.data.artId);
                setNftDetails(singleNft[0].metadata.data);
                setIsLoading(false);
                return singleNft;
              } catch (error) {
                console.error("Error getting NFT metadata:", error);
              }
            }
          }
        } else {
          setNftUri([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getNfts = async () => {
      let res = await getUserNftsFromContract();
      // console.log(res);
    };
    getNfts();
  }, [tokenId, location.pathname, collectionDetails]);

  useEffect(() => {
    const getNfts = async () => {
      await getUserNftsFromContract();
    };
    getNfts();
  }, []);

  // get collection details from firebase

  const getCollectionDetails = async () => {
    if (collectionId) {
      const result = await getCollectionDetailsFirebase(collectionId);
      setCollectionDetails(result);
    }
  };

  useEffect(() => {
    getCollectionDetails();
  }, [collectionId]);

  // get listed data
  const getListNFTData = async () => {
    if (collectionDetails && web3 !== null) {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      console.log(address, "address");
      console.log(collectionDetails?.contractAddress, "contract address");

      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods
        .getNFTDetail(address, [`${collectionDetails?.contractAddress}`])
        .call({ from: address });
      console.log(data, "details");
      setNftCollectionDetails(data[0]);
    }
  };

  useEffect(() => {
    getListNFTData();
  }, [collectionDetails, web3]);

  useEffect(() => {
    if (web3 !== null) {
      getListNFTData();
      getAllListNFTData();
    }
  }, [collectionDetails, web3]);

  // map and set listed data
  useEffect(() => {
    console.log(nftData, "nft data");
    console.log(listedNfts[0], "sale listed nfts");
    console.log(listedNfts[1], "offer listed nfts");

    if (nftData !== undefined || listedNfts) {
      let nftListDetails = [];

      nftData?.forEach((nftItem) => {
        let isSaleListed = false;
        let isOfferListed = false;
        let saleCountIndex = null;
        let offerCountIndex = null;

        // Check for sale listings
        if (listedNfts[0]) {
          listedNfts[0]?.forEach((nft, index) => {
            // Adding index to track sale listing position
            if (nft?.uriData === nftItem?.uri) {
              isSaleListed = true;
              saleCountIndex = nft.listCount; // Store the index for sale listing
              nftListDetails.push({
                ...nft,
                ...nftItem,
                isListed: true,
                isSaleListed: true,
                saleCountIndex, // Add saleCountIndex to the object
                listedType: "listed",
              });
            }
          });
        }

        // Check for offer listings
        if (listedNfts[1]) {
          listedNfts[1]?.forEach((nft, index) => {

            // Adding index to track offer listing position
            if (nft?.uriData === nftItem?.uri) {
              isOfferListed = true;
              offerCountIndex = nft.listCount // Store the index for offer listing
              const existingIndex = nftListDetails.findIndex(
                (nftDetail) => nftDetail.uri === nftItem.uri
              );

              if (existingIndex !== -1) {
                nftListDetails[existingIndex] = {
                  ...nftListDetails[existingIndex],
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                };
              } else {
                nftListDetails.push({
                  ...nft,
                  ...nftItem,
                  isListed: true,
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                  listedType: "listed",
                });
              }
            }
          });
        }

        // Ensure both flags and indices are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === nftItem.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed,
              isOfferListed,
              saleCountIndex: isSaleListed
                ? saleCountIndex
                : nftListDetails[existingIndex].saleCountIndex,
              offerCountIndex: isOfferListed
                ? offerCountIndex
                : nftListDetails[existingIndex].offerCountIndex,
            };
          }
        }

        // If the NFT is not listed in either sale or offer listings
        if (!isSaleListed && !isOfferListed) {
          const nftCollectionDetail = nftCollectionDetails.find(
            (detail) => detail.uri === nftItem?.uri
          );

          nftListDetails.push({
            ...nftItem,
            listedData: nftCollectionDetail,
            isListed: false,
            listedType: "noListed",
          });
        }
      });

      console.log(nftListDetails, "nft list details");
      setState(nftListDetails);
    } else {
      console.log("Error");
    }
  }, [listedNfts, nftCollectionDetails, nftData]);

  useEffect(() => {
    if (address) {
      getUserNftsFromContract();
    }
  }, []);

  // get bidding history for offer
  const getOfferHistory = async () => {
    if (web3 !== null) {
      let MarketplaceAddress;
      if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      console.log(state[0], "staste datat");
      const auctionListId = state[0]?.offerCountIndex;
      console.log(auctionListId, "aunction list id");

      try {
        const result = await contract.methods
          .getBiddingHistory(auctionListId)
          .call();
        console.log(result, "result");

        // Create a mutable copy of the result array
        const mutableResult = [...result];

        // Reverse the mutable copy
        mutableResult.reverse();
        console.log(mutableResult, "offer history");

        // Set the reversed array to offerHistory state
        setOfferHistory(mutableResult);

        return result; // Return the original result if needed
      } catch (error) {
        console.error("Error fetching bidding history:", error);
        // Handle error if necessary
      }
    }
  };

  // get selected by owner  (from bidding)
  const getIsUserSelected = async () => {
    if (web3 !== null) {
      let MarketplaceAddress;
      if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const auctionListId = state[0]?.offerCountIndex;
      console.log(auctionListId);
      const mintContractAddress = collectionDetails?.contractAddress;
      console.log(tokenId, "token id");

      const result = await contract.methods.SelectedUser(auctionListId).call();


      if (result == 0) {
        setBiddingCount(null);
      } else {
        setBiddingCount(result);
      }
      console.log(result, "result.........");
      if (result) {
        const result2 = await contract.methods
          .Bidding(mintContractAddress, tokenId, "1")
          .call();
        console.log(result2, "result 2");

        setBidUserDetail(result2);
      }
      return result;
    }
  };

  useEffect(() => {
    getIsUserSelected();
  }, [nftData, state]);

  // get owner data for nft

  const getOwner = async () => {
    console.log("get owner");
    let web3 = new Web3(window.ethereum);

    let contractAddress;
    let Address;

    if (collectionDetails) {
      contractAddress = collectionDetails?.contractAddress;
      Address = collectionDetails?.address;
      console.log(Address);
      if (Address) {
        try {
          if (web3 !== null) {
            const contract = new web3.eth.Contract(
              mintContractABI,
              contractAddress
            );
            console.log(contract.methods, "contract methods");
            const contractOwner = await contract.methods.owner().call();

            try {
              const result = await contract.methods
                .ownerOf(tokenId)
                .call({ from: contractOwner });
              console.log(result, "result");
              setOwner(result);
            } catch (error) {
              console.log(error, "error in contract");
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    const getOwnerofnft = async () => {
      await getOwner();
    };
    if (reloadAfterTransfer === true) {
      getOwnerofnft();
      updateTransfer(false);
    }
  }, [reloadAfterTransfer]);

  useEffect(() => {
    console.log(reloadAfterTransfer, "reload after transfer");
    const getOwnerofnft = async () => {
      await getOwner();
    };

    getOwnerofnft();
  }, [location, tokenId, state[0]]);

  // map nft details

  const getNftDetails = async () => {
    if (state[0]) {
      let uri;

      setNftDetails(nftData[0].metadata.data);
      console.log(state[0]?.isSaleListed);
      console.log(state[0]?.isOfferListed);

      if (state[0]?.isSaleListed) {
        setListedType(1);
        setIsSaleListed(true);
        getOfferHistory();
      }
      if (state[0]?.isOfferListed) {
        setIsOfferListed(true);
        setListedType(2);
        getOfferHistory();
      }

      setNftDetails(nftData[0]?.metadata.data);

      uri = nftData[0]?.uri;
      const result = await getNftMetadata(uri);
      setNftDetails(nftData[0]?.metadata.data);
    }
  };

  useEffect(() => {
    getNftDetails();
    if (state[0]?.isOfferListed) {
      if (getOfferHistory() !== undefined) {
        getNftDetails();
        getSelectedUser();
      }
    } else {
      getNftDetails();
    }
  }, [web3, tokenId, location, nftData, listedNfts, state]);

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";
    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };
  const getArtistNamebyAdress2 = (accountAddress) => {
    let artistName = "";

    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        artistName = user?.userName;
      }
    });


    return artistName;
  };
  // save artist to firebase
  const handleSaveArtistStats = async (
    artistAddress,
    sale,
    selectedNetwork
  ) => {
    const artistStats = await saveArtistStats(
      artistAddress,
      sale,
      selectedNetwork
    );
  };

  // buy now function of blockchain
  const BuyNow = async () => {
    if (inCompleteProfile) {
      toast.info("Your profile is incomplete");
      return;
    }
    setBuyStatus("confirming");
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    let username = await getUserName();
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);
    const contractOwner = await contract.methods.owner().call();

    const priceInEther = state[0]?.listedData?.price;

    const royality = 2;
    if (state[0]?.listedData?.count || priceInEther) {
      const transaction = contract.methods.buyNft(
        state[0]?.listedData?.count,
        priceInEther
        // royality,
        // contractOwner
      );

      try {
        const result = await transaction.send({
          from: accounts[0],
          value: priceInEther,
          gasLimit: 3000000,
        });
        console.log(result, "transaction hash");
        let transactionHash = result?.transactionHash;
        await getOwner();
        setBuyStatus("success");
        const historyData = {
          action: "sold",
          user: username,
          artworkUri: nftDetails,
          from: state[0]?.newOwner,
          to: accounts[0],
          price: priceInEther,
          tokenId: state[0]?.listedData?.tokenId,
          transactionHash: transactionHash,
        };

        await getOwner();
        handleNotifications(
          username,
          "purchase",
          priceInEther,
          nftData[0]?.metadata.data?.artName,
          transactionHash,
          nftData[0]?.metadata.data.selectedBlockchain,
          getArtistNamebyAdress2(owner)
        );
        console.log(transactionHash);
        console.log(historyData, "historyData");
        let selectedNetwork = nftData[0]?.metadata.data.selectedBlockchain;
        console.log(selectedNetwork);
        handleCollectionHistory(nftDetails?.selectedCollectionId, historyData);
        handlecollectionStats(
          accounts[0],
          priceInEther,
          nftData[0]?.metadata.data.selectedBlockchain
        );
        handleSaveArtistStats(
          state[0]?.listedData?.artist,
          priceInEther,
          selectedNetwork
        );
        // setBuyArtModal(false);

        console.log(artId);
        console.log(username);
        let price = priceInEther;
        console.log(price);

        let action = "sold";
        saveArtworkHistory(
          transactionHash,
          address,
          action,
          username,
          artId,
          price,
          null
        );
        saveArtLastPriceToFirebase(artId, price);
        await getAllListNFTData();

        toast.success("Artwork purchased successfully");
        return true;
      } catch (error) {
        setBuyStatus("error");
        toast.error("Transaction Failed!");
      }
    }
  };

  // claim nft after user is selected by owner

  // function to check if tokens are approved or not
  const [totalSupply, setTotalSupply] = useState(null);
  const [balance, setBalance] = useState(null);
  const [allowance, setAllowance] = useState(null);
  const ifTokensApproved = async () => {
    try {
      const selectedBlockchain = nftData[0]?.metadata.data.selectedBlockchain;
      let tokenAddress;
      let MarketplaceAddress;

      if (selectedBlockchain === "Coston") {
        tokenAddress = coston_Token;
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedBlockchain === "Coston2") {
        tokenAddress = coston2_Token;
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      if (!tokenAddress || !MarketplaceAddress) {
        throw new Error("Invalid blockchain selected");
      }

      console.log(tokenAddress, "Token address");
      console.log(MarketplaceAddress, "Marketplace address");

      const contract = new web3_1.eth.Contract(erc20abi, tokenAddress);
      console.log(contract.methods, "Contract methods");

      console.log(address, "User address");

      const balance = await contract.methods.balanceOf(address).call();
      const totalSupply = await contract.methods.totalSupply().call();
      const allowance = await contract.methods
        .allowance(address, MarketplaceAddress)
        .call();

      setTotalSupply(totalSupply);
      setBalance(balance);
      setAllowance(allowance);

      console.log(balance, "Balance");
      console.log(totalSupply, "Total supply");
      console.log(allowance, "Allowance");
    } catch (error) {
      console.error("Error checking token approval", error);
    }
  };

  useEffect(() => {
    if (bidUserDetail) {
      console.log(bidUserDetail?.price);

      if (balance === 0) {
        console.log("Balance is zero");
        return;
      }

      const allowanceValue = parseFloat(allowance);
      const bidPrice = parseFloat(bidUserDetail?.price);

      console.log(allowanceValue, "Allowance value");
      console.log(bidPrice, "Bid price");

      if (allowanceValue <= 0 || allowanceValue < bidPrice) {
        setApproveTokens(false);
      } else {
        setApproveTokens(true);
      }
    }
  }, [totalSupply, balance, isOwner, bidUserDetail, allowance, address]);

  useEffect(() => {
    console.log(approveTokens, "Approve tokens");
  }, [approveTokens]);

  useEffect(() => {
    const check = async () => {
      await ifTokensApproved();
    };
    check();
  }, [nftData[0], address]);

  const getTokenApproved = async () => {
    try {
      const accounts = await web3_1.eth.getAccounts();
      const selectedBlockchain = nftData[0]?.metadata.data.selectedBlockchain;
      let tokenAddress;
      let MarketplaceAddress;

      if (selectedBlockchain === "Coston") {
        tokenAddress = coston_Token;
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedBlockchain === "Coston2") {
        tokenAddress = coston2_Token;
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      console.log(coston2_Token);
      if (!tokenAddress || !MarketplaceAddress) {
        throw new Error("Invalid blockchain selected");
      }

      console.log(tokenAddress, "Token address");
      console.log(MarketplaceAddress, "Marketplace address");

      const contract = new web3_1.eth.Contract(erc20abi, tokenAddress);
      const transaction = await contract.methods.approve(
        MarketplaceAddress,
        100000000000000
      );
      const result = await transaction.send({
        from: accounts[0],
        gasLimit: 3000000,
      });

      await ifTokensApproved();
      console.log(result, "Transaction hash");
      await ifTokensApproved();
    } catch (error) {
      console.error("Error approving tokens", error);
    }
  };

  const mintToApprove = async () => {
    try {
      // Get the accounts from the web3 instance
      const accounts = await web3_1.eth.getAccounts();

      // Define the token address based on the selected blockchain
      let tokenAddress;
      if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
        tokenAddress = coston_Token;
      } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
        tokenAddress = coston2_Token;
      }
      const contract = new web3_1.eth.Contract(erc20abi, tokenAddress);

      const transaction = await contract.methods.mint().call();

      console.log(transaction, "transaction hash");
    } catch (error) {
      console.log("error", error);
    }
  };

  // bid
  // accept offer
  // before claim approve tokens
  // claim

  const ClaimNft = async () => {
    console.log(bidUserDetail, "bid  details");

    const accounts = await web3_1.eth.getAccounts();
    console.log(nftData[0]?.metadata.data.selectedBlockchain);
    let MarketplaceAddress;
    if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    if (web3_1) {
      const contract = new web3_1.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const priceToEther = bidUserDetail.price;
      const auctionListId = state[0]?.offerCountIndex;
      console.log(priceToEther, "price to etther");

      const transaction = contract.methods.ClaimNFT(
        auctionListId,
        bidUserDetail?.price
      );

      console.log(transaction);
      try {
        const result = await transaction.send({
          from: accounts[0],
          // value: priceToEther,
          gasLimit: 3000000,
        });
        setBidModal(false);
        const historyData = {
          action: "tranfer",
          user: username,
          artworkUri: nftDetails,
          from: state[0]?.newOwner,
          to: accounts[0],
          price: priceToEther,
          tokenId: tokenId,
        };
        let selectedNetwork = nftData[0]?.metadata.data.selectedBlockchain;
        console.log(selectedNetwork);
        handleCollectionHistory(nftDetails?.selectedCollectionId, historyData);
        handlecollectionStats(
          accounts[0],
          priceToEther,
          nftData[0]?.metadata.data.selectedBlockchain
        );
        handleSaveArtistStats(
          state[0]?.listedData?.artist,
          priceToEther,
          selectedNetwork
        );
        await getAllListNFTData();
        getOfferHistory();
        getSelectedUser();
        navigate("/explore");
      } catch (error) {
        console.log(error, ":error");
        toast.error("Transaction Failed!");
      }
    }
  };

  // store collection stats data to fireabse

  const handlecollectionStatsListing = async (
    owner,
    newPrice,
    selectedNetwork
  ) => {
    console.log(offerPrice, "offer price");

    console.log(selectedNetwork);
    const collectionId = nftDetails?.selectedCollectionId;
    const collectionStats = await getCollectionStats(collectionId);

    if (collectionStats) {
      let volume = collectionStats?.volume || [];
      volume.push(`${offerPrice}-${new Date().getTime()}`);

      let owners = collectionStats?.owners || [];
      if (!owners.includes(owner)) {
        owners.push(owner);
      }
      let SGBvolume = parseFloat(collectionStats?.SGBvolume) || 0;
      let FLRvolume = parseFloat(collectionStats?.FLRvolume) || 0;

      if (selectedNetwork === "Coston") {
        SGBvolume += parseFloat(offerPrice);
      } else if (selectedNetwork === "Coston2") {
        FLRvolume += parseFloat(offerPrice);
      }

      let floorPrice = collectionStats?.floorPrice || [];

      // Remove the matching offerPrice from floorPrice
      floorPrice = floorPrice.filter((fp) => {
        const price = parseFloat(fp.split("-")[0]);
        return price !== parseFloat(offerPrice / 1000000000000000000);
      });

      // Determine the minimum floor price from the updated floorPrice array
      let minFloorPrice;
      console.log(floorPrice?.length, "floor price length");
      if (floorPrice?.length > 0) {
        minFloorPrice = Math.min(
          ...floorPrice.map((fp) => parseFloat(fp.split("-")[0]))
        );
      } else if (floorPrice?.length == 1) {
        minFloorPrice = floorPrice[0];
      } else {
        minFloorPrice = 0; // or set to a default value if needed
      }
      console.log(minFloorPrice, "min floor price");
      console.log(floorPrice);

      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats?.artworkCount,
        createdAt: collectionStats?.createdAt,
        creatorEarning: collectionStats?.creatorEarning,
        volume: volume,
        SGBvolume: SGBvolume,
        FLRvolume: FLRvolume,
        USDvolume: collectionStats?.USDvolume,
        floorPrice: floorPrice,
        listedCount: (collectionStats?.listedCount || 0) - 1, // Adjusting listedCount
        saleCount: (collectionStats?.saleCount || 0) + 1, // Incrementing saleCount
        owners: owners,
        minFloorPrice: minFloorPrice, // Optional: to store the minimum floor price separately
      };

      saveCollectionStats(data);
    }
  };

  const handlecollectionStats = async (owner, offerPrice, selectedNetwork) => {
    console.log(offerPrice, "offer price");
    console.log(selectedNetwork);
    const collectionId = nftDetails?.selectedCollectionId;
    const collectionStats = await getCollectionStats(collectionId);

    if (collectionStats) {
      let volume = collectionStats?.volume || [];
      volume.push(`${offerPrice}-${new Date().getTime()}`);

      let owners = collectionStats?.owners || [];
      if (!owners.includes(owner)) {
        owners.push(owner);
      }
      let SGBvolume = parseFloat(collectionStats?.SGBvolume) || 0;
      let FLRvolume = parseFloat(collectionStats?.FLRvolume) || 0;

      if (selectedNetwork === "Coston") {
        SGBvolume += parseFloat(offerPrice);
      } else if (selectedNetwork === "Coston2") {
        FLRvolume += parseFloat(offerPrice);
      }

      let floorPrice = collectionStats?.floorPrice || [];

      // Remove the matching offerPrice from floorPrice
      floorPrice = floorPrice.filter((fp) => {
        const price = parseFloat(fp.split("-")[0]);
        return price !== parseFloat(offerPrice / 1000000000000000000);
      });

      // Determine the minimum floor price from the updated floorPrice array
      let minFloorPrice;
      console.log(floorPrice?.length, "floor price length");
      if (floorPrice?.length > 0) {
        minFloorPrice = Math.min(
          ...floorPrice.map((fp) => parseFloat(fp.split("-")[0]))
        );
      } else if (floorPrice?.length == 1) {
        minFloorPrice = floorPrice[0];
      } else {
        minFloorPrice = 0; // or set to a default value if needed
      }
      console.log(minFloorPrice, "min floor price");
      console.log(floorPrice);

      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats?.artworkCount,
        createdAt: collectionStats?.createdAt,
        creatorEarning: collectionStats?.creatorEarning,
        volume: volume,
        SGBvolume: SGBvolume,
        FLRvolume: FLRvolume,
        USDvolume: collectionStats?.USDvolume,
        floorPrice: floorPrice,
        listedCount: (collectionStats?.listedCount || 0) - 1, // Adjusting listedCount
        saleCount: (collectionStats?.saleCount || 0) + 1, // Incrementing saleCount
        owners: owners,
        minFloorPrice: minFloorPrice, // Optional: to store the minimum floor price separately
      };

      saveCollectionStats(data);
    }
  };

  // if profile is not complete don't allow offer (or make bid)
  const openOffer = async () => {
    if (inCompleteProfile) {
      toast.info("Your Profile is not complete");
      return;
    }
    setMakeOfferModal(true);
  };
  const [bidCount, setBidCount] = useState(null);

  useEffect(() => {
    if (offerHistory?.length <= 0) {
      setBidCount(0);
      return;
    }
    const bidCounts = offerHistory.map((e) => parseInt(e[4], 10)); // Assuming 'bidCount' is at the 4th position
    const maxBidCount = Math.max(...bidCounts);
    console.log(maxBidCount);
    setBidCount(maxBidCount);
  }, [offerHistory]);

  //  make offer to blockchain
  useEffect(() => {
    console.log(state[0], "listed stated data");
  }, [state[0]]);

  const makeOffer = async () => {
    const web3_1 = new Web3(window.ethereum);

    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);
    const contractOwner = await contract.methods.owner().call();

    const auctionListId = state[0]?.offerCountIndex;
    console.log(auctionListId);
    const priceToEther = web3.utils.toWei(offerPrice, "ether");

    const username = getUserName();
    try {
      const transaction = contract.methods.NftOffers(
        auctionListId,
        username,
        priceToEther
      );
      console.log(transaction);

      const result = await transaction?.send({
        from: accounts[0],
        gasLimit: 3000000,
      });
      if (result) {
        setOfferStatus("success");
      }
      console.log(result);
      let transactionHash = result?.transactionHash;
      toast.success("Offer made successfully");
      try {
        // await mintToApprove();
      } catch (error) {
        console.log(error);
      }
      console.log(nftDetails, "nft details");
      const historyData = {
        action: "offer",
        user: username,
        artworkUri: nftDetails,
        from: accounts[0],
        to: state[0]?.newOwner,
        price: priceToEther,
        tokenId: state[0]?.listedData?.tokenId,
        transactionHash: transactionHash,
      };
      let action = "offer2";

      console.log(transactionHash, "tranacation ahsg");
      let price = priceToEther;
      let count;
      if (bidCount) {
        count = bidCount + 1;
      } else {
        count = 1;
      }
      console.log(count);
      saveOfferHash(artId, count, transactionHash);

      // saveArtworkHistory(transactionHash, address, action, username, artId, price, null);

      handleCollectionHistory(nftDetails?.selectedCollectionId, historyData);
      // setMakeOfferModal(false);
      getOfferHistory();

      handleNotifications(
        username,
        "newOffer",
        priceToEther / 1000000000000000000,
        nftData[0]?.metadata.data?.artName,
        transactionHash,
        nftData[0]?.metadata.data.selectedBlockchain,
        getArtistNamebyAdress2(state[0]?.newOwner),

      );
      return true;
    } catch (error) {
      setOfferStatus("error");
      toast.error("Transaction Failed!");
    }
  };

  // accept offer (owner can accept offer of user and that user will be selected user)

  const handleAcceptOffer = async (bidCount, name) => {
    console.log(bidCount, "bid count");
    const web3_1 = new Web3(window.ethereum);
    let username = getUserName();
    // alert(bidCount);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    const tokenData = state[0];
    if (tokenData?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (tokenData?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    const auctionListId = tokenData?.offerCountIndex;
    console.log(auctionListId);

    try {
      // alert("heeree");

      const transaction = contract.methods.selectUser(auctionListId, bidCount);
      const result = await transaction.send({
        from: accounts[0],
      });
      setBidModal(false);
      getOfferHistory();
      getIsUserSelected();
      getSelectedUser();

      handleNotifications(
        getArtistNamebyAdress2(name),
        "offerAccepted",
        null,
        nftData[0]?.metadata.data?.artName,
        result?.transactionHash,
        nftData[0]?.metadata.data.selectedBlockchain,

      );
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };



  const handleRemoveOffer = async (bidCount, name) => {
    console.log(bidCount, "bid count");
    const web3_1 = new Web3(window.ethereum);
    let username = getUserName();
    // alert(bidCount);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    const tokenData = state[0];
    if (tokenData?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (tokenData?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    console.log(contract.methods, "contract methods in remove  offer");


    const auctionListId = tokenData?.offerCountIndex;
    console.log(auctionListId);
    console.log(bidCount);

    try {
      // alert("heeree");

      const transaction = contract.methods.cancelOfferPrice(auctionListId, bidCount);
      const result = await transaction.send({
        from: accounts[0],
      });
      setBidModal(false);
      getOfferHistory();
      getIsUserSelected();
      getSelectedUser();

      // handleNotifications(
      //   getArtistNamebyAdress2(name),
      //   "offerAccepted",
      //   null,
      //   nftData[0]?.metadata.data?.artName,
      //   result?.transactionHash,
      //   nftData[0]?.metadata.data.selectedBlockchain,

      // );
      toast.success("Offer cancelled succesfully!");
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };


  // get selected user for which offer is accepted by owner

  const getSelectedUser = async () => {
    if (web3 !== null) {
      let MarketplaceAddress;
      const tokenData = state[0];
      if (tokenData?.metadata.data.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (tokenData?.metadata.data.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const auctionListId = tokenData?.offerCountIndex;
      const result = await contract?.methods
        ?.SelectedUser(auctionListId)
        ?.call();
      console.log(result);
      setSelectedUser(result);
      if (result !== 0) {
        setSelectedBid(offerHistory[result - 1]);
        setSelectUserAddress(offerHistory[result - 1]?.user);
      }
      if (offerHistory[result - 1]?.user === connectedAccount) {
        setIsClaimUser(true);
      }
      console.log(offerHistory[result - 1]?.user);
    }
  };

  useEffect(() => {
    getSelectedUser();
  }, [offerHistory, address]);

  const handleCancelList = async () => {
    setLoading(true);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    const tokenData = state[0];

    const priceInEther = state[0]?.listedData?.price; // Assuming the price is in Wei

    if (tokenData?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (tokenData?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);
    const auctionListId = tokenData?.saleCountIndex;

    try {
      const transaction = contract.methods.CancelListForSale(auctionListId);
      const result = await transaction.send({
        from: accounts[0],
      });
      setLoading(false);

      setListingModal("successListing");

      getAllListNFTData();
      handlecollectionStats(
        accounts[0],
        priceInEther,
        nftData[0]?.metadata.data.selectedBlockchain
      );

      // setEditListModal(false);
      // navigate("/explore");
    } catch (error) {
      toast.error("Transaction Failed!");
      setLoading(false);
      setListingModal("error");
    }
  };

  // Function to convert price from wei to ether
  const convertWeiToEther = (priceInWei) => {
    if (priceInWei) {
      try {
        // Convert the price from wei to ether, ensuring the input is a string
        const priceInEther = web3.utils.fromWei(priceInWei.toString(), "ether");
        return priceInEther;
      } catch (error) {
        console.error('Error converting Wei to Ether:', error);
        return null;
      }
    }
    return null; // Return null or handle cases where priceInWei is falsy
  };


  const handleCancelOfferList = async () => {
    setLoading(true);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;
    if (nftData[0]?.metadata.data.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (nftData[0]?.metadata.data.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);
    const auctionListId = state[0]?.listCount;
    try {
      const transaction = contract.methods.cancelOfferList(auctionListId);
      const result = await transaction.send({
        from: accounts[0],
      });
      setLoading(false);
      setEditListModal(false);
      navigate("/explore");
    } catch (error) {
      setLoading(false);
      toast.error("Transaction Failed!");
    }
  };

  const getArtistImage = (accountAddress) => {
    let artistImage = thumb;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = thumb;
        }
      }
    });

    return artistImage;
  };

  const handleTagClick = (tagName) => {
    console.log(tagName);

    setTagName(tagName);
    navigate("/Explore");
  };

  const getUserName = () => {
    let artistName = "";
    userData?.forEach((user) => {
      if (user?.id === address) {
        artistName = user?.userName;
      }
    });
    console.log(artistName, "artistName");

    return artistName;
  };
  const { data, isError } = useBalance({
    address: address,
  });

  function convertUnixTimestampToDate(unixTimestamp) {
    if (unixTimestamp) {
      // Convert Unix timestamp to milliseconds
      const milliseconds = parseInt(unixTimestamp) * 1000;

      // Create a new Date object
      const date = new Date(milliseconds);

      // Get the day and month
      const day = date.toLocaleDateString("en-US", { weekday: "short" });
      const month = date.toLocaleDateString("en-US", { month: "short" });
      // const month = date.toLocaleDateString('en-US', { month: 'short' });

      // Return the formatted date string
      return `${day}, ${month},${date.getFullYear()}`;
    }
  }

  useEffect(() => {
    console.log(collectionDetails, "collectionDetails");
  }, [collectionDetails]);

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        console.log(priceInUSD.toFixed(2));
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        console.log(priceInUSD.toFixed(2));
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    console.log(flr, "flr");
  }, [flr]);
  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);
  const formatAddress = (address) => {
    console.log(address, "address");
    if (address?.length <= 11) {
      return address; // If address is too short to format, return it as is
    }
    return `${address?.slice(0, 6)}...${address?.slice(-5)}`;
  };

  const navigateToExplorer = (address) => {
    console.log(collectionDetails?.selectedNetwork);
    if (collectionDetails?.selectedNetwork === "Coston") {
      window.open(
        `https://coston-explorer.flare.network/token/${address}`,
        "_blank"
      );
    } else {
      window.open(
        `https://coston2-explorer.flare.network/token/${address}`,
        "_blank"
      );
    }
  };

  // get data by username

  const getImageByUsername = async (username) => {
    console.log(username);
    let res = await getUserDataByUserName(username);
    console.log(res, "response of get image by username");
    console.log(res?.documentData?.image);
    if (res) {
      let image = res?.documentData?.image;
      console.log(image);
      return image;
    }
  };
  const [userImages, setUserImages] = useState(null);
  useEffect(() => {
    const fetchUserImages = async () => {
      const imagePromises = artworkHistory.map(async (entry) => {
        console.log(entry, "artowrk history");
        const image = await getUserDataByUserName(entry.username);
        return { username: entry.username, image: image?.documentData?.image };
      });

      const images = await Promise.all(imagePromises);
      const imagesMap = images.reduce((acc, { username, image }) => {
        acc[username] = image;
        return acc;
      }, {});

      setUserImages(imagesMap);
    };

    if (artworkHistory?.length > 0) {
      fetchUserImages();
    }
  }, [artworkHistory]);

  const handlePrice = () => {
    if (!newPrice) {
      toast.error("Enter price to continue");
      return;
    }
    // Simulate blockchain confirmation process
    setListingModal("confirm");
    HandlechangePrice();
  };

  const handleCancelListing = () => {
    // Simulate blockchain confirmation process
    setListingModal("confirm");

    setTimeout(() => {
      // Simulate success or error response
      let success; // Change to false to simulate error
      try {
        handleCancelList();
        success = true;
      } catch (error) {
        console.log(error);
      }
    }, 2000);
  };
  const [extractedUris, setExtractedUris] = useState([]);

  useEffect(() => {
    console.log(listedNfts, "listed nfts");
    console.log(collectionDetails, "collectionDetails");
    console.log(collectionId, "collectionId");

    const uris = [];

    console.log(typeof listedNfts, "listed nftsssssssssssss");

    console.log(listedNfts, "listed nftsssssssssssss");
    if (listedNfts[0]) {
      for (let a of listedNfts[0]) {
        console.log(a.uriData, "nft data");
        console.log(a.listedData.tokenId);
        // uris.push(a.uriData);
        uris.push({ uri: a.uriData, tokenId: a.listedData.tokenId });
      }

      for (let b of listedNfts[1]) {
        console.log(b.uriData);
        console.log(b.listedData.tokenId);
        uris.push({ uri: b.uriData, tokenId: b.listedData.tokenId });
        // uris.push(b.uriData);
      }
    }

    console.log(uris, "uris............");

    setExtractedUris(uris);
  }, [listedNfts, collectionDetails, collectionId]);

  const [nfts, setNfts] = useState([]);
  useEffect(() => {
    console.log(artId);
    console.log(collectionId, "collection id");

    Promise.all(
      extractedUris.map((item) => {
        return getNftMetadata(item.uri).then((metadata) => {
          return { tokenId: item.tokenId, metadata: metadata }; // Store token ID and metadata together
        });
      })
    ).then((nftData) => {
      let uniqueTokenIds = new Set();
      let filtered = nftData?.filter((item) => {
        if (
          item?.metadata?.data?.selectedCollectionId === collectionId &&
          item.metadata.data.artId !== artId &&
          item.tokenId !== tokenId && // Exclude the tokenId stored in the tokenId state
          !uniqueTokenIds.has(item.tokenId)
        ) {
          uniqueTokenIds.add(item.tokenId);
          return true;
        }
        return false;
      });
      setNfts(filtered);
      console.log(filtered, "nft meta data");
    });
  }, [extractedUris, collectionId, artId, tokenId]);

  useEffect(() => {
    console.log(isNftApproved, "isNftApproved");
  }, [isNftApproved]);

  const getLastPrice = async () => {
    let res = await getArtLastPriceFromFirebase(nftUri);
    console.log(res, "last price,...");
    setLastPrice(res / 1000000000000000000);
  };
  useEffect(() => {
    getLastPrice();
  }, [nftUri]);

  const handleClick = (item) => {
    navigate(
      `/artwork/${item?.metadata?.data.selectedBlockchain}/${item?.metadata?.data.selectedCollectionId}/${item?.tokenId}`
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    // useScrollToTop();
  };

  const navigateToexplorer = (entry) => {
    const network = nftData[0]?.metadata.data.selectedBlockchain;
    console.log(network, "network...");
    console.log(entry);
    let url = "";

    if (network === "Coston2") {
      url = `https://coston2-explorer.flare.network/tx/${entry.transactionHash}`;
    } else {
      url = `https://coston-explorer.flare.network/tx/${entry.transactionHash}`;
    }

    window.open(url, "_blank");
  };

  const getTransactionHashByBidCount = (bidCount, offers) => {
    console.log(bidCount);
    console.log(offers);
    for (const offer of offers) {
      if (String(offer.bidCount) === String(bidCount)) {
        return offer.transactionHash;
      }
    }
    return null; // Return null if no match is found
  };
  const navigatetoOfferExplorer = async (item) => {
    try {
      const res = await getOffersByArtId(artId);
      console.log(res);

      const hash = getTransactionHashByBidCount(item?.bidCount, res);
      console.log(hash);

      if (!hash) {
        console.error("Transaction hash not found");
        return;
      }

      const network = nftData[0]?.metadata.data.selectedBlockchain;
      console.log(network, "network...");

      let url = "";
      if (network === "Coston2") {
        url = `https://coston2-explorer.flare.network/tx/${hash}`;
      } else {
        url = `https://coston-explorer.flare.network/tx/${hash}`;
      }

      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };
  // useEffect(() => {
  //   console.log(artworkHistory);
  //   console.log(offerHistory);

  //   const filteredArtworkHistory = artworkHistory.filter(item => item.action === 'offer2');
  //   console.log(filteredArtworkHistory, "tesaf");

  //   const updatedOfferHistory = offerHistory.map(offer => {
  //     const [address, account, price, biddingTime, bidCount, details] = offer;
  //     console.log(biddingTime);
  //     for (let a of filteredArtworkHistory) {
  //       console.log(a.timestamp.seconds);

  //     }

  //     const matchingArtwork = filteredArtworkHistory.find(artwork =>
  //       artwork.timestamp.seconds === biddingTime &&
  //       artwork.price === price
  //     );

  //     console.log(matchingArtwork, "matching artwork");

  //     if (matchingArtwork) {
  //       // Remove the matched artwork from filteredArtworkHistory
  //       filteredArtworkHistory.splice(filteredArtworkHistory.indexOf(matchingArtwork), 1);
  //       return [...offer, { ...details, transactionHash: matchingArtwork.transactionHash }];
  //     }

  //     return offer;
  //   });

  //   setMergedOfferHistory(updatedOfferHistory);

  //   console.log('Merged Offer History:', updatedOfferHistory);
  //   console.log('Remaining Artwork History:', filteredArtworkHistory);
  // }, [artworkHistory, offerHistory]);

  // useEffect(() => {
  //   console.log(mergedOfferHistory, "merged offerHistoy");

  // }, [mergedOfferHistory])

  useEffect(() => {
    console.log(owner, "owner");
    if (owner === address) {
      console.log("yesl");
      setIsOwner(true);
      if (state[0]?.isSaleListed) {
        if (owner === state[0]?.newOwner) {
          console.log("true");
          setIsOwner(true);
        }
      }
    } else {
      setIsOwner(false);
    }
  }, [owner, address]);

  useEffect(() => {
    console.log(bidUserDetail, "bid user details");
  }, [bidUserDetail]);

  useEffect(() => {
    console.log(isOfferListed, "isOfferListed type");
    console.log(isSaleListed, "is sale listed");
  }, [isOfferListed, isSaleListed]);
  return (
    <div>
      <div className="artwork-details">
        <div className="artwork-banner site-containers v-center  ">
          <div className="left ">
            {isLoading ? (
              <Skeleton.Avatar
                active
                size={600}
                shape="square"
                block={true}
                className="ms-1 w-25 mb-3"
              />
            ) : (
              <div className="banner-img">
                {nftData[0]?.metadata.data?.image ? (
                  <>
                    {/* <img
                    src={nftData[0]?.metadata.data?.image}
                    alt="art"
                    className="img-100 artwork-hover"
                  /> */}
                    <Image
                      src={nftData[0]?.metadata.data?.image}
                      alt="art"
                      className="img-100 artwork-hover"
                    />
                  </>
                ) : nftData[0]?.metadata.data?.videoUrl ? (
                  <video
                    className="img-100 "
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                  >
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={nftData[0]?.metadata.data?.previewImg} // Replace with the path to your preview image
                    className="img-100 artwork-hover"
                  />
                )}

                {address && (
                  <>
                    {isLiked ? (
                      <img
                        src={liked}
                        alt="like"
                        className={`like-icon img-24 pointer   ${isLiked ? "" : ""
                          }`}
                        onClick={handleLikeClick}
                      />
                    ) : (
                      <img
                        src={like}
                        alt="like"
                        className={`like-icon img-24 pointer   ${isLiked ? "invert1" : ""
                          }`}
                        onClick={handleLikeClick}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="right">
            <div className="artwork-user-wrapper  ">
              {isLoading ? (
                <>
                  <Skeleton.Input
                    active
                    size="large"
                    block={true}
                    className="ms-1 w-25 mb-3"
                  />
                </>
              ) : (
                <div className="collection-name v-center">
                  <p
                    className="body-medium fw-bold text-black no-text-transform v-center pointer hover-underline "
                    onClick={() =>
                      navigate(
                        `/explore-collections/${nftDetails?.selectedCollectionId}`
                      )
                    }
                  >
                    {nftData[0]?.metadata.data?.selectedCollection}
                    <img
                      src={verified}
                      alt="verify"
                      className="img-18 img-fluid ms-2"
                    />
                  </p>

                  <label className="no-text-transform br-30">
                    {nftData[0]?.metadata.data?.selectedBlockchain}
                  </label>
                </div>
              )}
              <br />
              {isLoading ? (
                <>
                  <Skeleton.Input
                    active
                    size="large"
                    block={true}
                    className="ms-1 w-25 mt-2"
                  />
                </>
              ) : (
                <h3 className="fw-bold">
                  {nftData[0]?.metadata.data?.artName}
                </h3>
              )}

              <div className="artist-owner v-center gap-5 mt-30">
                {isLoading ? (
                  <div className="d-flex align-items-center gap-5">
                    <div className="d-flex align-items-center">
                      <Skeleton.Avatar active size="large" shape="circle" />
                      <Skeleton.Input
                        active
                        size="small"
                        block={false}
                        className="ms-1"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Skeleton.Avatar active size="large" shape="circle" />
                      <Skeleton.Input
                        active
                        size="small"
                        block={false}
                        className="ms-1"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {(state[0]?.newOwner || owner) && (
                      <>
                        <UserProfile
                          status="artist"
                          address={nftData[0]?.metadata?.data?.artistAddress}
                          username={getUserName()}
                          image={getArtistImage(
                            nftData[0]?.metadata?.data?.artistAddress
                          )}
                        />
                        <UserProfile
                          status="owner"
                          address={state[0]?.newOwner || owner}
                          username={getUserName()}
                          image={getArtistImage(state[0]?.newOwner)}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="pricing-wrapper">
              <div className="list-price mt-40">
                {isSaleListed && (
                  <>
                    <label className="medium text-grey no-text-transform fw-normal pb-2">
                      List price
                    </label>

                    <div className="price-value v-end">
                      <span className="v-center">
                        <img
                          src={
                            state[0]?.metadata?.data.selectedBlockchain ===
                              "Coston"
                              ? SGB
                              : FLR
                          }
                          alt="flr"
                          className="me-2"
                        />

                        {/* {listedType === 1  ? ( */}
                        <h6 className="font-normal">
                          {convertWeiToEther(state[0]?.listedData?.price)}
                        </h6>
                      </span>
                      <p className="body-medium  mb--2 ms-1">
                        {state[0]?.metadata?.data.selectedBlockchain ===
                          "Coston" ? (
                          <span className="fw-semibold body-large">SGB</span>
                        ) : (
                          <span className="fw-semibold body-large">FLR</span>
                        )}

                        {isSaleListed ? (
                          <span className="text-medium-grey body-medium fw-medium fw-500 ms-1">
                            {" "}
                            ($
                            {state[0]?.metadata?.data.selectedBlockchain ===
                              "Coston"
                              ? (
                                convertWeiToEther(
                                  state[0]?.listedData?.price
                                ) * sgb
                              ).toFixed(2)
                              : (
                                convertWeiToEther(
                                  state[0]?.listedData?.price
                                ) * flr
                              ).toFixed(2)}
                            )
                          </span>
                        ) : (
                          <span className="text-medium-grey ms-1">
                            {" "}
                            ($
                            {state[0]?.metadata?.data.selectedBlockchain ===
                              "Coston2"
                              ? (
                                state[0]?.listedData?.minimumBid * sgb
                              ).toFixed(2)
                              : (
                                state[0]?.listedData?.minimumBid * flr
                              ).toFixed(2)}
                            )
                          </span>
                        )}
                      </p>
                    </div>
                  </>
                )}

                {/* btns */}
                {isLoading ? (
                  <Skeleton.Input
                    active
                    size="large"
                    block={true}
                    className="ms-1 w-25 mt-2"
                  />
                ) : (
                  <>
                    {state && address && !biddingCount && (
                      <div className="artwork-btns v-center gap-10 mt-30">
                        {/* If the item is sale listed */}
                        {!isSaleListed &&
                          (state[0]?.newOwner === connectedAccount ? (
                            isNewtworkConnected ? (
                              <Button
                                text="List for sale"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={() => {
                                  navigate(`/list-forSale`, {
                                    state: {
                                      data: nftData[0]?.metadata.data,
                                      nftDetails: state[0],
                                    },
                                  });
                                }}
                              />
                            ) : (
                              <Button
                                text="Switch Network To List"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleSwitchChain}
                              />
                            )
                          ) : null)}

                        {state[0]?.newOwner !== connectedAccount &&
                          (isNewtworkConnected ? (
                            <Button
                              text="Make offer"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={openOffer}
                            />
                          ) : (
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          ))}

                        {/* If both sale and offer are listed */}
                        {isSaleListed &&
                          isOfferListed &&
                          (state[0]?.newOwner === connectedAccount ? (
                            isNewtworkConnected ? (
                              <Button
                                text="Edit listing"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleEditListModal}
                              />
                            ) : (
                              <Button
                                text="Switch Network"
                                className="btn-prime btn-primary px-3"
                                width="176px"
                                height="36px"
                                onClick={handleSwitchChain}
                              />
                            )
                          ) : isNewtworkConnected ? (
                            <>
                              {/* <Button
                              text="Make offer"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={openOffer}
                            /> */}
                              <Button
                                text="Buy now"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleBuyArtModal}
                              />
                            </>
                          ) : (
                            <>
                              <Button
                                text="Switch Network"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleSwitchChain}
                              />
                              {/* <Button
                            text="Switch Networkasdas"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            onClick={handleSwitchChain}
                          /> */}
                            </>
                          ))}

                        {/* Claim Pending Button */}
                        {biddingCount &&
                          (state[0]?.newOwner === connectedAccount ? (
                            <Button
                              text="Claim Pending"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              disabled
                            />
                          ) : isNewtworkConnected ? (
                            approveTokens ? (
                              <Button
                                text="Claim Pending"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={ClaimNft}
                              />
                            ) : (
                              <Button
                                text="Approve Tokens"
                                className={`btn-prime ${selectedUser === 0
                                  ? "btn-ternary mt-2"
                                  : "btn-primary mt-2"
                                  }`}
                                width="300px"
                                height="30px"
                                onClick={getTokenApproved}

                              />
                            )

                          ) : (
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          ))}

                        {/* More Options */}
                        {!(
                          state[0]?.isListed === false && owner !== address
                        ) && (
                            <div className="more-opt cursor-pointer dropdown rounded-circle option-btn img-35">
                              <img
                                src={option}
                                alt=""
                                className="dropdown-toggle no-after"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              />
                              <ul className="dropdown-menu no-border bg-transparent">
                                <Dropdown
                                  listed={
                                    state[0]?.isSaleListed ? "listed" : "noListed"
                                  }
                                  item={state[0]}
                                  tokenId={tokenId ? tokenId : null}
                                  owner={state[0]?.newOwner || owner}
                                  LocationArt={"singleArt"}
                                />
                              </ul>
                            </div>
                          )}
                      </div>
                    )}
                  </>
                )}
                {biddingCount && (
                  <div className="artwork-btns v-center gap-10 mt-30">
                    {state[0]?.newOwner === connectedAccount ? (
                      <Button
                        text="Claim Pending"
                        className="btn-prime btn-primary"
                        width="176px"
                        height="36px"
                        disabled={true}
                      />
                    ) : (
                      <>
                        {approveTokens ? (
                          <Button
                            text="Claim Pending"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            disabled={false}
                            onClick={ClaimNft}
                          />
                        ) : (
                          <Button
                            text="Approve Tokens"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            disabled={false}
                            onClick={getTokenApproved}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}


                {/* 
                  {address && (
                    <div className="artwork-btns v-center gap-10 mt-30">
                      {listedType === 1 && (
                        isNewtworkConnected ? (
                          state[0]?.newOwner === connectedAccount ? (
                            <Button
                              text="Edit listing"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={handleEditListModal}
                            />
                          ) : (
                            <>
                              <Button
                                text="Make offer"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={openOffer}
                              />
                              <Button
                                text="Buy now"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleBuyArtModal}
                              />
                            </>
                          )
                        ) : (

                          <>
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                               width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          </>

                        )
                      )}





                      {listedType === 2 &&
                        (owner === connectedAccount ? (
                          isNewtworkConnected ? (
                            <>
                              {biddingCount && (
                                <Button
                                  text="Claim Pending"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  disabled={true}
                                />
                              )}
                            </>
                          ) : (
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary px-3"
                              width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          )
                        ) : isNewtworkConnected ? (
                          <>
                            {biddingCount ? (
                              <Button
                                text="Claim Pending"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={state[0]?.newOwner === connectedAccount ? undefined : ClaimNft}
                                disabled={state[0]?.newOwner === connectedAccount}
                              />
                            ) : (
                              <Button
                                text={state[0]?.newOwner === connectedAccount ? "Switch Network" : "Make offer"}
                                className="btn-prime btn-primary"
                                width={state[0]?.newOwner === connectedAccount ? "auto" : "176px"}
                                height="36px"
                                onClick={state[0]?.newOwner === connectedAccount ? handleSwitchChain : openOffer}
                              />
                            )}
                          </>
                        ) : (
                          !state[0]?.newOwner ? (
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                               width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          ) : (
                            <Button
                              text="Switch Network"
                              className="btn-prime btn-primary"
                              width="176px"
                              height="36px"
                              onClick={handleSwitchChain}
                            />
                          )
                        ))}

                      {listedType === 2 && owner === address && (
                        state[0]?.newOwner === connectedAccount ? (
                          <Button
                            text="List for sale"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            onClick={() => {
                              navigate(`/list-forSale`, {
                                state: {
                                  data: nftData[0]?.metadata.data,
                                  nftDetails: state[0],
                                },
                              });
                            }}
                          />
                        ) : (
                          <Button
                            text="Switch Network "
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            onClick={handleSwitchChain}
                          />
                        )

                      )}


                      {!(state[0]?.isListed === false && owner !== address) && (
                        <div className="more-opt cursor-pointer dropdown rounded-circle option-btn img-35">
                          <img
                            src={option}
                            alt=""
                            className="dropdown-toggle no-after"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <ul className="dropdown-menu no-border bg-transparent">
                            <Dropdown
                              listed={state[0]?.isSaleListed ? "listed" : "noListed"}
                              item={state[0]}
                              tokenId={tokenId ? tokenId : null}
                              owner={state[0]?.listedData?.seller || owner}
                              LocationArt={"singleArt"}
                            />
                          </ul>
                        </div>
                      )}
                    </div>
                  )} */}

                {
                  <div className="artwork-btns  v-center gap-10 d-none">
                    <Button
                      text="Edit listing"
                      className="btn-prime btn-primary"
                      width="176px"
                      height="36px"
                      onClick={handleEditListModal}
                    />
                    {!(state[0]?.isListed === false && owner !== address) && (
                      <div className="more-opt cursor-pointer dropdown rounded-circle option-btn img-35">
                        <img
                          src={option}
                          alt=""
                          className="dropdown-toggle no-after"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul class="dropdown-menu no-border bg-transparent">
                          <Dropdown
                            listed={
                              state[0]?.isSaleListed ? "listed" : "noListed"
                            }
                            item={state[0]?.metadata?.data}
                            nftDetails={state[0]}
                            tokenId={tokenId ? tokenId : null}
                            owner={state[0]?.newOwner || owner}
                            LocationArt={"singleArt"}
                          />
                        </ul>
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="artwork-details-content mt-60">
          <div className="left-content">
            <div className="history ">
              <h5 className="fw-bold no-text-transform">History</h5>
              <div className="history-content">
                <div className="history-list">
                  {offerHistory?.length > 0 &&
                    offerHistory.map((item, index) => {
                      console.log(item, "item,,,,");
                      return item?.bidCount === biddingCount ? (
                        <div key={index} className="histrory-list">
                          <div className="left img-24 v-center justify-content-center">
                            <img
                              src={offerMade}
                              alt="offer"
                              className="img-14"
                            />
                          </div>
                          <div className="right">
                            <Popover
                              placement="bottom"
                              trigger="hover"
                              content={
                                <>
                                  <ArtistPopUp
                                    userProfile={getArtistImage(item?.user)}
                                    verified={verified}
                                    artistName={getArtistNamebyAdress(
                                      item?.user
                                    )}
                                  />
                                </>
                              }
                            >
                              <p className="body-medium">
                                <span
                                  onClick={() => {
                                    navigate(`/profile/${item?.user}`);
                                  }}
                                  className="position-relative  pointer hover-underline"
                                >
                                  {getArtistNamebyAdress(item?.user)}
                                </span>{" "}
                                made an offer of{" "}
                                {convertWeiToEther(item?.price)} FLR
                              </p>
                            </Popover>

                            <label className="small     pointer hover-underline">
                              {new Date(
                                item?.biddingTime * 1000
                              ).toLocaleString()}
                              {/* {convertUnixTimestampToDate(item?.biddingTime)} */}

                              <img src={arrow} alt="" className="img-12 ms-1" />
                            </label>

                            {state[0]?.newOwner ===
                              connectedAccount ? (
                              isNewtworkConnected ? (
                                <Button
                                  text="Pending"
                                  className={`btn-prime ${selectedUser === 0
                                    ? "btn-ternary mt-2"
                                    : "btn-primary mt-2"
                                    }`}
                                  width="100px"
                                  height="30px"
                                  disabled={true}
                                />
                              ) : (
                                <Button
                                  text="Switch Network"
                                  className="btn-prime btn-primary"
                                  width="190px"
                                  height="36px"
                                  onClick={handleSwitchChain}
                                />
                              )
                            ) : (
                              <>
                                {bidUserDetail?.user === address && address && selectUserAddress === connectedAccount ? (
                                  approveTokens ? (
                                    <Button
                                      text="Claim"
                                      className={`btn-prime ${selectedUser === 0
                                        ? "btn-ternary mt-2"
                                        : "btn-primary mt-2"
                                        }`}
                                      width="100px"
                                      height="30px"
                                      onClick={ClaimNft}
                                    />
                                  ) : (
                                    <Button
                                      text="Approve Tokens"
                                      className={`btn-prime ${selectedUser === 0
                                        ? "btn-ternary mt-2"
                                        : "btn-primary mt-2"
                                        }`}
                                      width="300px"
                                      height="30px"
                                      onClick={getTokenApproved}
                                    />
                                  )
                                ) : (
                                  // <p>Pending for claim...</p>
                                  <Button
                                    text="Claim"
                                    className={`btn-prime ${selectedUser === 0
                                      ? "btn-ternary mt-2"
                                      : "btn-primary mt-2"
                                      }`}
                                    width="100px"
                                    height="30px"
                                    onClick={ClaimNft}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        !biddingCount && (
                          <div className="histrory-list">
                            <div className="left img-24 v-center justify-content-center">
                              <img
                                src={offerMade}
                                alt="offer"
                                className="img-14"
                              />
                            </div>
                            <div className="right">
                              <Popover
                                placement="bottom"
                                trigger="hover"
                                content={
                                  <>
                                    <ArtistPopUp
                                      userProfile={getArtistImage(item?.user)}
                                      verified={verified}
                                      artistName={getArtistNamebyAdress(
                                        item?.user
                                      )}
                                    />
                                  </>
                                }
                              >
                                <p className="body-medium">
                                  <span
                                    onClick={() => {
                                      navigate(`/profile/${item?.user}`);
                                    }}
                                    className="position-relative  pointer hover-underline"
                                  >
                                    {getArtistNamebyAdress(item?.user)}
                                  </span>{" "}
                                  made an offer of{" "}
                                  {convertWeiToEther(item?.price)} FLR
                                </p>
                              </Popover>

                              <label
                                onClick={() => navigatetoOfferExplorer(item)}
                                className="small     pointer hover-underline"
                              >
                                {new Date(
                                  item?.biddingTime * 1000
                                ).toLocaleString()}

                                <img
                                  src={arrow}
                                  alt=""
                                  className="img-12 ms-1"
                                />
                              </label>

                              {connectedAccount &&
                                (isNewtworkConnected ? (
                                  state[0]?.newOwner === address ? (
                                    <Button
                                      text="Accept offer"
                                      className={`btn-prime ${selectedUser === 0
                                        ? "btn-ternary mt-2"
                                        : "btn-primary mt-2"
                                        }`}
                                      width="100px"
                                      height="30px"
                                      disabled={selectedUser === 0}
                                      onClick={() =>
                                        handleAcceptOffer(item?.bidCount, item.user)
                                      }
                                    />
                                  ) : (
                                    <Button
                                      text="Remove offer"
                                      className={`btn-prime ${selectedUser === 0
                                        ? "btn-ternary mt-2"
                                        : "btn-primary mt-2"
                                        }`}
                                      width="100px"
                                      height="30px"
                                      onClick={() =>
                                        handleRemoveOffer(item?.bidCount, item.user)
                                      }
                                    // disabled={selectedUser === 0}
                                    // onClick={() =>
                                    //   handleAcceptOffer(item?.bidCount)
                                    // }
                                    />
                                  )
                                ) : (
                                  <Button
                                    text="Switch Network"
                                    className="btn-prime btn-primary"
                                    width="190px"
                                    height="36px"
                                    onClick={handleSwitchChain}
                                  />
                                ))}
                            </div>
                          </div>
                        )
                      );
                    })}

                  {artworkHistory?.length > 0 &&
                    artworkHistory.map((entry, index) => (
                      <React.Fragment key={index}>
                        {console.log(entry, "enruttt")}
                        <div className="history-item d-flex mb-3">
                          <div className="left img-24 v-center justify-content-center">
                            <img src={listed} alt="action" className="img-14" />
                          </div>
                          <div className="right">
                            <p className="body-medium">
                              {entry.action === "Listed for sale" &&
                                `Listed for sale by `}
                              {entry.action === "Mint" && `Minted by `}
                              {entry.action === "sold" && `Sold to `}

                              <Popover
                                placement="bottom"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getArtistImage(entry?.address)}
                                    verified={verified}
                                    artistName={getArtistNamebyAdress(
                                      entry?.address
                                    )}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigate(`/profile/${entry?.address}`);
                                  }}
                                  className="position-relative pointer hover-underline"
                                >
                                  {getArtistNamebyAdress(entry?.address)
                                    ?.length > 18
                                    ? getArtistNamebyAdress(
                                      entry?.address
                                    ).substring(0, 18) + "..."
                                    : getArtistNamebyAdress(entry?.address)}
                                </span>
                              </Popover>
                              {entry.action === "Listed for sale" &&
                                ` for ${entry.price} ${collectionDetails?.selectedNetwork ===
                                  "Coston2"
                                  ? "FLR"
                                  : "SGB"
                                }`}

                              {entry.action === "sold" &&
                                ` for ${entry.price / 1000000000000000000}`}

                              {entry.action === "transfer" && (
                                <>
                                  {" transferred art to "}
                                  <Popover
                                    placement="bottom"
                                    trigger="hover"
                                    content={
                                      <ArtistPopUp
                                        userProfile={getArtistImage(entry?.to)}
                                        verified={verified}
                                        artistName={getArtistNamebyAdress(
                                          entry?.to
                                        )}
                                      />
                                    }
                                  >
                                    <span
                                      onClick={() =>
                                        navigate(`/profile/${entry?.to}`)
                                      }
                                      className="position-relative pointer hover-underline"
                                    >
                                      {getArtistNamebyAdress(entry?.to)?.length >
                                        18
                                        ? `${getArtistNamebyAdress(
                                          entry?.to
                                        ).substring(0, 18)}...`
                                        : getArtistNamebyAdress(entry?.to)}
                                    </span>
                                  </Popover>
                                </>
                              )}
                            </p>

                            <label
                              onClick={() => {
                                navigateToexplorer(entry);
                              }}
                              className="small pointer hover-underline"
                            >
                              {new Date(
                                entry.timestamp.seconds * 1000
                              ).toLocaleString()}
                              <img src={arrow} alt="" className="img-12 ms-1" />
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </div>

                {/* offer */}
              </div>
            </div>
          </div>
          <div className="right-content">
            <div className="artwork-accord">
              <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                {/* Description  */}
                {nftDetails?.description && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p className="h-64 body-medium text-uppercase  fw-bold text-se  v-center cursor-pointer">
                        Description
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="body-small description">
                        {nftDetails?.description}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* traits */}
                {nftDetails?.traits?.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                        traits
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="traits">
                        {nftDetails?.traits && nftDetails.traits?.length > 0 ? (
                          nftDetails.traits.map((trait, index) => {
                            // Check if trait.type and trait.name are not empty
                            if (trait.type && trait.name) {
                              return (
                                <div key={index} className="trait-box">
                                  <label className="small">{trait.name}</label>
                                  <ul>
                                    <li>{trait.type}</li>
                                  </ul>
                                </div>
                              );
                            } else {
                              return null; // Skip rendering if type or name is empty
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* details */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                      details
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="details">
                      <label className="small">contract address</label>
                      <label
                        onClick={() =>
                          navigateToExplorer(collectionDetails?.contractAddress)
                        }
                        className="medium text-black text-underline no-text-transform pointer"
                      >
                        {formatAddress(collectionDetails?.contractAddress)}
                      </label>
                    </div>
                    <div className="details">
                      <label className="small">token standard </label>
                      <label className="medium text-black   ">Erc-721</label>
                    </div>
                    <div className="details">
                      <label className="small">BLOCKCHAIN </label>
                      <label className="medium text-black no-text-transform">
                        {collectionDetails?.selectedNetwork === "Coston2"
                          ? "Flare "
                          : "Songbird "}
                      </label>
                    </div>
                    <div className="details">
                      <label className="small">Creator Earnings </label>
                      <label className="medium text-black  ">5%</label>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* tags */}
                {nftDetails?.selectedTags?.length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                        tags
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-22">
                        <CustomCheckBox
                          values={nftDetails?.selectedTags}
                          disabled={true}
                          // onTagClick={handleTagClick}
                          type={"singleArt"}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
          </div>
        </div>

        <div className="more-collections site-container pb-sm-0">
          {nfts?.length > 0 && (
            <>
              <h5 className="fw-bold text-center no-text-transform hide-on-mobile ">
                More from this collection
              </h5>
              <h6 className="fw-bold text-center no-text-transform hide-on-desktop  text-normal font-normal">
                More from this collection
              </h6>
            </>
          )}
          <div className="collection-cards">
            {nfts.map((item, index) => {
              return (
                <div key={index} className="collection-card">
                  <div
                    onClick={() => handleClick(item)}
                    className="head pointer"
                  >
                    {item?.metadata?.data.image ? (
                      <img src={item?.metadata?.data.image} alt="" />
                    ) : (
                      <img src={item?.metadata?.data.previewImg} alt="" />
                    )}
                  </div>
                  <label className="fw-bold medium text-black no-text-transform ">
                    {item?.metadata?.data.artName}
                  </label>

                  <label
                    onClick={() => handleClick(item)}
                    className="fw-bold small  v-center pointer hover-underline"
                  >
                    VIEW ARTWORK
                    <img src={arrow} alt="" className="img-12 ms-2" />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Modal
        show={createFinish}
        onHide={handleCreateFinish}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3">All Done</h5>

            <p className="body-medium ">
              You created {nftData[0]?.metadata.data?.artName}
              <span className="fw-500">
                {/* {nftData[0]?.metadata.data?.artName} */}
              </span>
            </p>

            {isLoading ? (
              <Skeleton.Avatar
                active
                size={240}
                shape="square"
                block={true}
                className="  mb-3 d-blocl"
              />
            ) : (
              <img
                src={nftDetails?.image || nftDetails?.previewImg}
                alt="img"
              />
            )}
            <Button
              text="Done"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={handleCreateFinish}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={finishListing}
        onHide={handleFinishListing}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3">All Done</h5>

            <p className="body-medium">
              You listed{" "}
              <span className="fw-500">
                {nftData[0]?.metadata.data?.artName}
              </span>
            </p>


            
            {isLoading ? (
              <Skeleton.Avatar
                active
                size={240}
                shape="square"
                block={true}
                className="  mb-3 d-blocl"
              />
            ) : (
              <img src={nftDetails?.image || nftDetails?.previewImg} alt="img" />
            )}

            <Button
              text="Done"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={handleFinishListing}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* transfer */}
      <Modal
        show={artTransfer}
        onHide={handleArtTransfer}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Transfer
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="art-transfer v-center flex-column pt-5 mt-2">
            <img src={art} alt="img" className="nft-img" />
            <p className="body-medium mt-40 mb-3 pb-3">
              Transfer <span className="fw-bold">“{nftDetails?.artName}” </span>{" "}
              to
            </p>

            <div style={{ maxWidth: "487px" }} className="w-100  ">
              <InputBox
                type="text"
                placeholder="Address e.g 0x1H3a..."
                labelClass="d-none"
                name="transferAddress"
                value={transferAddress}
                onChange={(e) => setTransferAddress(e.target.value)}
              />
            </div>

            <div className="h-center gap-3 mt-5 mb-70">
              <Button
                text="Cancel"
                className="btn-prime btn-primary"
                width="131px"
                height="36px"
                onClick={handleArtTransfer}
              />{" "}
              <Button
                text="Transfer"
                className={`btn-prime border-0 ${transferAddress?.length > 0 ? "btn-primary" : "btn-ternary"
                  }`}
                width="131px"
                height="36px"
                onClick={handleArtTransfer}
                disabled={transferAddress?.length > 0 ? "" : "disabled"}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Buy now modal */}

      <Modal
        show={buyArtModal}
        onHide={handleBuyArtModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              buy now
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {buyStatus === "" && (
            <div className="buy-now-modal w-431 m-auto ">
              <div className="listed-item  v-center">
                <div className="left">
                  {nftDetails?.image ? (
                    <img src={nftDetails?.image} alt="img" />
                  ) : (
                    <video className="img-100" loop playsInline autoPlay muted>
                      <source src={nftDetails?.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    {nftDetails?.artName}
                  </label>
                  <p className="body-medium">{`${nftDetails?.selectedCollection} #${collectionId}`}</p>
                </div>
              </div>
              <label className="mt-4 pt-1  text-black">Price</label>

              <div className="pricing  body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between ">
                <Input
                  className="body-medium fw-bold text-black  br-30  w-100 ps-1 "
                  disabled="disabled"
                  placeholder="Price"
                  value={state[0]?.listedData?.price / 1000000000000000000}
                />
                <span>
                  {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                    ? "SGB"
                    : "FLR"}
                </span>
              </div>
              <div className="avail-blnc mt-2 pt-1">
                <p className="body-medium v-center justify-content-between">
                  {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                    ? `$${(
                      (state[0]?.listedData?.price / 1000000000000000000) *
                      sgb
                    )?.toFixed(4)}`
                    : `$${(
                      (state[0]?.listedData?.price / 1000000000000000000) *
                      flr
                    )?.toFixed(4)}`}
                  <span>
                    Available balance:
                    <span className="fw-bold">
                      {" "}
                      {Number(data?.formatted).toFixed(2)}
                      {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                        ? "SGB"
                        : "FLR"}
                    </span>
                  </span>
                </p>
                <p className="body-small text-grey text-center mt-3">
                  Please note, there are 5% royalties associated with all
                  artwork.
                </p>
              </div>

              {isNewtworkConnected ? (
                <Button
                  text="Buy now"
                  className="btn-prime btn-primary mt-4"
                  width="100%"
                  height="36px"
                  onClick={handleBuyNow}
                />
              ) : (
                <Button
                  text="Switch Network"
                  className="btn-prime btn-primary mt-4"
                  width="100%"
                  height="36px"
                  onClick={handleSwitchChain}
                />
              )}
            </div>
          )}

          {/* Waiting for blockchain wallet confirmation… */}
          {buyStatus === "confirming" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Confirm this transaction in your wallet.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>

              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {/* if error*/}
          {buyStatus === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Sorry, we couldn't complete this request.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Please try again
              </p>

              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                imageClassName="me-2 img-18"
                onClick={handleBuyNow}
              />
            </div>
          )}

          {/* on purchased success */}

          {buyStatus === "success" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center  ">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Congratulations! You've successfully purchased this item.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Purchase successful!
              </p>

              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleBuyArtModal}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Make an offer */}

      <Modal
        show={makeOfferModal}
        onHide={handleOfferModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              MAKE OFFER
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {offerStatus === "" && (
            <div className="buy-now-modal w-431 m-auto ">
              <div className="listed-item  v-center">
                <div className="left">
                  {nftDetails?.image ? (
                    <img src={nftDetails?.image} alt="img" />
                  ) : (
                    <video className="img-100" loop playsInline autoPlay muted>
                      <source src={nftDetails?.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    {nftDetails?.artName}
                  </label>

                  <p className="body-medium">{`${nftDetails?.selectedCollection}`}</p>
                  {/* <p className="body-medium">{`${nftDetails?.selectedCollection} #${nftDetails?.selectedCollectionId}`}</p> */}
                </div>
              </div>
              <label className="mt-4 pt-1  text-black">Price</label>

              <div className="pricing border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between bg-transparent">
                <Input
                  className="body-medium fw-bold text-black   br-30  w-100 bg-transparent ps-1"
                  placeholder="Price"
                  type="number"
                  value={offerPrice}
                  onChange={handlePriceChange}
                />
                <span>
                  {" "}
                  {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                    ? "WSGB"
                    : "WFLR"}
                </span>
              </div>

              <div className="avail-blnc mt-2 pt-1">
                <p className="body-medium v-center justify-content-between">
                  {offerPrice > 0 ? (
                    state[0]?.metadata?.data.selectedBlockchain === "Coston" ? (
                      <span>~{offerPrice * (0.011).toFixed(3)}</span>
                    ) : (
                      <span>~ ${offerPrice * (0.032).toFixed(3)}</span>
                    )
                  ) : (
                    <span></span>
                  )}

                  <span>
                    Available balance:{" "}
                    <span className="fw-bold">
                      {Number(data?.formatted).toFixed(4)}
                    </span>
                  </span>
                </p>
              </div>
              <p className="body-small text-grey text-center mt-3">
                Please note, there are 5% royalties associated with all artwork.
              </p>
              {isNewtworkConnected ? (
                <Button
                  text="Make Offer"
                  className={`btn-prime ${disableOfferBtn ? "btn-ternary" : "btn-primary"
                    }  mt-5`}
                  width="100%"
                  height="36px"
                  onClick={handleMakeAnOffer}
                  disabled={disableOfferBtn}
                />
              ) : (
                <Button
                  text="Switch Network"
                  className="btn-prime btn-primary mt-50"
                  width="100%"
                  height="36px"
                  onClick={handleSwitchChain}
                />
              )}
            </div>
          )}

          {/* Waiting for blockchain wallet confirmation… */}
          {offerStatus === "confirming" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Confirm this transaction in your wallet.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>

              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {/* if error*/}
          {offerStatus === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Sorry, we couldn't complete this request.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Please try again
              </p>

              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                onClick={handleMakeAnOffer}
                imageClassName="me-2 img-18"
              />
            </div>
          )}

          {/* on success */}
          {offerStatus === "success" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Your offer has been sent. Keep an eye on your notifications!
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Offer sent
              </p>

              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleOfferModal}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/*  EDIT LISTING*/}

      <Modal
        show={editListModal}
        onHide={handleEditListModal}
        className="sign-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              EDIT LISTING
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {listingModal === "initial" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="listed-item">
                <div className="v-center">
                  <div className="left">
                    {nftData[0]?.metadata.data?.image ? (
                      <img
                        src={nftData[0]?.metadata.data?.image}
                        alt="art"
                        className="img-100px "
                      />
                    ) : (
                      <video
                        className="img-100"
                        loop
                        playsInline
                        autoPlay
                        muted
                      >
                        <source
                          src={nftData[0]?.metadata.data?.videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {/* <img src={art} alt="img" className="img-100px" /> */}
                  </div>
                  <div className="right ms-3">
                    <label className="larger fw-bold text-black no-text-transform">
                      {nftDetails?.artName}
                    </label>
                    <p className="body-medium">{collectionDetails?.name}</p>

                    <h6 className="fw-bold fst-normal mt-2">
                      {convertWeiToEther(state[0]?.listedData?.price)}
                      {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                        ? "SGB"
                        : "FLR"}
                    </h6>
                  </div>
                </div>
              </div>

              <p className="mt-30 text-center">
                Would you like to change the price of the listing or cancel the
                listing and remove it from sale?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Change price"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("changePrice")}
                />
                <Button
                  text="Cancel listing"
                  className="btn-prime btn-secondary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("cancelListing")}
                />
              </div>
            </div>
          )}

          {listingModal === "changePrice" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {nftData[0]?.metadata.data?.image ? (
                  <img
                    src={nftData[0]?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
                {/* <img src={art} alt="img" className="img-65 border-solid-black pad-2" /> */}
              </div>
              <p className="mt-40 text-center">
                Set new price for <strong>“{nftDetails?.artName}”:</strong>
              </p>
              <div className="pricing bg-transparent border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between">
                <Input
                  className="body-medium ps-2 text-black bg-transparent br-30 w-100"
                  placeholder="Price"
                  type="number"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                />
                <span>
                  {state[0]?.metadata?.data.selectedBlockchain === "Coston"
                    ? "WSGB"
                    : "WFLR"}
                </span>
              </div>
              <p className="mt-2 body-medium text-medium-grey">
                ~ $
                {(state[0]?.metadata?.data.selectedBlockchain === "Coston"
                  ? newPrice * sgb
                  : newPrice * flr
                ).toFixed(2)}
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Continue"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={handlePrice}
                />
              </div>
            </div>
          )}

          {listingModal === "cancelListing" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {nftData[0]?.metadata.data?.image ? (
                  <img
                    src={nftData[0]?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <p className="mt-40 text-center">
                Cancel listing:
                <strong> {nftDetails?.artName}</strong>
              </p>
              <p className="mt-30 text-center">
                Are you sure you want to cancel this listing?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Go back"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("initial")}
                />
                <Button
                  text="Yes, cancel listing"
                  className="btn-prime btn-secondary text-error bg-error-20 border-0"
                  width="156px"
                  height="36px"
                  onClick={handleCancelListing}
                />
              </div>
            </div>
          )}

          {listingModal === "confirm" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Confirm this transaction in your wallet.
              </p>
              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>
              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {listingModal === "successPrice" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully updated price for
                <span className="fw-bold"> "{nftDetails?.artName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleEditListModal}
              />
            </div>
          )}

          {listingModal === "successListing" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully cancelled listing for
                <span className="fw-bold"> "{nftDetails?.artName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleEditListModal}
              />
            </div>
          )}

          {listingModal === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                {listingModal === "changePrice"
                  ? `Sorry, we couldn't change the price for "${nftDetails?.artName}".`
                  : `Sorry, we couldn't cancel your listing for "${nftDetails?.artName}" at this time.`}
              </p>
              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                imageClassName="me-2 img-18"
                onClick={() => {
                  setListingModal("initial");
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Make a  bid*/}

      <Modal
        show={bidModal}
        onHide={handleBidModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Place a offer
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="buy-now-modal w-431 m-auto ">
            <div className="listed-item auction-live ">
              <div className="v-center">
                <div className="left">
                  <img src={art} alt="img" className="border-0" />
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    Arwork Name
                  </label>
                  <p className="body-medium">Untitled Collection #7156351271</p>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <p className="h-center flex-column">
                  <span className="text-light-grey  fw-500 body-medium">
                    Reserve not met
                  </span>
                  <span className="fw-bold body-medium mt-2">-</span>
                </p>
                <p className="d-flex flex-column align-items-end">
                  <span className="text-light-grey  fw-500 body-medium">
                    Reserve price
                  </span>
                  <span className="fw-semibold mt-2">
                    <img src={FLR} alt="FLR" />
                    330,000 FLR
                  </span>
                </p>
              </div>
            </div>
            <label className="mt-4 pt-1  text-black">Price</label>
            <div className="pricing border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between bg-transparent">
              <Input
                className="body-medium fw-bold text-black   br-30  w-100 bg-transparent ps-1"
                placeholder="Price"
                type="number"
              />
              <span>WFLR</span>
            </div>
            <div className="avail-blnc mt-2 pt-1">
              <p className="body-medium v-center justify-content-between">
                ~ $40.52
                <span>
                  Available balance:
                  <span className="fw-bold"> 3721 WFLR</span>
                </span>
              </p>
            </div>
            {/* available balance needs to be greater than price before ‘buy now’
            button becomes primary. */}
            <Button
              text="Place bid"
              className="btn-prime btn-ternary mt-50"
              width="100%"
              height="36px"
              onClick={(e) => toast("bid placed")}
              disabled="disabled"
            />
          </div>

          {/* Waiting for blockchain wallet confirmation… */}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={wallet} alt="wallet" className="img-100" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Confirm this transaction in your wallet.
            </p>

            <p className="body-medium fw-bold text-center mt-30">
              Waiting for blockchain confirmation…
            </p>

            <Button
              text=""
              className="btn-prime btn-primary mt-50"
              width="138px"
              height="36px"
              imageSrc={loader}
              imageClassName="rotate-360"
            />
          </div>

          {/* if error*/}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={error} alt="wallet" className="img-100" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Sorry, we couldn't complete this request.
            </p>

            <p className="body-medium fw-bold text-center mt-30">
              Please try again
            </p>

            <Button
              text="Try again"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              imageSrc={refresh}
              imageClassName="me-2 img-18"
            />
          </div>

          {/* on success */}

          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none ">
            <div className="wallet h-center m-auto">
              <img src={tick} alt="tick" className="img-100 invert1" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Your offer has been sent. Keep an eye on your notifications!
            </p>

            <p className="body-medium fw-bold text-center mt-30">Offer sent</p>

            <Button
              text="Done"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              onClick={handleOfferModal}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Make a  bid*/}

      <Modal
        show={isFullScreen}
        onHide={toggleFullScreen}
        className="fullScreen-modal "
        centered
      >
        <Modal.Body className="p-0">
          <div className="full-screen-art">
            {/* <div className="screen-alert">
              <img src={Esc} alt="Esc" className="Esc img-fluid" />
            </div> */}
            <img src={nftDetails?.image} alt="art" className="art" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SingleArt2;
