import React, { useDebugValue, useEffect } from "react";
import "../../style/main.scss";
import Button from "../button";
import star35 from "../../assets/icon/spiked-circle/black/35px.svg";
import more from "../../assets/icon/more-horizontal.svg";
import copy from "../../assets/icon/copy-grey.svg";
import copy1 from "../../assets/icon/copy.svg";
import hide from "../../assets/icon/private-profile.svg";
import close from "../../assets/icon/close.svg";
import copylink from "../../assets/icon/link.svg";
import twitter from "../../assets/icon/twitter.svg";
import report from "../../assets/icon/report.svg";
// import placeholder from "../../assets/icon/profile-large.svg";
import placeholder from "../../assets/images/profile-1.svg";
import verified from "../../assets/icon/verified-artist.svg";

import user from "../../assets/images/face-4.png";
import EditProfile from "./editProfile";
import { useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import FollowModal from "./followModal";
import { toast } from "react-toastify";
import Dropdown from "../shared/dropdown";
import { useNavigate } from "react-router-dom";
import {
  getUserData,
  getFollowersData,
  getFollowingData,
  getFollowersByAddress,
  getFollowingByAddress,
  getUserDataByUserName,
  getProfileSettingFirebase,
  handleFollow,
  unfollowArtist,
  checkIfFollowed,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { getDoc } from "firebase/firestore";

import sortby from "../../assets/icon/sort-by.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import { Route } from "react-router-dom";
import { add } from "lodash";

const Header = ({
  id,
  setUserDataByUserName,
  userDataByUserName,
  reload,
  setReload,
  address,
  followersData,
  followingData,
}) => {
  const navigate = useNavigate();
  const [showBidModal, setShowBidModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isFollowed, setIsFollowed] = useState(null);
  console.log(isFollowed, "user data by username from profile");
  // const [moreMenu, setMoreMenu] = useState(false);
  // const [focused, setFocused] = useState(false);
  const [show, setShow] = useState(false);
  const {
    address: accountAddress,
    isConnecting,
    isDisconnected,
    isConnected,
  } = useAccount();
  useEffect(() => {
    console.log(address, "6788888");
  }, [address]);

  const [userData, setUserData] = useState("");

  const [userName, setUserName] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const text = "0X9683…C315";

  const handleBidModal = () => {
    setShowBidModal((prev) => !prev);
  };

  const handleModalClick = (type) => {
    setModalType(type);
    if (id) {
      if (isPrivate == true) {
        setShowFollowModal(false);
      } else {
        setShowFollowModal(true);
      }
    } else {
      setShowFollowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowFollowModal(false);
    setModalType("");
  };

  const copyAddress = () => {
    let Add;
    if (!id) {
      Add = address;
    } else {
      Add = id;
    }

    navigator.clipboard
      .writeText(Add)
      .then(() => {
        toast.success("Address Copied");
      })
      .catch((error) => {
        toast.warning(" Failed to copy tex");
      });
  };

  const copyPageLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(`${currentUrl}/${address}`)
      .then(() => {
        toast.success("Link copied");
      })
      .catch((error) => {
        toast.warning("Link copying Failed", error);
      });
  };
  const fetchUserLoggedProfile = async () => {
    console.log(address, "address in profile");
    const data = await getUserData(address);
    console.log(data, "profile data");
    user = data?.userName;
    console.log(user, "username666");
    setUserName(user);
  };
  useEffect(() => {
    fetchUserLoggedProfile();
  }, [address]);

  let username = localStorage?.getItem("userName");
  const fetchUserData = async () => {
    let user;
    if (!id || id == null) {
      const data = await getUserData(address);
      console.log(data, "yserdata");
      user = data?.userName;

      setReload(false);
    } else {
      if (id) {
        console.log(id);
        const data = await getUserData(id);
        console.log(data, "yserdata");
        user = data?.userName;
      }
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [modalType, id, userData, reload]);

  useEffect(() => {
    // alert("reloading");
    fetchUserData();
  }, [userData, id, reload]);

  // share on twitter
  const shareOnTwitter = () => {
    const currentUrl = window.location.href;
    const tweetText = "Check out this page: " + currentUrl;
    const tweetUrl =
      "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweetText);
    window.open(tweetUrl, "_blank");
  };

  const privateProfile = () => {
    toast.warning("Profile private");
  };

  const getfirebasedata = async () => {
    let add;
    if (id) {
      add = id;
    } else {
      add = address;
    }
    if (address) {
      console.log(add, "sfdffsdffff");
      const data = await getUserData(add);
      console.log(data, "yserdata");
      setUserData(data);
    }
  };

  useEffect(() => {
    const item = localStorage.getItem("reload1") === "true";

    if (item) {
      getfirebasedata();

      localStorage.removeItem("reload1");
    } else {
      getfirebasedata();
    }
  }, [id, address, reload]);

  useEffect(() => {
    getfirebasedata();
  }, [reload]);

  useEffect(() => {
    console.log(userData?.userName);
  }, [userData || reload]);
  const [isloggedin, setislogged] = useState("");

  useEffect(() => {
    const isLogged = localStorage?.getItem("catalystSigner");
    setislogged(isLogged);
  }, []);

  const getUserNameForProfile = async () => {
    let add;
    if (id) {
      add = id;
    } else {
      add = address;
    }

    const userNamedata = await getUserData(add);
    console.log(userNamedata);
    // setUserDataByUserName(userNamedata);
    setUserData(userNamedata);
    setReload(false);
  };

  useEffect(() => {
    if (id || address || reload) {
      getUserNameForProfile();
    }
  }, [id, address, reload]);

  const [activeOfferFilter, setActiveOfferFilter] = useState("All offers");
  const handleOfferTick = (tick) => {
    setActiveOfferFilter(tick);
  };
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    const fetchProfileSetting = async () => {
      let Add;

      if (!id) {
        Add = address;
      } else {
        Add = id;
      }
      const profileSetting = await getProfileSettingFirebase(Add);
      if (profileSetting && profileSetting.hasOwnProperty("private")) {
        setIsPrivate(profileSetting.private);
      }
    };
    fetchProfileSetting();
  }, [address, id]);

  const fetchFollowStatus = async () => {
    console.log(accountAddress);
    console.log(id);

    if (id && accountAddress) {
      const ifFollowed = await checkIfFollowed(accountAddress, id);
      console.log(ifFollowed, "if followed");
      setIsFollowed(ifFollowed);
    }
  };

  useEffect(() => {
    fetchFollowStatus();
  }, [id, accountAddress]);

  const handleFollowAction = async () => {
    if (id) {
      try {
        if (isFollowed) {
          await unfollowArtist(accountAddress, id);
          toast.warn("Unfollowed Successfully");
        } else {
          console.log(accountAddress, id);
          await handleFollow(accountAddress, id); // Assuming this is the correct function name
          toast.success("Followed Successfully");
        }

        // Fetch follow status again to update the state
        fetchFollowStatus();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <div className="profile-header site-container ">
        <div className="left-content">
          <div className="user-name">
            {userDataByUserName &&
              (userDataByUserName.isWhiteListed ? (
                <img src={verified} alt="verified" />
              ) : (
                <img src={star35} alt="star" />
              ))}

            <h5 className="no-text-transform">
              {userDataByUserName?.userName === address
                ? address
                  ? `${address.slice(0, 6)}...${address.slice(-4)}`
                  : `${id.slice(0, 6)}...${id.slice(-4)}`
                : userDataByUserName?.userName === id
                  ? id
                    ? `${id.slice(0, 6)}...${id.slice(-4)}`
                    : address
                      ? `${address.slice(0, 6)}...${address.slice(-4)}`
                      : "@username"
                  : userDataByUserName?.userName
                    ? `@${userDataByUserName.userName}`
                    : id
                      ? `${id.slice(0, 6)}...${id.slice(-4)}`
                      : address
                        ? `${address.slice(0, 6)}...${address.slice(-4)}`
                        : "@username"}
            </h5>


          </div>
          <div className="profile-headlines">
            <p className="body-medium">
              {userDataByUserName?.userHeadline
                ? userDataByUserName?.userHeadline
                : userDataByUserName?.userHeadline}
            </p>
          </div>
          <div className="profile-controls">
            <div className="d-flex">
              {isloggedin ? (
                id ? (
                  <>
                    {console.log(isFollowed)}
                    <Button
                      text={isFollowed ? "Following" : "Follow"}
                      className={`btn-prime ${isFollowed
                        ? " btn-secondary  bg-transparent"
                        : "btn-primaryA "
                        }`}
                      width="126px"
                      onClick={handleFollowAction} // Ensure you have a handleFollow function defined
                    />
                    <div>
                      <div
                        className="more-opt cursor-pointer icon-shadow hide-on-desktop"
                        onClick={handleShow}
                      >
                        <img src={more} alt="option" />
                      </div>
                    </div>
                    <div className="ofr-recieved d-flex align-items-center filter dropdown hide-on-mobile">
                      <p
                        className="body-medium dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="more-opt cursor-pointer icon-shadow">
                          <img src={more} alt="option" />
                        </div>
                      </p>
                      <ul className="dropdown-menu">
                        <li className="dropdown-item" onClick={copyAddress}>
                          <img
                            src={copy}
                            alt="tick"
                            className="invert-0 img-24"
                          />
                          Copy Address
                        </li>
                        <li className="dropdown-item" onClick={copyPageLink}>
                          <img src={copylink} alt="tick" className="invert-0" />
                          Copy Link
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => navigate("/report-issue")}
                        >
                          <img src={report} alt="tick" className="invert-0" />
                          Report
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      text="Edit profile"
                      className="btn-prime btn-primaryA"
                      onClick={handleBidModal}
                    />
                    <div>
                      <div
                        className="more-opt cursor-pointer icon-shadow hide-on-desktop"
                        onClick={handleShow}
                      >
                        <img src={more} alt="option" />
                      </div>
                    </div>
                    <div className="ofr-recieved d-flex align-items-center filter dropdown hide-on-mobile">
                      <p
                        className="body-medium dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="more-opt cursor-pointer icon-shadow">
                          <img src={more} alt="option" />
                        </div>
                      </p>
                      <ul className="dropdown-menu">
                        <li className="dropdown-item" onClick={copyAddress}>
                          <img
                            src={copy}
                            alt="tick"
                            className="invert-0 img-24"
                          />
                          Copy Address
                        </li>
                        <li className="dropdown-item" onClick={copyPageLink}>
                          <img src={copylink} alt="tick" className="invert-0" />
                          Copy Link
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => navigate("/report-issue")}
                        >
                          <img src={report} alt="tick" className="invert-0" />
                          Report
                        </li>
                      </ul>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
            </div>

            <div className="user-acc cursor-pointer" onClick={copyAddress}>
              {address ? (
                <p className="body-small">
                  {userDataByUserName?.documentId
                    ? userDataByUserName?.documentId.slice(0, 5) +
                    "..." +
                    userDataByUserName?.documentId.slice(
                      userDataByUserName?.documentId.length - 6,
                      userDataByUserName?.documentId.length
                    )
                    : address.slice(0, 5) +
                    "..." +
                    address.slice(address.length - 6, address.length)}
                  <span>
                    <img src={copy} alt="copy" />
                  </span>
                </p>
              ) : id ? (
                <p className="body-small">
                  {id.slice(0, 5) + "..." + id.slice(id.length - 6, id.length)}
                  <span>
                    <img src={copy} alt="copy" />
                  </span>
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="right-content">
          <div className="d-flex followings">
            <div
              className="follow cursor-pointer"
              onClick={() => {
                handleModalClick("followers");
              }}
            >
              <p>{followersData?.count}</p>
              <label className="followers-label cursor-pointer">
                Followers
              </label>
            </div>
            <div
              className="follow cursor-pointer pointer"
              onClick={() => handleModalClick("following")}
            >
              <p>{followingData?.count}</p>
              <label className="followers-label">Following</label>
            </div>
          </div>

          {address && (
            <div className="profile-img">
              {userDataByUserName?.documentData?.image ? (
                <img
                  src={userDataByUserName?.documentData.image}
                  alt="profile"
                  className="img-100 "
                />
              ) : userDataByUserName?.image == "" ||
                userDataByUserName?.image == undefined ||
                userDataByUserName?.image == null ? (
                // <div className="border w-100 h-100 rounded-circle v-center h-center border-dark">
                <img src={placeholder} alt="profile" className="img-100  " />
              ) : (
                // </div>
                <img src={userDataByUserName?.image} alt="profile" className="img-100 " />
              )}
            </div>
          )}

          {!address && (
            <div className="profile-img">
              <img src={placeholder} alt="profile" className="img-100 " />
            </div>
          )}
        </div>

        {isPrivate && (
          <div className="private-btn  hide-on-mobile ">
            <Button
              imageSrc={hide}
              text="PRIVATE PROFILE"
              width="146px"
              height="29px"
              onClick={privateProfile}
              className="pe-none"
            />
          </div>
        )}

        {/* more Menu */}

        {/* To add back layer when more menu is open */}
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <div className="more-menu-sm trans-7 ">
          <div className="menu-head pb-0">
            <label className="text-black">profile</label>
            <div className="close-btn">
              <img
                src={close}
                alt="close"
                className="img-24 cursor-pointer"
                onClick={handleClose}
              />
            </div>
          </div>

          <div className="address">
            <label className="medium h-64 d-flex align-items-end">
              address
            </label>
            <label
              className="d-flex align-items-center h-64 text-black cursor-pointer"
              onClick={copyAddress}
            >
              <img src={copy1} alt="copy" className="me-3 " />
              {text}
            </label>
          </div>

          <div className="share">
            <label className="medium h-64 d-flex align-items-end">share</label>
            <label
              className="d-flex align-items-center h-64 cursor-pointer text-black"
              onClick={copyPageLink}
            >
              <img src={copylink} alt="copy" className="me-3 " />
              Copy Link
            </label>
          </div>

          <div className="report">
            <label className="medium h-64 d-flex align-items-end">more</label>
            <label
              className="d-flex align-items-center h-64 text-black cursor-pointer"
              onClick={() => navigate("/report-issue")}
            >
              <img src={report} alt="report" className="me-3 " />
              Report
            </label>
          </div>
        </div>
      </Offcanvas>

      <EditProfile
        show={showBidModal}
        handleModal={handleBidModal}
        setReload={setReload}
      />

      <FollowModal
        userName={userName}
        id={id}
        show={showFollowModal}
        setShowFollowModal={setShowFollowModal}
        handleModal1={handleModalClose}
        data={
          modalType === "followers" ? followersData.data : followingData.data
        }
        dataType={modalType}
      />
    </div>
  );
};
const offerFilter = [
  { value: "Offers made", label: "Offers made" },
  { value: "Offers received", label: "Offers received" },
  { value: "Expired offers", label: "Expired offers" },
  { value: "All offers", label: "All offers" },
];
export default Header;
