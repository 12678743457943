import React, { useState, useEffect, useContext } from "react";
import Headers from "../components/profile/header";
import ProfileTabs from "../components/profile/profileTabs";
import { useParams, useNavigate } from "react-router-dom";
import useScrollToTop from "../customHooks/scrollToTop";
import { getUserData, getFollowersByAddress, getFollowingByAddress, checkIfFollowed } from "../firebase/firebase";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { NFTContext } from "../components/context/appContext";
const Profile = () => {
  const navigate = useNavigate();
  const { address: accountAddress } = useAccount();
  const { address } = useParams();
  const location = useLocation();

  const [id, setId] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [userDataByUserName, setUserDataByUserName] = useState("");
  const [profReload, setProfReload] = useState(false);
  const [followersData, setFollowersData] = useState({ count: 0, data: [] });
  const [followingData, setFollowingData] = useState({ count: 0, data: [] });
  const [isFollowed, setIsFollowed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const { reload, reloadProfile } = useContext(NFTContext);
  const [load, setLoad] = useState(false);
  useEffect(() => {

    console.log(location.state, "location");
    setSelectedTab(location.state?.tab);
  }, [location])

  useEffect(() => {
    console.log(address, "address");
    console.log(accountAddress, "accountaddress");
    if (address) {
      const formattedAddress = address.toLowerCase().trim();
      const formattedAccountAddress = accountAddress?.toLowerCase().trim();

      if (formattedAddress !== formattedAccountAddress) {
        console.log("different account");

        setId(address);
      }
      if (formattedAddress === formattedAccountAddress) {
        console.log("same account");
        setUserAddress(address);
        setId(null);
        setLoad(true);
      }
      if (!accountAddress || accountAddress === undefined) {
        setId(address);
        setLoad(true);
      }
    }
  }, [address, accountAddress]);

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      const newAccountAddress = accounts[0].toLowerCase().trim();
      const currentAddress = userAddress || address;
      console.log(currentAddress, "current Address");

      if (newAccountAddress !== currentAddress) {
        navigate(`/profile/${newAccountAddress}`);
        console.log("navigating");
        setLoad(true);
        setUserAddress(newAccountAddress);
        setId(null);
      }
    };

    window.ethereum.on("accountsChanged", handleAccountsChanged);

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, [navigate, userAddress, address]);


  useEffect(() => {
    const fetchData = async () => {
      console.log(id, "iod");
      console.log(accountAddress, "account address")

      if (!accountAddress) {

        const res = await getUserData(id);
        setUserDataByUserName(res);
        console.log(res, "response of data");

        const followersRes = await getFollowersByAddress(id);
        setFollowersData(followersRes || { count: 0, data: [] });

        const followingRes = await getFollowingByAddress(id);
        setFollowingData(followingRes || { count: 0, data: [] });
      }
      try {
        if (id && id != null && accountAddress.toLowerCase() !== address.toLowerCase()) {


          const res = await getUserData(id);
          setUserDataByUserName(res);

          const followersRes = await getFollowersByAddress(id);
          setFollowersData(followersRes || { count: 0, data: [] });

          const followingRes = await getFollowingByAddress(id);
          setFollowingData(followingRes || { count: 0, data: [] });

          // const ifFollowed = await checkIfFollowed(accountAddress, address);
          // console.log(ifFollowed,"if followed");
          // setIsFollowed(ifFollowed);
        }


        else if (accountAddress) {
          const res = await getUserData(accountAddress);
          setUserDataByUserName(res);

          const followersRes = await getFollowersByAddress(accountAddress);
          setFollowersData(followersRes || { count: 0, data: [] });

          const followingRes = await getFollowingByAddress(accountAddress);
          setFollowingData(followingRes || { count: 0, data: [] });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, [id, accountAddress, reloadProfile]);

  useEffect(() => {
    if (!accountAddress && address) {
      const formattedAddress = address.toLowerCase().trim();
      setUserAddress(null);
      setId(address);

      const fetchData = async () => {
        try {
          const res = await getUserData(formattedAddress);
          console.log(res, "response");
          setUserDataByUserName(res);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [accountAddress, address]);

  useEffect(() => {
    console.log(accountAddress, "accountAddress1");
    console.log(userAddress, "userAddress");
    console.log(userDataByUserName, "user data by username");
  }, [accountAddress, userAddress, userDataByUserName]);

  useScrollToTop();

  return (
    <div>
      <div className="pb-4">
        <Headers
          id={id}
          setUserDataByUserName={setUserDataByUserName}
          userDataByUserName={userDataByUserName}
          reload={profReload}
          setReload={setProfReload}
          address={userAddress}
          followersData={followersData}
          followingData={followingData}

        />
        <ProfileTabs
          id={id}
          setUserDataByUserName={setUserDataByUserName}
          userDataByUserName={userDataByUserName}
          reload={profReload}
          setReload={setProfReload}
          address={userAddress}
          selectedTab={selectedTab}
          load={load}

        />
      </div>
    </div>
  );
};

export default Profile;
