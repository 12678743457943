import React from "react";

const DotLoader = () => {
  return (
    <div>
      <div className="loaders"></div>
      <style jsx>{`
        .loaders {
          width: 30px;
          aspect-ratio: 4;
          background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
            calc(100% / 3) 100% space;
          clip-path: inset(0 100% 0 0);
          animation: l1 1s steps(4) infinite;
        }
        @keyframes l1 {
          to {
            clip-path: inset(0 -34% 0 0);
          }
        }
      `}</style>
    </div>
  );
};

export default DotLoader;
