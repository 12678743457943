import React, { useState, useEffect } from "react";
import profile from "../../assets/icon/profile-picture.svg";
import verified from "../../assets/icon/verified-artist-small.svg";
import drop from "../../assets/icon/chevron-down-extra-small.svg";
import { Offcanvas } from "react-bootstrap";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import ArtistPopUp from "../shared/artistpopup";
import { Popover } from "antd";

const TopArtist = (props) => {
  const [artist, setArtist] = useState(props.artist || []);
  const [priceShow, setPriceShow] = useState(false);
  const [activeArtistValue, setActivePrice] = useState("volume");

  useEffect(() => {
    setArtist(props.artist);
  }, [props]);

  const artistHandleShow = () => setPriceShow(true);
  const artistHandleHide = () => setPriceShow(false);

  const artistOptions = [
    { value: "volume", label: "Volume" },
    { value: "works sold", label: "Works sold" },
    { value: "highest sale", label: "Highest sale" },
    { value: "average price", label: "Average price" },
    { value: "joined", label: "Joined" },
  ];

  const handleIArtistTick = (tick) => {
    setActivePrice(tick);
    artistHandleHide();
  };

  const getVolume = (artist) => {
    if (props.currencyFilter === "FLR") {
      return artist.coston2Volume;
    } else if (props.currencyFilter === "SGB") {
      return artist.costonVolume;
    } else {
      return artist.totalVolume;
    }
  };

  const getHighestSale = (artist) => {
    if (props.currencyFilter === "FLR") {
      return artist.coston2HigherSale;
    } else if (props.currencyFilter === "SGB") {
      return artist.costonHigherSale;
    } else {
      return artist.higherSale;
    }
  };

  const getAverageSale = (artist) => {
    if (props.currencyFilter === "FLR") {
      return artist.coston2AverageSale;
    } else if (props.currencyFilter === "SGB") {
      return artist.costonAverageSale;
    } else {
      return artist.averageSale;
    }
  };

  const getWorkSold = (artist) => {
    if (props.currencyFilter === "FLR") {
      return artist.coston2ListCount;
    } else if (props.currencyFilter === "SGB") {
      return artist.costonListCount;
    } else {
      return artist.salesCount;
    }
  };

  const formatTimestampToDate = (timestamp) => {
    if (!timestamp) return "";
    const milliseconds =
      timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000;
    const date = new Date(milliseconds);

    // Extract the short month name (first 3 letters)
    const month = date.toLocaleDateString("en-US", { month: "short" });
    // Capitalize the first letter of the month and make the rest lowercase
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();

    return `${monthCapitalized} ${date.getDate()}, ${date.getFullYear()}`;
  };

  // Example usage:
  const timestamp = { seconds: 1715482088, nanoseconds: 0 };
  console.log(formatTimestampToDate(timestamp));


  const filteredArtists = artist.filter((item) => {
    if (props.timeTab === "All") return true;

    const updatedAtTimestampInSeconds = item?.updatedAt?.seconds || 0;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    console.log(props, "props");
    let filterTime;
    switch (props.timeFilter) {
      case "24h":
        filterTime = currentTimeInSeconds - 24 * 60 * 60;
        break;
      case "7D":
        filterTime = currentTimeInSeconds - 7 * 24 * 60 * 60;
        break;
      case "30D":
        filterTime = currentTimeInSeconds - 30 * 24 * 60 * 60;
        break;
      default:
        filterTime = 0;
    }

    console.log(filterTime, "filter time");
    console.log(updatedAtTimestampInSeconds);
    return (
      updatedAtTimestampInSeconds >= filterTime &&
      updatedAtTimestampInSeconds <= currentTimeInSeconds
    );
  });

  return (
    <>
      <div className="stat-content-wrapper">
        <div className="stats-table">
          <div className="v-center stats-table-head">
            <div className="c1 hide-on-tablet">
              <label className="small">#</label>
            </div>
            <div className="c2 hide-on-tablet">
              <label className="small">ARTIST</label>
            </div>
            <div className="c3 hide-on-tablet">
              <label className="small">Volume</label>
            </div>
            <div className="c3 hide-on-tablet">
              <label className="small">Works Sold</label>
            </div>
            <div className="c5 hide-on-tablet">
              <label className="small">Highest Sale</label>
            </div>
            <div className="c6 hide-on-tablet">
              <label className="small">Average Sale</label>
            </div>
            <div className="c7 hide-on-tablet">
              <label className="small">Joined</label>
            </div>
            <div className="d-flex w-100 hide-on-desktop2">
              <div className="d-flex w-50">
                <div className="c1">
                  <label className="small">#</label>
                </div>
                <div>
                  <label className="small">Artist</label>
                </div>
              </div>
              <div className="w-50 v-center justify-content-end">
                <label
                  className="small text-black no-text-transform"
                  onClick={artistHandleShow}
                >
                  {
                    artistOptions.find(
                      (option) => option.value === activeArtistValue
                    )?.label
                  }
                </label>
                <img src={drop} alt="" />
              </div>
            </div>
          </div>
          <div className="stats-table-body">
            {filteredArtists.map((item, index) => (
              <div className="stats-table-row v-center" key={index}>
                <div className="c1">
                  <label className="text-black">{index + 1}</label>
                </div>
                <div className="c2 pointer">
                  <Popover
                    placement="top"
                    trigger="hover"
                    content={
                      <ArtistPopUp
                        userProfile={item?.image ? item?.image : profile}
                        artistName={item?.userName}
                        verified={verified}
                      />
                    }
                  >
                    <div className="profile">
                      <img
                        src={item?.image ? item?.image : profile}
                        alt="profile"
                        className="profile-img rounded-circle"
                      />
                      <label className="text-black no-text-transform mx-2 pointer hover-underline">
                        {item?.userName}
                      </label>
                      <img src={verified} alt="verified" className="verified" />
                    </div>
                  </Popover>
                </div>
                <div
                  className={`c3 ${activeArtistValue === "volume"
                    ? "item-show"
                    : "hide-on-tablet"
                    }`}
                >
                  <label className="text-black">
                    {getVolume(item) / 1000000000000000000}$
                  </label>
                </div>
                <div
                  className={`c4 ${activeArtistValue === "works sold"
                    ? "item-show"
                    : "hide-on-tablet"
                    }`}
                >
                  <label className="text-black">{getWorkSold(item)}</label>
                </div>
                <div
                  className={`c5 ${activeArtistValue === "highest sale"
                    ? "item-show"
                    : "hide-on-tablet"
                    }`}
                >
                  <label className="text-black">
                    {getHighestSale(item) / 1000000000000000000}$
                  </label>
                </div>
                <div
                  className={`c6 ${activeArtistValue === "average price"
                    ? "item-show"
                    : "hide-on-tablet"
                    }`}
                >
                  <label className="text-black">
                    {getAverageSale(item) / 1000000000000000000}$
                  </label>
                </div>
                <div
                  className={`c7 ${activeArtistValue === "joined"
                    ? "item-show"
                    : "hide-on-tablet"
                    }`}
                >
                  <label className="text-black text-capitalize">
                    {item?.updatedAt ? formatTimestampToDate(item.updatedAt) : 'No date available'}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Offcanvas
        show={priceShow}
        onHide={artistHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">Change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={artistHandleHide}
              />
            </div>
          </div>
          <div className="share">
            {artistOptions.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeArtistValue === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleIArtistTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeArtistValue === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default TopArtist;
