import React, { useEffect, useState } from "react";
import "../style/main.scss";
import SectionHeader from "./sectionHeader";
import img1 from "../assets/images/face-3.png";
import img2 from "../assets/images/face-4.png";
import img3 from "../assets/images/face-9.png";
import img4 from "../assets/images/face-7.png";
import thumb from "../assets/icon/profile-picture.svg";
import verified from "../assets/icon/verified-artist.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAccount } from "wagmi";
import ArtistPopUp from "./shared/artistpopup";
import {
  getTrendingArtist,
  getAllArtistDetails,
  getUserData,
  getAllUsers,
} from "../firebase/firebase";
import placeholder from "../assets/images/profile-1.svg";
import { useNavigate } from "react-router-dom";
import { Popover, Skeleton } from "antd";
import SkeltonLoader from "./loader/skeltonLoader";

const TrendingArtists = () => {
  const navigate = useNavigate();

  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [trendArtist, setTrendArtist] = useState([]);
  const [allArtist, setAllArtist] = useState([]);
  const [userData, setUserData] = useState([]);
  const getTrendingArtists = async () => {
    setLoading(true);
    const artists = await getTrendingArtist();
    console.log("artists: ", artists);

    const updatedArtists = []; // Array to hold artists with appended userData

    for (let artist of artists) {
      let userData = await getUserData(artist?.artistAddress);
      console.log(userData, "response");
      // Assuming userData is an object containing the data you want to append
      const updatedArtist = { ...artist, ...userData }; // Append userData fields to the artist object
      updatedArtists.push(updatedArtist); // Push the updated artist to the array
      console.log(updatedArtist, "updated artist data");
    }

    setTrendArtist(updatedArtists);
    setLoading(false); // Update state with the array containing artists with appended userData
  };

  useEffect(() => {
    getTrendingArtists();
  }, []);

  const openProfile = (userAddress) => {
    navigate(`/profile/${userAddress}`);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getArtistImage = (accountAddress) => {
    let artistImage = thumb;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = thumb;
        }
      }
    });

    return artistImage;
  };

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";
    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return artistName;
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="trending-artist site-container">
        <SectionHeader
          title="Trending"
          title2="artists"
          isImage={false}
          link={"/stats"}
        />

        <div
          className="trending-artist-content  m-auto"
          style={{ maxWidth: "800px" }}
        >
          {loading ? (
            <div className="d-flex gap-3">
              <div className="d-flex flex-column">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              {trendArtist.slice(0, 4).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="t-artist-card d-flex flex-column align-items-center pointer "
                  >
                    <div
                      className="t-artist-card-head"
                      onClick={() =>
                        navigate(`/profile/${item?.artistAddress}`)
                      }
                    >
                      {item?.image ? (
                        <img
                          src={item?.image}
                          alt="card img"
                          className="img-100"
                        />
                      ) : (
                        <img
                          src={placeholder}
                          alt="card img"
                          className="img-100"
                        />
                      )}
                    </div>
                    <Popover
                      placement="top"
                      trigger="hover"
                      content={
                        <>
                          <ArtistPopUp
                            userProfile={getArtistImage(item?.artistAddress)}
                            verified={verified}
                            artistName={getArtistNamebyAdress(
                              item?.artistAddress
                            )}
                          />
                        </>
                      }
                    >
                      <p
                        onClick={() => {
                          navigate(`/profile/${item?.artistAddress}`);
                        }}
                        className="body-large hover-underline"
                      >
                        {item?.userName.length > 10 ? (
                          <>@{item?.userName.substring(0, 10)}...</>
                        ) : (
                          <>@{item?.userName}</>
                        )}
                      </p>
                    </Popover>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default TrendingArtists;
