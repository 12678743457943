import React, { useEffect, useState, useContext } from "react";
import Collected from "../profile/collected";
import "../../style/main.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import star20 from "../../assets/icon/spiked-circle/black/24px.svg";
import noOffer from "../../assets/icon/offers.svg";
import eye from "../../assets/icon/eye.svg";
import refresh from "../../assets/icon/refresh-metadata.svg";
import plus from "../../assets/icon/plus-white.svg";
import verified from "../../assets/icon/verified-artist.svg";
import thumb from "../../assets/icon/profile-picture.svg";
import tick from "../../assets/icon/tick-small-white.svg";

import Activity from "./activity";
import DiscriptionCollection from "./discriptionCollection";
import Button from "../button";
import AnalyticsCollection from "./analyticsCollection";
import { useLocation, useNavigate } from "react-router-dom";

import { NFTContext } from "../context/appContext";

import FooterV2 from "../footerV2";
import { ClearAllProvider } from "./ClearAllContext";
import {
  getCollectionDetailsFirebase,
  getCollectionStats,
  getNftsInCollectionFirebase,
} from "../../firebase/firebase";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import {
  updateCollectionApprovedStatus,
  getArtistDetailsById,
  getUserData,
} from "../../firebase/firebase";
import { useAccount, useWalletClient } from "wagmi";
import { useSwitchNetwork, useNetwork } from "wagmi";
import Web3 from "web3"; // Import the web3 library

import Loader from "../shared/Loader";
import { toast } from "react-toastify";
import useScrollToTop from "../../customHooks/scrollToTop";
import CardSkelton from "../shared/cardSkelton";
import Footer from "../footer";
import { Modal } from "react-bootstrap";
import { transactionType } from "viem";

const ExploreCollections = () => {
  const [key, setKey] = useState("artwork");
  const [collectionDetails, setCollectionDetails] = useState("");
  const [contractAddress, setContractAddress] = useState();
  const [collectionAddress, setCollectionAddress] = useState();
  const [nftUri, setNftUri] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedNFTData, setApprovedNFTData] = useState([]);
  const [collectionStats, setCollectionStats] = useState([]); // [totalVolume, floorPrice, owners, listed]
  const [createdAt, setCreatedAt] = useState();
  const [listedPercent, setListedPercent] = useState("--");
  const [owners, setOwners] = useState();
  const [totalVolume, setTotalVolume] = useState();
  const [floorPrice, setFloorPrice] = useState(null);
  const [artworkCount, setArtworkCount] = useState();
  const [earning, setEarning] = useState(5);
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { address } = useAccount();
  const [user, setUser] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isNftApproved, setIsNftApproved] = useState(false);
  const [load, setLoad] = useState(true);
  const [isNewtworkConnected, setIsNewtworkConnected] = useState(false);
  const [artistDetails, setArtistDetails] = useState([]);
  const [networkSymbol, setNetworkSymbol] = useState(null);

  const { showModal, updateModal } = useContext(NFTContext);
  const navigate = useNavigate();

  useScrollToTop();

  // backend urls from env
  const Backend_url = process.env.REACT_APP_BACKEND_URL;
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;

  const [web3, setWeb3] = useState(null);
  const location = useLocation();
  const [createFinish, setCreateFinish] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState([]);

  useEffect(() => {
    if (showModal) {
      setCreateFinish(showModal);
    }
  }, [showModal]);

  const hideFinishModel = () => {
    setCreateFinish(false);
    updateModal(false);
    // Optionally navigate away or reset state if needed
  };
  // setting rpc url

  useEffect(() => {
    setSelectedNetwork(collectionDetails?.selectedNetwork);
    if (collectionDetails?.selectedNetwork === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    }
    if (collectionDetails?.selectedNetwork === "Coston2") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [collectionDetails]);

  // selected network

  useEffect(() => {
    if (collectionDetails?.selectedNetwork === chain?.name) {
      setIsNewtworkConnected(true);
    } else {
      setIsNewtworkConnected(false);
    }
  }, [chain, collectionDetails]);

  // check owner of collection
  const checkOwner = async () => {
    const owner = collectionDetails?.address;
    if (address === owner) {
      setIsOwner(true);
    } else {
    }
  };

  const checkIfNftsApproved = async () => {
    let web3_1;

    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    let MarketplaceAddress;
    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    if (web3_1 !== null) {
      const contract = new web3_1.eth.Contract(
        mintContractABI,
        collectionDetails?.contractAddress
      );

      const data = await contract.methods
        .isApprovedForAll(address, MarketplaceAddress)
        .call();
      return data;
    }
  };

  const getstatus = async () => {
    let res = await checkIfNftsApproved();
    console.log(res, "response fo approval");
    setIsNftApproved(res);
  };

  useEffect(() => {
    getstatus();
  }, [collectionDetails]);

  useEffect(() => {
    if (collectionDetails) {
      checkOwner();
    }
  }, [collectionDetails]);

  const handleSwitchNetwork = () => {
    const chainObj = chains.find(
      (chain) => chain.name === collectionDetails?.selectedNetwork
    );
    switchNetwork?.(chainObj?.id);
  };

  // Replace this with your contract address and ABI
  const contractABI = mintContractABI;

  // Replace this with the function call to get NFTs from your contract
  const getUserNftsFromContract = async () => {
    setLoading(true);

    try {
      if (web3 !== null) {
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const contractOwner = await contract.methods.owner().call();

        contract.methods
          .getTokenId(collectionAddress)
          .call({ from: contractOwner }, (error, result) => {
            if (error) {
              console.error(error);
            } else {
              if (result?.length > 0) {
                const tokens_uri = result?.map((token) => token?.uri);
                console.log(tokens_uri, "urriiii");
                setNftUri(tokens_uri);
                getNftMetadata(tokens_uri);
              } else {
                setNftUri([]);
                setLoad(false);
              }
            }
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // get nfts from blockchain

  const getGuestNftsFromContract = async () => {
    setLoading(true);
    try {
      if (web3 !== null) {
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const contractOwner = await contract.methods.owner().call();
        contract.methods
          .getTokenId(collectionAddress)
          .call({ from: contractOwner }, (error, result) => {
            if (error) {
              console.error(error);
              console.log("collection token id issue");
            } else {
              console.log("result of uri: ", result);
              if (result?.length > 0) {
                const tokens_uri = result?.map((token) => token?.uri);

                setNftUri(tokens_uri);
                getNftMetadata(tokens_uri);
              } else {
                setNftUri([]);
                setLoad(false);
              }
            }
            setLoad(false);
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // get nft metadata from aws

  const getNftMetadata = async (tokens_uri) => {
    setLoading(true);
    const nftMetadata = await Promise.all(
      tokens_uri.map(async (uri) => {
        const response = await fetch(`${Backend_url}/getNFTMetadata`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uri: uri,
          }),
        });
        const json = await response.json();
        return {
          uri: uri, // Include the URI in the result object
          data: json.data.data,
        };
      })
    );
    setLoad(false);
    setLoading(false);
    setNfts(nftMetadata);
  };

  // approve contract for user

  const ApproveAllNFT = async () => {
    setLoading(true);
    try {
      const web3_1 = new Web3(window.ethereum);
      const accounts = await web3_1.eth.getAccounts();
      const contract = new web3_1.eth.Contract(contractABI, contractAddress);
      const contractOwner = await contract.methods.owner().call();
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      contract.methods
        .setApprovalForAll(MarketplaceAddress, true)
        .send({ from: accounts[0] }, (error, result) => {
          if (error) {
            console.error(error);
            setLoading(false);
          } else {
            updateCollectionApprovedStatus(
              location.pathname.split("/")[2],
              true
            );
            getCollectionDetails();
            setLoading(false);
          }
        });
      checkIfNftsApproved();
    } catch (error) {
      alert("Transaction Rejected");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contractAddress) {
      if (isOwner) {
        getUserNftsFromContract();
      } else {
        getGuestNftsFromContract();
      }
    }
  }, [collectionDetails, contractAddress, web3, collectionAddress]);

  const getCollectionDetails = async () => {
    const result = await getCollectionDetailsFirebase(
      location.pathname.split("/")[2]
    );
    console.log(result, "resule");
    if (result?.selectedNetwork === "Coston") {
      setNetworkSymbol("CFLR");
    } else if (result?.selectedNetwork === "Coston2") {
      setNetworkSymbol("C2FLR");
    } else if (result?.selectedNetwork === "Flare") {
      setNetworkSymbol("FLR");
    } else if (result?.selectedNetwork === "Songbird") {
      setNetworkSymbol("SGB");
    }
    setCollectionDetails(result);
    setContractAddress(result?.contractAddress);
    setCollectionAddress(result?.address);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.enable();
    } else {
      alert("Please install MetaMask to use this dApp!");
    }

    getCollectionDetails();
  }, []);

  // get nft data from firebase

  const getNftsInCollection = async () => {
    const result = await getNftsInCollectionFirebase(
      location.pathname.split("/")[2]
    );
    setNfts(result);
  };

  // get nft stats details from firebase

  const getCollectionStatDetails = async () => {
    if (location.pathname.split("/")[2]) {
      try {
        const result = await getCollectionStats(
          location.pathname.split("/")[2]
        );
        setCollectionStats(result);

        console.log(result, "result for collectionStatDetails");
        if (
          (result && result.hasOwnProperty("volume")) ||
          result.hasOwnProperty("floorPrice")
        ) {
          // Set total volume
          setTotalVolume(
            result.volume.length > 0
              ? result.volume[result.volume.length - 1].split("-")[0] || 0
              : 0
          );

          // Set floor price
          // setFloorPrice(result.minFloorPrice || "--");

          // Set owners count
          setOwners(result.owners ? result.owners.length || 0 : 0);

          // Set listed percent
          console.log(result, "listedCount");
          // if (result.listedCount === 0 || result.artworkCount === 0) {
          //   setListedPercent(0);
          // } else {
          //   setListedPercent(
          //     ((result.listedCount / result.artworkCount) * 100).toFixed(2) || 0
          //   );
          // }

          // Set created at date
          const date = new Date(result.createdAt);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const dateString = `${months[date.getMonth()]} ${date.getFullYear()}`;
          setCreatedAt(dateString);

          // Set artwork count
          setArtworkCount(result.artworkCount || 0);

          // Set earning
          // setEarning(result.creatorEarning);
        } else {
          console.error("Incomplete or missing data in result object");
        }
      } catch (error) {
        console.error("Error fetching collection stats:", error);
      }
    }
  };

  useEffect(() => {
    getNftsInCollection();
    getCollectionStatDetails();
  }, []);

  // fetch artist data from firebase

  useEffect(() => {
    const fetchArtistDetails = async () => {
      try {
        const details = await getUserData(collectionDetails?.address);
        setUser(details);
        console.log(details, "detailss");

        setArtistDetails(details);
      } catch (error) { }
    };

    fetchArtistDetails();
  }, [collectionDetails]);
  // Function to convert price from wei to ether

  const convertWeiToEther = (priceInWei) => {
    // Convert the price from wei to ether
    if (priceInWei) {
      const priceInEther = web3?.utils.fromWei(priceInWei?.toString(), "ether");
      return priceInEther;
    }
  };

  useEffect(() => {
    console.log(collectionStats, "collection stats");
  }, [collectionStats]);



  const navigateToExplorer = (address, selectedBlockchain) => {
    console.log(selectedBlockchain, "selected blockchain");
    console.log(address, "address");

    if (selectedBlockchain === "Coston") {
      window.open(
        `https://coston-explorer.flare.network/tx/${address}`,
        "_blank"
      );
    } else {
      window.open(
        `https://coston2-explorer.flare.network/tx/${address}`,
        "_blank"
      );
    }
  };
  return (
    <div>
      <div className="explore-collections site-container">
        <div className="header">
          <div className="left">
            <div className="artists-name v-center">
              <label className="no-text-transform br-30 ms-0">
                {collectionDetails?.selectedNetwork}
              </label>
              <label
                onClick={() => navigateToExplorer(collectionDetails?.transactionHash, collectionDetails?.selectedNetwork)}
                className="br-30 ms-2 pointer"
              >
                erc-721
              </label>
            </div>
            <div className="collection-name d-flex flex-column mt-3">
              <h4 className="no-text-transform ">{collectionDetails?.name}</h4>

              <h4>{collectionDetails?.userName}</h4>
              <label className="fw-500 medium no-text-transform mb-1 hide-on-desktop">
                Created by
              </label>
              <div className="artists-name v-center flex-row align-items-center off-white-border width-fit  p-1 br-30 pointer">
                <img
                  src={artistDetails?.image ? artistDetails?.image : thumb}
                  alt="verify"
                  className="img-35 img-fluid  rounded-circle p-br-1"
                />

                {artistDetails?.userName ? (
                  <label
                    onClick={() => {
                      navigate(`/profile/${artistDetails?.id}`);
                    }}
                    className="medium fw-bold ps-1 text-black no-text-transform v-center bg-transparent m-0 pe-0 pointer"
                  >
                    @
                    {artistDetails?.userName.length > 18
                      ? artistDetails?.userName.substring(0, 18) + "..."
                      : artistDetails?.userName}
                    <img
                      src={verified}
                      alt="verify"
                      className="img-18 img-fluid ms-2 "
                    />
                  </label>
                ) : (
                  <>
                    <label className="medium fw-bold ps-1 text-black no-text-transform v-center bg-transparent m-0 pe-0">
                      Artists Name
                      <img
                        src={verified}
                        alt="verify"
                        className="img-18 img-fluid ms-2 "
                      />
                    </label>
                  </>
                )}
              </div>
            </div>

            {collectionDetails?.isWhiteList && isOwner ? (
              <div className="whitelisted-btn v-center gap-3 mt-4">
                <Button
                  text="Edit Collection"
                  className="btn-prime btn-secondary"
                  width="132px"
                  height="36px"
                  onClick={() =>
                    navigate(
                      `/edit-collections/${location.pathname.split("/")[2]}`
                    )
                  }
                />
                <Button
                  text="Create Artwork"
                  className="btn-prime btn-primary"
                  width="150px"
                  height="36px"
                  imageClassName="img-18 me-2"
                  imageSrc={plus}
                  onClick={() => {
                    if (user?.isWhiteListed === false) {
                      toast.error("Artist Not Approved");
                    } else {
                      navigate(
                        `/create-art/${location.pathname.split("/")[2]}`,

                        {
                          state: {
                            CollectionName: collectionDetails?.name,
                            symbol: collectionDetails?.symbol,
                            data: collectionDetails,
                          },
                        }
                      );
                    }
                  }}
                />

                {/*  Approve All NFt to Marketplace */}
                {/* {isNewtworkConnected ? (
                  <Button
                    text="Approve All NFTs"
                    className={`btn-prime ${
                      !isNftApproved ? "btn-primary" : "btn-ternary"
                    }`}
                    width="150px"
                    height="36px"
                    imageClassName="img-18 me-2"
                    disabled={isNftApproved}
                    imageSrc={plus}
                    onClick={() => ApproveAllNFT()}
                  />
                ) : (
                  <Button
                    text="Switch Network to Approve NFTs"
                    className={`btn-prime p-1 ${
                      !collectionDetails?.Approved
                        ? "btn-primary"
                        : "btn-ternary"
                    }`}
                    width="260px"
                    height="36px"
                    imageClassName="img-18 me-2"
                    disabled={collectionDetails?.Approved}
                    imageSrc={plus}
                    onClick={() => handleSwitchNetwork()}
                  />
                )} */}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="right">
            <div className="art-creation v-center">
              <label className="medium">
                <span className="fw-500 no-text-transform">Artworks</span>
                <span className="fw-bold text-black no-text-transform ms-1">
                  {artworkCount || 0}
                </span>
              </label>
              <div className="divider hide-on-mobile"></div>
              <label className="medium hide-on-mobile">
                <span className="fw-500 no-text-transform">Created</span>
                <span className="fw-bold text-black no-text-transform ms-1">
                  {createdAt || 0}
                </span>
              </label>

              <div className="divider"></div>
              <label className="medium">
                <span className="fw-500 no-text-transform">
                  Creator Earnings
                </span>
                <span className="fw-bold text-black no-text-transform ms-1">
                  {earning || 5}%
                </span>
              </label>
            </div>
            <div className="collection-summary v-center">
              <div className="t-volume pr35">
                <p className="fw-bold text-center">
                  {totalVolume > 0
                    ? `${convertWeiToEther(totalVolume)} ${networkSymbol}`
                    : "--"}
                </p>
                <label htmlFor="" className="medium no-text-transform">
                  Total Volume
                </label>
              </div>

              <div className="divider"></div>

              <div className="t-volume pr35 pl35">
                <p className="fw-bold text-center">
                  {floorPrice > 0 ? `${floorPrice} ${networkSymbol}` : "--"}
                </p>
                <label htmlFor="" className="medium no-text-transform">
                  Floor Price
                </label>
              </div>

              <div className="divider"></div>

              <div className="t-volume pl35 pr35">
                <p className="fw-bold text-center">
                  {owners > 0 ? owners : "--"}
                </p>
                <label htmlFor="" className="medium no-text-transform">
                  Owners
                </label>
              </div>

              <div className="divider"></div>

              <div className="t-volume pl35">
                <p className="fw-bold text-center">
                  {isNaN(listedPercent) ? "--" : `${listedPercent}%`}
                </p>
                <label htmlFor="" className="medium no-text-transform">
                  Listed
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="profile-tabs mt-40 ">
          <Tabs
            defaultActiveKey="artwork"
            id="uncontrolled-tab-example"
            className="mb-3 profile-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              eventKey="artwork"
              title={
                <span>
                  <img
                    src={key == "artwork" ? star20 : star20}
                    alt="star"
                    className={
                      key == "artwork"
                        ? "hide-on-mobile spikeimg "
                        : "hide-on-mobile opacity-50"
                    }
                  />
                  artwork
                </span>
              }
            >
              {loading ? (
                <div
                  className={`grid-display artworkGrid ? "w-75 active" : ""
                 `}
                >
                  <CardSkelton />
                  <CardSkelton />
                  <CardSkelton />
                  <CardSkelton />
                </div>
              ) : (
                <>
                  <ClearAllProvider>
                    <Collected
                      propFromCollections="d-none"
                      nfts={nfts}
                      ApvNFT={collectionDetails}
                      isOwner={isOwner}
                      loading={load}
                      setNfts={setNfts}
                      setLoading={setLoad}
                      setListedPercent={setListedPercent}
                      setFloorPrice={setFloorPrice}
                    />
                  </ClearAllProvider>
                </>
              )}
            </Tab>

            {/* Activity */}

            <Tab
              eventKey="activity"
              title={
                <span>
                  <img
                    src={key == "activity" ? star20 : refresh}
                    alt="activity"
                    className={
                      key == "activity"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile opacity-25 img-18"
                    }
                  />
                  activity
                </span>
              }
            >
              <Activity />
            </Tab>

            {/* analytics */}

            {/* <Tab
              eventKey="analytics"
              title={
                <span>
                  <img
                    src={key == "analytics" ? star20 : eye}
                    alt="analytics"
                    className={
                      key == "analytics"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile opacity-25 img-18"
                    }
                  />
                  analytics
                </span>
              }
            >
              <AnalyticsCollection stats={collectionStats} />
            </Tab> */}

            {/* Discription */}

            <Tab
              eventKey="description"
              title={
                <span>
                  {/* if no offer */}
                  <img
                    src={key == "description" ? star20 : noOffer}
                    alt="description"
                    className={
                      key == "description"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile"
                    }
                  />
                  description
                </span>
              }
            >
              <DiscriptionCollection
                hide="d-none"
                description={collectionDetails?.description}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer />

      <Modal
        show={createFinish}
        onHide={hideFinishModel}
        className="sign-modal create-collection-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              success
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="create-collection-modal justify-content-center">
            <div className="pb-5 v-center flex-column top-content">
              <div className="wallet h-center m-auto">
                <img
                  src={tick}
                  alt="tick"
                  className="img-100 invert1 border rounded-circle p-3"
                />
              </div>
              <p className="body-medium text-center mt-3 pt-1 text-center ">
                Your smart contract has been deployed to the{" "}
                {selectedNetwork === "Coston2" ? "Flare" : "Songbird"} Network!
              </p>
              <label className="mt-30 pt-1 no-text-transform text-black text-center w-100">
                Congratulations!
              </label>
            </div>
            <div className="on-success mt-2 v-center gap-4 h-center">
              <Button
                text="Done"
                className="btn-prime btn-primary"
                width="175px"
                height="36px"
                onClick={hideFinishModel}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ExploreCollections;
