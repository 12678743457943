import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import { Accordion } from "react-bootstrap";
import Button from "../button";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import search from "../../assets/icon/search-grey.svg";
import down from "../../assets/icon/chevron-down-small.svg";
import Input from "../inputs";
import CustomCheckBox from "../shared/customTags";
import { set } from "firebase/database";
import { useFeeData } from "wagmi";
import { ClearAllContext } from "./ClearAllContext";
import { NFTContext } from "../context/appContext";

const AsideFilterExplore = ({ onSelectedFilterChange }) => {
  const [isChange, setIsChange] = useState(false);
  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const [priceCurrency, setPriceCurrency] = useState("USD");
  const [isFilterChange, setIsFilterChange] = useState(false);
  const { change } = useContext(NFTContext);

  const [selectStatus, setSelectStatus] = useState({
    buyNow: false,
    onOffer: false,
    All: true,
  });
  const [selectCurrency, setSelectCurrency] = useState({
    allChains: true,
    flr: false,
    sgb: false,
    coston: false,
    coston2: false,
  });
  const [selectCategories, setSelectCategories] = useState({
    "3D": false,
    Animation: false,
    Generative: false,
    Phygital: false,
    Geometric: false,
    Fantasy: false,
    Surreal: false,
    Illustration: false,
    AI: false,
    Photography: false,
    Portrait: false,
    Landscape: false,
    Audio: false,
    Nature: false,
    Drawing: false,
    Painting: false,
  });
  const [selectPrice, setSelectPrice] = useState({
    min: "",
    max: "",
  });

  // State for Status filter
  const handleStatusFilter = (selectedStatus) => {
    setSelectStatus((prevSelectedStatus) => ({
      buyNow: selectedStatus === "buyNow",
      onOffer: selectedStatus === "onOffer",
      All: selectedStatus === "All",
    }));
    setIsChange(true);
  };

  // handle currency filter

  const handleCurrencyFilter = (tag) => {
    setSelectCurrency((prevSelectedTags) => ({
      ...prevSelectedTags,
      allChains: false, // Deselect all currencies
      flr: false,
      sgb: false,
      coston: false,
      coston2: false,
      [tag]: true, // Select the clicked currency
    }));
    setIsChange(true);
  };

  // State for Price filter
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const handleCategoriesFilter = (tag) => {
    setSelectCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [tag]: !prevSelectedCategories[tag], // Toggle the selection state
    }));
    setIsChange(true);
  };
  const [isPrimary, setIsPrimary] = useState(false);
  useEffect(() => {
    if (minPrice || maxPrice) {
      setIsPrimary(true);
    } else {
      setIsPrimary(false);
    }
  }, [minPrice, maxPrice]);

  useEffect(() => {
    console.log(isPrimary, "primary");
  }, [isPrimary]);

  const handlePriceFilter = () => {
    setSelectPrice({
      min: minPrice,
      max: maxPrice,
    });
    setIsChange(true);
  };

  useEffect(() => {
    console.log(selectPrice, "selected price");
  }, []);

  // search

  const SearchFilter = () => {
    onSelectedFilterChange({
      selectStatus,
      selectCurrency,
      selectCategories,
      selectPrice,
    });
    // setIsChange(false);
  };

  useEffect(() => {
    SearchFilter();
  }, [
    selectCategories,
    selectCurrency,
    selectStatus,
    selectPrice,
    maxPrice,
    minPrice,
  ]);
  useEffect(() => {
    console.log(clearAll, "Clear all");
    if (clearAll) {
      clearAllFilter();
      setClearAll(false); // Reset the clear flag after clearing filters
    }
  }, [clearAll]);

  const clearAllFilter = () => {
    setSelectStatus({
      buyNow: false,
      onOffer: false,
      All: true,
    });
    setSelectCurrency({
      allChains: true,
      flr: false,
      sgb: false,
      coston: false,
      coston2: false,
    });
    setSelectCategories({
      "3D": false, // Use the same keys as in useState
      Animation: false,
      Generative: false,
      Phygital: false, // Use the same keys as in useState
      Geometric: false, // Use the same keys as in useState
      Fantasy: false, // Use the same keys as in useState
      Surreal: false,
      Illustration: false,
      AI: false,
      Photography: false,
      Portrait: false,
      Landscape: false,
      Audio: false,
      Nature: false,
      Drawing: false,
      Painting: false,
    });
    setSelectPrice({
      min: "",
      max: "",
    });
    setMinPrice("");
    setMaxPrice("");
    setIsChange(false);
    SearchFilter();
  };
  useEffect(() => {
    change(isChange);
    console.log(isChange, "is filter change");
  }, [isChange, selectCategories, selectPrice, selectCurrency, selectStatus]);
  return (
    <>
      <div className="collection-multi-filter">
        <div className="multi-filter-options">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Status
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-filter collection-status-filter">
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectStatus.All ? "selected" : ""
                    }`}
                    onClick={() => handleStatusFilter("All")}
                  >
                    <img
                      src={selectStatus.All ? checked : unCheck}
                      alt="checkbox"
                    />
                    All
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectStatus.buyNow ? "selected" : ""
                    }`}
                    onClick={() => handleStatusFilter("buyNow")}
                  >
                    <img
                      src={selectStatus.buyNow ? checked : unCheck}
                      alt="checkbox"
                    />
                    Buy now
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectStatus.onOffer ? "selected" : ""
                    }`}
                    onClick={() => handleStatusFilter("onOffer")}
                  >
                    <img
                      src={selectStatus.onOffer ? checked : unCheck}
                      alt="checkbox"
                    />
                    On offer
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* Categories filter */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Categories
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="pb-3 mt-22">
                  <CustomCheckBox
                    values={[
                      "3D",
                      "Animation",
                      "Generative",
                      "Phygital",
                      "Geometric",
                      "Fantasy",
                      "Surreal",
                      "Illustration",
                      "AI",
                      "Photography",
                      "Portrait",
                      "Landscape",
                      "Audio",
                      "Nature",
                      "Drawing",
                      "Painting",
                    ]}
                    selectedValues={selectCategories || {}}
                    onChange={(e) => handleCategoriesFilter(e.target.value)}
                    disabled={false}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* Pricing filter */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Price
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="curreny-filter v-center">
                  <div className="dropdown me-3">
                    <button
                      className=" dropdown-toggle body-large fw-normal"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {priceCurrency}
                      <img src={down} alt="down" className="ms-2" />
                    </button>
                    <ul className="dropdown-menu ">
                      <li onClick={() => setPriceCurrency("USD")}>
                        <label className="text-black fw-normal">USD</label>
                      </li>
                      <li onClick={() => setPriceCurrency("FLR")}>
                        <label className="text-black fw-normal">FLR</label>
                      </li>
                      <li onClick={() => setPriceCurrency("SGB")}>
                        <label className="text-black fw-normal">SGB</label>
                      </li>
                    </ul>
                  </div>
                  <div className="input-val v-center">
                    <input
                      type="number"
                      placeholder="Min"
                      value={minPrice}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value > 0) {
                          setMinPrice(value);
                        }
                      }}
                      
                    />
                    <p className="body-large m-2">to</p>
                    <input
                      type="number"
                      placeholder="Max"
                      value={maxPrice}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value > 0) {
                          setMaxPrice(value);
                        }
                      }}
                      
                  
                    />
                  </div>
                  <div className="apply-val w-100">
                    <Button
                      text="Apply"
                      className={`btn-prime br-30 ${
                        isPrimary ? "btn-primary" : "btn-ternary"
                      } text-medium-grey`}
                      width="100%"
                      height="47px"
                      onClick={handlePriceFilter}
                      disabled={isPrimary ? "" : "disabled"}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* curreny filter */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Currency
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-filter collection-status-filter">
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.allChains ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("allChains")}
                  >
                    <img
                      src={selectCurrency.allChains ? checked : unCheck}
                      alt="checkbox"
                    />
                    All chains
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.flr ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("flr")}
                  >
                    <img
                      src={selectCurrency.flr ? checked : unCheck}
                      alt="checkbox"
                    />
                    FLR
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.sgb ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("sgb")}
                  >
                    <img
                      src={selectCurrency.sgb ? checked : unCheck}
                      alt="checkbox"
                    />
                    SGB
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.coston ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("coston")}
                  >
                    <img
                      src={selectCurrency.coston ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.coston2 ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("coston2")}
                  >
                    <img
                      src={selectCurrency.coston2 ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON2
                  </p>
                  {/* <p className="body-large h-64 fw-normal v-center cursor-pointer">
                      <img src={unCheck} alt="checkbox" />
                      SGB
                    </p> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* <div className="multi-filter-footer">
            <Button
              text="Clear all"
              // className="btn-prime btn-secondary"
              className={`btn-prime ${
                isChange ? "btn-secondary" : "btn-ternary"
              } `}
              width="49%"
              height="47px"
              onClick={clearAllFilter}
            />
            <Button
              text="Done"
              // className="btn-prime btn-primary"
              className={`btn-prime ${
                isChange ? "btn-primary" : "btn-ternary"
              } `}
              width="49%"
              height="47px"
              onClick={SearchFilter}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AsideFilterExplore;
