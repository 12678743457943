import React, { useState, useEffect, useRef } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import option from "../../assets/icon/more-vertical.svg";
import sale from "../../assets/icon/list-for-sale.svg";
import transfer from "../../assets/icon/transfer.svg";
import copyLink from "../../assets/icon/link.svg";
import view from "../../assets/icon/eye.svg";
import eidt from "../../assets/icon/edit.svg";
import dropdown from "../../assets/icon/chevron-down-extra-small.svg";
import { Offcanvas } from "react-bootstrap";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import Dropdown from "../shared/dropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import {
  getAllUsers,
  getArtLastPriceFromFirebase,
} from "../../firebase/firebase";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import AsideFilterExplore from "../explore/asideFilterExplore";

import { useAccount } from "wagmi";
import { Table } from "antd";

const LayoutRow = (props) => {
  const [show, setShow] = useState({});
  const [activePrice, setActivePrice] = useState("listPrice");
  const [priceShow, setPriceShow] = useState(false);
  const dropdownRef = useRef(null);

  const { address } = useAccount();
  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(collectionData);
  const [tokenId, setTokenId] = useState();
  const location = useLocation();
  const [userData, setUserData] = useState([]);

  const [web3, setWeb3] = useState(new Web3(window.ethereum));
  const [isNftApproved, setIsNftApproved] = useState(false);

  // marketplace contract address
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  // setting rpc url
  useEffect(() => {
    if (collectionDetails?.selectedNetwork === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    } else {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [collectionDetails]);

  // price Filter handle
  const priceHandleShow = () => setPriceShow(true);
  const priceHandleHide = () => setPriceShow(false);

  const showOption = (index) => {
    setShow({ [index]: true });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  // ----------- Handle option menu end

  const handleIPriceTick = (tick) => {
    setActivePrice(tick);
  };

  const priceOptions = [
    { value: "listPrice", label: "List price" },
    { value: "lastPrice", label: "Last sale price" },
    { value: "bestOffer", label: "Best offer" },
  ];

  // get all listed data from blockchain

  const getAllListNFTData = async () => {
    if (collectionDetails) {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const data = await contract.methods.getAllListedNfts().call();

      setListedNfts(data);
    }
  };

  useEffect(() => {
    setNftDetails(props?.nftDetails);
    getAllListNFTData();
  }, [props?.nftDetails]);

  // mapping and setting listed data to nfts

  useEffect(() => {
    if (props?.nfts !== undefined) {
      let nftListDetails = [];
      console.log(listedNfts, "listed nfts");

      props?.nfts.forEach((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });

      console.log(nftListDetails, "nftdetails");

      // Fetch and set last price for each NFT
      nftListDetails.forEach(async (e) => {
        console.log(e?.data?.artId, "artid");
        let Price = await getArtLastPriceFromFirebase(e?.data?.artId);
        console.log(Price, "price");
        if (Price) {
          e.data.lastPrice = Price;
        } else {
          e.data.lastPrice = 0;
        }
      });

      console.log(nftFilteredDetails, "nft filtered details");
      bid(nftListDetails);

      setCollectionData(nftListDetails);
    } else {
      setCollectionData([]);
      setNftFilteredDetails([]);
    }
  }, [nftDetails, listedNfts, props?.nfts]);

  // Filters (Recently Minted, Recently listed, lowest price , highest price)

  useEffect(() => {
    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = collectionData.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = collectionData
          .filter((item) => {
            return item.isListed; // Only keep listed items
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {
        if (collectionData.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed; // Only keep listed items
            })
            .sort((a, b) => {
              // Prioritize sale listings over offer listings
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(a?.listedData?.price) -
                  parseFloat(b?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(a?.listedData?.minimumBid) -
                  parseFloat(b?.listedData?.minimumBid)
                );
              }
            });
        }
      } else if (props?.sortFilter === "Highest price") {
        if (collectionData.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed; // Only keep listed items
            })
            .sort((a, b) => {
              // Prioritize sale listings over offer listings
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(b?.listedData?.price) -
                  parseFloat(a?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(b?.listedData?.minimumBid) -
                  parseFloat(a?.listedData?.minimumBid)
                );
              }
            });
        }
      }
      setNftFilteredDetails(sortedData);
    }
  }, [props?.sortFilter]);

  // side filters (buy now, offer now,price, category, network)

  const handleSelectedFilterChange = (newFilter) => {
    let filteredData = [];
    collectionData.filter((item) => {
      const isCategorySelected = Object.values(newFilter.selectCategories).some(
        (category) => category
      );
      let categoriesFilter;
      if (isCategorySelected) {
        categoriesFilter = Object.keys(newFilter.selectCategories).every(
          (category) =>
            newFilter.selectCategories[category]
              ? item.data.selectedTags.includes(category)
              : true
        );
      } else {
        categoriesFilter = true;
      }

      // Filter by currency
      const selectedCurrency = Object.values(newFilter.selectCurrency).some(
        (currency) => currency
      );
      let currencyFilter = true;

      if (selectedCurrency) {
        currencyFilter =
          selectedCurrency &&
          (newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr &&
              item.data.selectedBlockchain.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb &&
              item.data.selectedBlockchain.toLowerCase() ===
              "songbird network") ||
            (newFilter.selectCurrency.coston &&
              item.data.selectedBlockchain.toLowerCase() === "coston") ||
            (newFilter.selectCurrency.coston2 &&
              item.data.selectedBlockchain.toLowerCase() === "coston2"));
      }

      // Filter by price
      let priceFilter;
      if (
        newFilter.selectPrice.min === "" &&
        newFilter.selectPrice.max === ""
      ) {
        priceFilter = true;
      } else {
        if (item.isSaleListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.price / 1000000000000000000) >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.price / 1000000000000000000) <=
              parseFloat(newFilter.selectPrice.max));
        }
        if (item.isOfferListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.minimumBid) >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.minimumBid) <=
              parseFloat(newFilter.selectPrice.max));
        }
      }
      let statusFilter = false;

      if (
        newFilter.selectStatus.buyNow ||
        newFilter.selectStatus.onOffer ||
        newFilter.selectStatus.new
      ) {
        statusFilter =
          (newFilter.selectStatus.buyNow && item.isSaleListed) ||
          (newFilter.selectStatus.onOffer && item.isOfferListed) ||
          (newFilter.selectStatus.new && !item.isListed);
      } else {
        statusFilter = true;
      }

      // Return item if all filters pass
      if (categoriesFilter && currencyFilter && priceFilter && statusFilter) {
        filteredData.push(item);
      }
    });

    // setting number of items
    props.setItemNumber(filteredData?.length);

    setNftFilteredDetails(filteredData);
  };

  // setting number of total nfts of filter change.

  useEffect(() => {
    props.setItemNumber(nftFilteredDetails.length);
  }, [nftFilteredDetails]);

  useEffect(() => {
    const getToken = async () => {
      let res = await getTokenIdforNoList();
      setTokenId(res);
    };
    getToken();
  }, [collectionDetails]);

  // get user data from firebase

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };

  // check if nfts are apporoved

  const checkIfNftsApproved = async () => {
    let web3_1;

    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    let MarketplaceAddress;
    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    if (web3_1 !== null) {
      const contract = new web3_1.eth.Contract(
        mintContractABI,
        collectionDetails?.contractAddress
      );

      const data = await contract.methods
        .isApprovedForAll(address, MarketplaceAddress)
        .call();

      return data;
    }
  };

  // function to check if nfts are approved or not

  const getstatus = async () => {
    let res = await checkIfNftsApproved();
    setIsNftApproved(res);
  };

  useEffect(() => {
    getstatus();
  }, [address]);

  const getUserNftsFromContract = async (uri) => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri === uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const bid = async (nfts) => {
    let data = nfts;
    let offerListed = nfts.filter((item) => item.isOfferListed);
    let MarketplaceAddress;

    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    // getting bidding history from blockchain-

    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
    const updatedDetails = await Promise.all(
      offerListed.map(async (item) => {
        const biddingHistoryCoston = await contract.methods
          .getBiddingHistory(item?.listCount)
          .call();

        // Calculate max price from bidding history
        const maxPrice = calculateMaxPrice(biddingHistoryCoston);

        return { ...item, maxPrice: maxPrice / 1000000000000000000 }; // Assuming maxPrice is in wei, converting to ether
      })
    );

    setNftFilteredDetails((prevDetails) => {
      const unchangedItems = data.filter((prevItem) => !prevItem.isOfferListed);
      const mergedDetails = [
        ...unchangedItems,
        ...updatedDetails.filter(Boolean),
      ];
      return mergedDetails;
    });
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (currentPrice && !isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };

  // get nfts token for nfts that are not listed

  const getTokenIdforNoList = async (uri) => {
    try {
      let TokenId = await getUserNftsFromContract(uri);
      let tokenId = TokenId;
      return tokenId;
    } catch (error) {
      console.error(error);
    }
  };
  const navigateToArt = async (item) => {
    console.log(item, "item test tst");
    let token = await getTokenIdforNoList(item?.uri);
    console.log(token);
    navigate(
      `/artwork/${item.data.selectedBlockchain}/${item?.data.selectedCollectionId}/${token}`
    );
  };

  useEffect(() => {
    console.log(nftFilteredDetails, "nft filter details");
  }, [nftFilteredDetails]);
  const columns = [
    {
      title: `${nftFilteredDetails?.length} ITEMS`,
      key: "items",
      width: "400px",
      render: (_, record) => (
        <div className="d-flex align-items-center">
          <div className="art-img">
            {record.data.image ? (
              <img
                src={record.data.image}
                alt="artwork image"
                className="img-100"
              />
            ) : (
              <img
                src={record.data.previewImg}
                alt="artwork image"
                className="img-100"
              />
            )}
          </div>
          <div className="user-name d-flex flex-column">
            <label
              onClick={() => navigateToArt(record)}
              className="text-black text-capitalize hover-underline pointer"
            >
              {record?.data?.artName.length > 20 ? (
                <>
                  {record?.data?.artName.substring(0, 20) || "Art Name"}
                  ...
                </>
              ) : (
                <>{record?.data?.artName || "Art Name"}</>
              )}
            </label>
            <p className="body-medium fw-bold v-center text-medium-grey hover-underline pointer show-artist-popup">
              {record?.data.isSaleListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.listedData.seller}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.listedData.seller).length > 18
                    ? getArtistNamebyAdress(
                      record?.listedData.seller
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.listedData.seller)}
                </span>
              )}

              {record?.data.isOfferListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.newOwner}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.newOwner).length > 18
                    ? getArtistNamebyAdress(record?.newOwner).substring(
                      0,
                      18
                    ) + "..."
                    : getArtistNamebyAdress(record?.newOwner)}
                </span>
              )}
              {!record?.data.isListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.data.artistAddress}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.data.artistAddress).length > 18
                    ? getArtistNamebyAdress(
                      record?.data.artistAddress
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.data.artistAddress)}
                </span>
              )}
              <img src={verified} alt="verified" className="ms-2 img-18" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "List Price",
      key: "List Price",
      render: (_, record) => (
        <div>
          <label className="text-black">
            {record?.isListed
              ? record?.isSaleListed
                ? record?.listedData?.price / 1000000000000000000
                : "--"
              : "--"}
          </label>
        </div>
      ),
    },
    {
      title: "Last Sale Price",
      key: "Last Sale Price",
      render: (_, record) => (
        <>
          <label className="medium text-black">
            {record?.data?.lastPrice / 1000000000000000000 || "--"}
          </label>
        </>
      ),
    },
    {
      title: "Best Offer",
      key: "Best Offer",
      render: (_, record) => (
        <div>
          <label className="text-black">{record?.maxPrice || "--"}</label>
        </div>
      ),
    },
    {
      title: "",
      key: "options",
      render: (_, record, index) => (
        <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end ">
          <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown">
            <img
              src={option}
              alt=""
              className="dropdown-toggle no-after"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu no-border bg-transparent">
              <Dropdown
                listed={record?.isSaleListed ? "listed" : "noListed"}
                item={record}
                tokenId={record.listedData?.tokenId}
                row="row"
                transfer={collectionDetails?.Approved}
              />
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex ">
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
          />
        </div>

        {/* custom old table */}
        <div
          className={`row-layout hide-on-desktop flex-column ${props.flag ? "w-75 active" : "w-100"
            }`}
        >
          <div className="row-layout-head justify-content-between">
            <label className="small head-1 c1">
              {nftFilteredDetails?.length} Items
            </label>
            <label className="small head-1 c2 hide-on-mobile">List Price</label>
            <label
              className="small head-1 c4 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Last Sale Price
            </label>
            <label
              className="small head-1 c3 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Best Offer
            </label>
            {props?.isOwner && (
              <label className="small head-1 c5 hide-on-mobile pe-4"></label>
            )}
            <label
              className="small text-black d-flex align-items-center justify-content-end hide-on-desktop w-50 "
              onClick={priceHandleShow}
            >
              {
                priceOptions.find((option) => option.value === activePrice)
                  ?.label
              }
              <img src={dropdown} alt="dropdown" />
            </label>
          </div>

          {/* row layout content */}
          <div className="row-layout-body">
            {props?.nfts == undefined ? (
              <></>
            ) : (
              nftFilteredDetails.map((item, index) => {
                return (
                  <div
                    className="row-content d-flex align-items-center justify-content-between "
                    key={index}
                  >
                    {/* Artwork Items */}
                    <div className="c1 d-flex align-items-center artwork-item">
                      <div className="art-img">
                        {item.data.image ? (
                          <img
                            src={item.data.image}
                            alt="artwork image"
                            className="img-100"
                          />
                        ) : (
                          <img
                            src={item.data.previewImg}
                            alt="artwork image"
                            className="img-100"
                          />
                        )}
                      </div>
                      <div className="user-name d-flex flex-column">
                        <label
                          onClick={() => navigateToArt(item)}
                          className="text-black text-capitalize hover-underline pointer"
                        >
                          {item?.data?.artName.length > 20 ? (
                            <>
                              {item?.data?.artName.substring(0, 20) ||
                                "Art Name"}
                              ...
                            </>
                          ) : (
                            <>{item?.data?.artName || "Art Name"}</>
                          )}
                        </label>
                        <p className="body-medium fw-bold v-center text-medium-grey hover-underline pointer show-artist-popup">
                          {item?.data.isSaleListed && (
                            <span
                              onClick={() => {
                                navigate(`/profile/${item?.listedData.seller}`);
                              }}
                            >
                              {getArtistNamebyAdress(item?.listedData.seller)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.listedData.seller
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(
                                  item?.listedData.seller
                                )}
                            </span>
                          )}
                          {item?.data.isOfferListed && (
                            <span
                              onClick={() => {
                                navigate(`/profile/${item?.newOwner}`);
                              }}
                            >
                              {getArtistNamebyAdress(item?.newOwner)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.newOwner
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(item?.newOwner)}
                            </span>
                          )}
                          {!item?.data.isListed && (
                            <span
                              onClick={() => {
                                navigate(
                                  `/profile/${item?.data.artistAddress}`
                                );
                              }}
                            >
                              {getArtistNamebyAdress(item?.data.artistAddress)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.data.artistAddress
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(
                                  item?.data.artistAddress
                                )}
                            </span>
                          )}
                          <img
                            src={verified}
                            alt="verified"
                            className="ms-2 img-18"
                          />
                        </p>
                      </div>
                    </div>

                    {/* List price */}
                    <div className={` c3 pricing d-flex align-items-center`}>
                      <label className=" text-black ">
                        {item?.data?.artistFee}
                      </label>
                      <p>%</p>
                    </div>

                    {/* Last Sale Price */}

                    <div
                      className="c2 pricing d-flex align-items-center item-hide"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!item.isListed && (
                        <div className="offer my-4 mt-5">
                          <p className="body-large text-bold-black  ">
                            Not Listed
                          </p>
                        </div>
                      )}
                      {props.isOwner && !item?.isListed ? (
                        <></>
                      ) : (
                        <center>
                          <div className="offer ">
                            {item?.isSaleListed && (
                              <p className="body-large text-bold-black  ">
                                Listed For Sale
                              </p>
                            )}
                            {item?.isOfferListed && (
                              <p className="body-large text-bold-black  ">
                                Listed For Offer {item?.isListed}
                              </p>
                            )}
                          </div>
                        </center>
                      )}
                    </div>

                    {/* Best Offer */}
                    <div
                      className={` c3 pricing d-flex align-items-center `}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="text-black">
                        {item?.isListed
                          ? item?.isSaleListed
                            ? item?.listedData?.price / 1000000000000000000
                            : item?.isOfferListed
                              ? item?.listedData?.minimumBid
                              : "--"
                          : "--"}
                      </label>
                    </div>

                    <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end d-none">
                      <img
                        // className="pe-4"
                        src={option}
                        alt="option"
                        onClick={() => showOption(index)}
                      />

                      {/* Collected Artwork more option popup */}
                      {show[index] ? (
                        <div className="collection-option" ref={dropdownRef}>
                          <div className="collection-item">
                            <img src={sale} alt="sale" />
                            <label className="text-black no-text-transform">
                              List for sale
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={transfer} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Transfer{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={copyLink} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Copy link{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={view} alt="sale" />
                            <label className="text-black no-text-transform">
                              View item{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={eidt} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Edit item
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {props?.isOwner && isNftApproved && (
                      <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown ">
                        <img
                          src={option}
                          alt=""
                          className="dropdown-toggle no-after"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul class="dropdown-menu no-border bg-transparent">
                          <Dropdown
                            listed={item?.isListed ? "listed" : "noListed"}
                            item={item}
                            tokenId={tokenId}
                            row="row"
                            transfer={collectionDetails?.Approved}
                          />
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* ant des table */}
        <div
          className={`nft-table hide-on-mobile  ${props.flag ? "w-75 active" : "w-100"
            }`}
        >
          <Table columns={columns} dataSource={nftFilteredDetails} />
        </div>
      </div>

      {/* List Price menu */}
      <Offcanvas
        show={priceShow}
        onHide={priceHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={priceHandleHide}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activePrice === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activePrice === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default LayoutRow;
