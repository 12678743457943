import React, { useEffect, useState } from "react";
import { getCollections } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Button from "./button";
import LayoutGrid from "./profile/layoutGrid";
import ArtistPopUp from "./shared/artistpopup";
import verified from "../assets/icon/verified-artist-small.svg";
import profile from "../assets/icon/profile-picture.svg";
import Profile from "../assets/icon/profile-picture.svg";
import filter from "../assets/icon/filter.svg";
import imgg from "../assets/images/collectionPlaceholder.png";
import sortby from "../assets/icon/sort-by.svg";
import tick from "../assets/icon/tick-large-black.svg";
import close from "../assets/icon/close.svg";
import Footer from "./footer";
import CardSkelton from "./shared/cardSkelton";
import { useAccount } from "wagmi";
import {
  getAllUsers,
  getCollectionStats,
  getUserData,
} from "../firebase/firebase";
import { useLocation } from "react-router-dom";
import { Popover } from "antd";
import { Offcanvas } from "react-bootstrap";
import AsideFilterExplore from "./explore/asideFilterExplore";
import AsideCollectionFilter from "./explore/asideCollectionFilter";

function AllCollections(props) {
  const [collections, setCollections] = useState([]);
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const [Flag, setFlag] = useState(false);
  const location = useLocation();
  const { address } = useAccount();
  const [userProfile, setProfile] = useState(Profile);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const getUserCollections = async () => {
    const usercollections = await getCollections();
    const filterCollection = usercollections?.filter(
      (item) => item?.data?.isWhiteList === true
    );
    console.log("ancdefg", filterCollection);
    setCollections(filterCollection);
    setLoading(false);
  };
  useEffect(() => {
    getUserCollections();
  }, []);

  const getprofile = async (address) => {
    console.log(address);
    if (address) {
      let res = await getUserData(address);
      console.log(res.image);
      if (res) {
        return res?.image;
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    console.log("collection============", collections);
  }, [collections]);

  const [userData, setUserData] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);

  const [collectionArray, setCollectionArray] = useState([]);

  useEffect(() => {
    collections.map(async (collection) => {
      const collectionData = await getCollectionStats(collection?.documentId);
      console.log("collectionDetails", collectionDetails);
      setCollectionArray((prev) => [...prev, collectionData]);
    });
    console.log("collectionDetail 111222333", collectionArray);
    setCollectionDetails(collectionArray);
  }, [collections]);

  useEffect(() => {
    console.log("collectionArray", collectionArray);
  }, [collectionArray]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getArtistNamebyAdress = (ArtistAddress) => {
    let artistName = "";
    if (address === ArtistAddress) {
      return "You";
    }

    console.log("users", userData);
    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistName = user?.userName;
      }
    });

    console.log("artist name", artistName, "address", ArtistAddress);
    return "@" + artistName;
  };

  const getImageByAddress = (ArtistAddress) => {
    let artistImage = Profile;

    console.log("users", userData);
    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistImage = user?.image;
      }
    });

    return artistImage;
  };

  // ************************** Filter
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideFilter, setShowSideFilter] = useState(false);

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // ======================collection sorting filter
  const collectionSortFilter = [
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollection, setActiveCollection] = useState("Newest");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };

  const handleCollectionTick = (tick) => {
    console.log(tick, "tick");
    setActiveCollection(tick);
    setCollectionMobileFilter(false);
  };
  const [sortedCollections, setSortedCollections] = useState([]);
  useEffect(() => {
    let sortedCollections = [...collections];

    if (activeCollection === "Newest") {
      sortedCollections.sort((a, b) => b.data.timestamp - a.data.timestamp);
    } else if (activeCollection === "Oldest") {
      sortedCollections.sort((a, b) => a.data.timestamp - b.data.timestamp);
    }
    setSortedCollections(sortedCollections);

    console.log(sortedCollections, "sortedCollections");
    // setCollections(sortedCollections);
  }, [activeCollection, collections]);

  return (
    <>
      <div className="collection-filter">
        <div className="left">
          <div
            className="show-filter cursor-pointer "
            onClick={showMobileSideFilter}
          >
            <span>
              <img src={filter} alt="filter" id="filter" />
            </span>
            <label
              htmlFor="filter"
              className="medium text-black cursor-pointer ms-1 hide-on-mobile"
              onClick={() => setShow(!show)}
            >
              {hide ? "Hide Filters" : "Show Filters"}
            </label>
          </div>
          <div className="t-items">
            {hide ? (
              <label className="medium ms-4 pointer">Clear All</label>
            ) : (
              <label className="medium ms-4">{collections.length} Items</label>
            )}
          </div>
        </div>

        <div className="right">
          {/* Sorting filter dropdown desktop*/}
          <div className="recent-collection filter dropdown hide-on-mobile ">
            <p
              className="body-medium dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="hide-on-mobile">
                {
                  collectionSortFilter.find(
                    (option) => activeCollection === option.value
                  )?.label
                }
              </span>
              <span>
                <img src={sortby} alt="sortby" />
              </span>
            </p>
            <ul className="dropdown-menu">
              {collectionSortFilter.map((option) => {
                return (
                  <li
                    className="dropdown-item"
                    key={option.value}
                    // onClick={() => handleIPriceTick(option.label)}
                    onClick={() => handleCollectionTick(option.value)}
                  >
                    <img
                      src={tick}
                      alt="tick"
                      className={`${
                        activeCollection === option.label
                          ? "active opacity-100"
                          : "opacity-0"
                      }`}
                    />
                    {option.label}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Sorting filter dropdown Mobile*/}
          <div className="recent-collection filter hide-on-desktop ">
            <p className="body-medium " onClick={showMobileSortFilter}>
              <span className="hide-on-mobile">
                {
                  collectionSortFilter.find(
                    (option) => option.value === option.value
                  )?.label
                }
              </span>
              <span>
                <img src={sortby} alt="sortby" />
              </span>
            </p>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className={`grid-display artworkGrid ? "w-75 active" : ""
              `}
        >
          <CardSkelton />
          <CardSkelton />
          <CardSkelton />
          <CardSkelton />
        </div>
      ) : (
        <>
          <div
            className={
              hide ? "d-flex align-item-center justify-content-between" : ""
            }
          >
            <div className={`my-filter ${hide ? "w-25" : "d-none"}`}>
              <AsideCollectionFilter />
            </div>

            <div
              className={`grid-display artworkGrid ${
                hide ? "w-75 active" : ""
              }`}
            >
              {sortedCollections.map((item, index) => {
                console.log("item", item);
                let artCount = "---";
                let collectorCount = "---";
                collectionArray.map((collection) => {
                  if (collection?.collectionId === item?.documentId) {
                    artCount = collection?.artworkCount;
                    collectorCount = collection?.owners?.length || 0;
                  }
                });
                return (
                  <div className="collection-grid-card" key={index}>
                    <div className="card-head  ">
                      <div className="user-img">
                        {item && (
                          <img
                            src={
                              getImageByAddress(item?.data.address) || Profile
                            }
                            className="img-100 rounded-circle"
                          />
                        )}
                        {!item && (
                          <img
                            src={Profile}
                            className="img-100 rounded-circle"
                          />
                        )}
                      </div>

                      <div className="user-name">
                        <p
                          onClick={() =>
                            navigate(`/explore-collections/${item.documentId}/`)
                          }
                          className="body-large hover-underline pointer"
                        >
                          {item.data.name.length > 18
                            ? item.data.name.substring(0, 18)
                            : item.data.name}
                          {item.data.name.length > 18 && "..."}
                        </p>

                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              {address && (
                                <ArtistPopUp
                                  userProfile={
                                    getImageByAddress(item?.data.address) ||
                                    Profile
                                  }
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item.data.address
                                  )}
                                />
                              )}
                            </>
                          }
                        >
                          <p
                            onClick={() =>
                              navigate(`/profile/${item.data.address}/`)
                            }
                            className="fw-bold text-medium-grey hoverBlack show-artist-popup "
                          >
                            {getArtistNamebyAdress(item.data.address).length >
                            18
                              ? getArtistNamebyAdress(
                                  item.data.address
                                ).substring(0, 18)
                              : getArtistNamebyAdress(item.data.address)}
                            {getArtistNamebyAdress(item.data.address).length >
                              18 && "..."}
                            <img
                              src={verified}
                              alt="verified"
                              className="img-18 ms-1"
                            />
                          </p>
                        </Popover>
                      </div>
                    </div>

                    <div
                      className="card-body"
                      onClick={() =>
                        navigate(`/explore-collections/${item.documentId}/`)
                      }
                    >
                      <div className="art-img">
                        {!item.data?.image ? (
                          <img src={imgg} className="img-100 artwork-hover " />
                        ) : (
                          <img
                            src={item?.data?.image}
                            alt="art"
                            className="img-100 artwork-hover "
                          />
                        )}
                      </div>
                    </div>

                    <div className="card-footer">
                      <div className="owner" style={{ maxWidth: "200px" }}>
                        <p className="body-medium text-medium-grey ">
                          Collection Size
                        </p>
                        <p className="body-medium text-black fw-semibold ">
                          {artCount} Artworks
                        </p>
                      </div>

                      <div className="offer">
                        <p className="body-medium text-medium-grey ">
                          Collectors
                        </p>
                        <label className="medium text-black ">
                          {collectorCount} Collectors
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {/* <div className="mt-5 pt-5">
        <Footer />
      </div> */}

      {/* Collection Sorting Filter mobile menu */}
      <Offcanvas
        show={collectionMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {collectionSortFilter.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${
                  activeCollection === option.value ? "fw-bold" : "fw-normal"
                }`}
                onClick={() => handleCollectionTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${
                    activeCollection === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                  }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default AllCollections;
