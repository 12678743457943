import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import { Accordion } from "react-bootstrap";
import Button from "../button";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import search from "../../assets/icon/search-grey.svg";
import down from "../../assets/icon/chevron-down-small.svg";
import Input from "../inputs";
import { useLocation } from "react-router-dom";
import CustomCheckBox from "../shared/customTags";
const AsideFilter = ({ onSelectedFilterChange }) => {
  const [selectStatus, setSelectStatus] = useState({
    buyNow: false,
    onOffer: false,
    new: false,
  });
  const [selectCurrency, setSelectCurrency] = useState({
    allChains: false,
    flr: false,
    sgb: false,
  });
  const [selectCategories, setSelectCategories] = useState({
    "3D": false,
    Animation: false,
    Phygital: false,
    Geometric: false,
    Fantasy: false,
    Surreal: false,
    Illustration: false,
    AI: false,
    Photography: false,
    Portrait: false,
    Landscape: false,
    Audio: false,
    Nature: false,
    Drawing: false,
    Painting: false,
  });
  const [selectPrice, setSelectPrice] = useState({
    min: "",
    max: "",
  });

  // State for Status filter
  const handleStatusFilter = (tag) => {
    // setSelectStatus(!selectStatus);
    setSelectStatus((prevSelectedTags) => ({
      ...prevSelectedTags,
      [tag]: !prevSelectedTags[tag],
    }));
  };

  // handle currency filter

  const handleCurrencyFilter = (tag) => {
    setSelectCurrency((prevSelectedTags) => ({
      ...prevSelectedTags,
      allChains: false, // Deselect all currencies
      flr: false,
      sgb: false,
      [tag]: true, // Select the clicked currency
    }));
  };

  // State for Price filter
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const handleCategoriesFilter = (tag) => {
    setSelectCategories((prevSelectedCategories) => {
      const updatedCategories = { ...prevSelectedCategories };

      if (updatedCategories[tag]) {
        // Category already selected, remove it
        delete updatedCategories[tag];
      } else {
        // Category not selected, add it
        updatedCategories[tag] = true;
      }

      return updatedCategories;
    });
  };

  useEffect(() => {
    console.log("minPrice", minPrice);
    console.log("maxPrice", maxPrice);
    handlePriceFilter();
  }, [minPrice, maxPrice]);

  const handlePriceFilter = () => {
    setSelectPrice({
      min: minPrice,
      max: maxPrice,
    });
  };

  const clearAllFilter = () => {
    setSelectStatus({
      buyNow: false,
      onOffer: false,
      new: false,
    });
    setSelectCurrency({
      allChains: false,
      flr: false,
      sgb: false,
    });
    setSelectCategories({
      cat_3D: false,
      cat_animation: false,
      cat_phygital: false,
      cat_geometric: false,
      cat_fantasy: false,
    });
    setSelectPrice({
      min: "",
      max: "",
    });
  };
  const SearchFilter = () => {
    onSelectedFilterChange({
      selectStatus,
      selectCurrency,
      selectCategories,
      selectPrice,
    });
    console.log(selectStatus);
    console.log(selectCurrency);
    console.log(selectCategories);
    console.log(selectPrice);
  };

  const location = useLocation();

  const pathsToHideFilters = location.pathname === "/explore-collections";

  return (
    <>
      <div className="collection-multi-filter">
        <div className="multi-filter-options">
          <div
            style={{
              display: pathsToHideFilters ? "none" : "",
            }}
          >
            <Accordion>
              {/* status filter */}
              <div>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                      Status
                    </label>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="check-filter collection-status-filter">
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectStatus.buyNow ? "selected" : ""
                        }`}
                        onClick={() => handleStatusFilter("buyNow")}
                      >
                        <img
                          src={selectStatus.buyNow ? checked : unCheck}
                          alt="checkbox"
                        />
                        Buy now
                      </p>
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectStatus.onOffer ? "selected" : ""
                        }`}
                        onClick={() => handleStatusFilter("onOffer")}
                      >
                        <img
                          src={selectStatus.onOffer ? checked : unCheck}
                          alt="checkbox"
                        />
                        On offer
                      </p>
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectStatus.new ? "selected" : ""
                        }`}
                        onClick={() => handleStatusFilter("new")}
                      >
                        <img
                          src={selectStatus.new ? checked : unCheck}
                          alt="checkbox"
                        />
                        New
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* owner filter */}

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                      Categories
                    </label>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="pb-3 mt-22">
                      <CustomCheckBox
                        values={[
                          "3d",
                          "Animation",
                          "Phygital",
                          "3d",
                          "Geometric",
                          "Fantasy",
                          "Surreal",
                          "Illustration",
                          "AI",
                          "Photography",
                          "Portrait",
                          "Landscape",
                          "Audio",
                          "Nature",
                          "Drawing",
                          "Painting",
                        ]}
                        selectedValues={selectCategories}
                        onChange={(e) => handleCategoriesFilter(e.target.value)}
                        disabled={false}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Pricing filter */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                      Price
                    </label>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="curreny-filter v-center">
                      <div className="input-val v-center">
                        <input
                          type="number"
                          placeholder="Min"
                          value={minPrice}
                          onChange={(e) => {
                            setMinPrice(e.target.value);
                          }}
                        />
                        <p className="body-large m-2">to</p>
                        <input
                          type="number"
                          placeholder="Max"
                          value={maxPrice}
                          onChange={(e) => {
                            setMaxPrice(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* curreny filter */}
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                      Currency
                    </label>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="check-filter collection-status-filter">
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectCurrency.allChains ? "active" : ""
                        }`}
                        onClick={() => handleCurrencyFilter("allChains")}
                      >
                        <img
                          src={selectCurrency.allChains ? checked : unCheck}
                          alt="checkbox"
                        />
                        All chains
                      </p>
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectCurrency.flr ? "active" : ""
                        }`}
                        onClick={() => handleCurrencyFilter("flr")}
                      >
                        <img
                          src={selectCurrency.flr ? checked : unCheck}
                          alt="checkbox"
                        />
                        FLR
                      </p>
                      <p
                        className={`body-large h-64 fw-normal v-center cursor-pointer ${
                          selectCurrency.sgb ? "active" : ""
                        }`}
                        onClick={() => handleCurrencyFilter("sgb")}
                      >
                        <img
                          src={selectCurrency.sgb ? checked : unCheck}
                          alt="checkbox"
                        />
                        SGB
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </Accordion>
          </div>

          <div className="multi-filter-footer">
            <Button
              text="Clear all"
              className="btn-prime btn-secondary"
              width="49%"
              height="47px"
              onClick={clearAllFilter}
            />
            <Button
              text="Done"
              className="btn-prime btn-primary"
              width="49%"
              height="47px"
              onClick={SearchFilter}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AsideFilter;
