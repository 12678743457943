import React, { useEffect } from "react";
import "../style/main.scss";
import logo from "../assets/images/logo.png";
import discord from "../assets/icon/discord-white.svg";
import twitterIcon from "../assets/icon/twitter.svg";
import star from "../assets/images/star35.png";
import { Link } from "react-router-dom";

const FooterV2 = ({}) => {
  return (
    <div>
      <section className="footer footerV2">
        <div className="footer-content">
          <div className="left">
            <div className="site-logo">
              <div>
                <img src={star} alt="logo" className="invert1" />
                <img src={logo} alt="logo" className="invert1" />
              </div>
            </div>
            <div className="d-flex gap-4 mt-5">
              <a href="https://discord.gg/zAQ9RAh7db" target="_blank">
                <img src={discord} alt="discord" />
              </a>

              <a
                href="https://twitter.com/thefatcatsNFT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitterIcon}
                  alt="twitter"
                  className=""
                  style={{ filter: "invert(1)" }}
                />
              </a>
            </div>
          </div>

          <div className="center f-links d-flex">
            <label className="small">Community</label>
            <a href="https://discord.gg/zAQ9RAh7db" target="_blank">
              Discord
            </a>
            <a href="https://twitter.com/thefatcatsNFT" target="_blank">
              Twitter
            </a>
          </div>

          <div className="right f-links">
            <label className="small">art</label>
            <a
              href="https://docs.google.com/forms/u/0/d/1qLYHb4x9zU26HdjxH415GTvrDvpP3HXrEyerQm4GlaI/viewform?edit_requested=true"
              target="_blank"
            >
              Submit artist profile
            </a>
            <a href="/explore">Explore art</a>
          </div>

          <div className="right2 f-links">
            <label className="small">legal</label>
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Bug Bounty Program</a>
            <Link to="/report-issue">Report content</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterV2;
