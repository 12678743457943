

import React, { createContext, useEffect, useState } from 'react';
import { getCollections } from '../../firebase/firebase';
import Web3 from 'web3';
import mintABI from "../../abis/SafeMint/abi.json";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";

export const NFTContext = createContext();


export const NFTProvider = ({ children }) => {


  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  // context states
  const [address, setAddress] = useState('');
  const [nftList, setNftList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [artModal, setArtModal] = useState(false);
  const [createdArtworks, setCreatedArtworks] = useState(0);
  const [reloadAfterTransfer, setReloadAfterTransfer] = useState(false);
  const [search, setSearch] = useState(false);
  const[reloadProfile, setReloadProfile]=useState(false);

  const reload = ()=>{
    setReloadProfile(!reloadProfile);

  }


  const updateAddress = (value) => {
    console.log(value);
    setAddress(value);
  }


  const change = (val) => {
    setIsChange(val);
  }

  const updateNftList = (newNftList) => {
    setNftList(newNftList);
  };

  const updateModal = () => {
    setShowModal(!showModal);
  }

  const updateCreated = (value) => {
    setCreatedArtworks(value);
  }
  const udpateArtModal = () => {
    setArtModal(!artModal);
  }

  const updateTransfer = (value) => {
    setReloadAfterTransfer(value);
  }

  const updateSearch = (value) => {
    setSearch(!search);
  }









  // get all nfts

  const [collections, setCollections] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [nft, setNft] = useState(null);




  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      // Filter user collections where userAddress doesn't match with the provided address
      // const filteredCollections = usercollections.filter(
      //   (collection) => collection.address !== address
      // );
      console.log(usercollections, "userCollections");

      setCollections(usercollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };


  const getAllListNFTData = async (selectedNetwork, web3) => {
    // const accounts = await web3.eth.getAccounts();
    if (collections && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // alert("in listed");
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "listed data in explore art");
      setListedNfts(data);
      return data;

    } else {
      setListedNfts([]);
    }
  };

  const [owner, setOwner] = useState(null);


  const Backend_url = process.env.REACT_APP_BACKEND_URL;

  const getNftMetadata = async (tokens_uri, network) => {
    console.log(tokens_uri, "token uri");
    // Reset or initialize state variables
    setNfts([]);
    const nftMetadata = [];

    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri; // Extract URI from the current token
        console.log(uri, "uri");
        try {
          const response = await fetch(`${Backend_url}/getNFTMetadata`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uri: uri,
            }),
          });
          console.log(response, "response of url ");

          const json = await response.json();
          console.log(json, "json data");

          // Check if the data object is already in nftMetadata array
          const existingIndex = nftMetadata.findIndex((item) => item.uri === uri);
          if (existingIndex === -1 && json) {
            // Push the data object into the nftMetadata array along with the original token
            nftMetadata.push({
              data: token,
              metadata: {
                uri: uri,
                data: json.data.data,
              },
            });
          }
        } catch (error) {
          console.error(`Failed to fetch metadata from ${uri}:`, error);
        }
      })
    );

  

    // Update the state with the metadata and original tokens

    updateNftList(nftMetadata);


  };

  // step no 2
  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork, id) => {
    console.log(id, "id");

    let web;
    if (selectedNetwork == "Coston2") {
      web = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    } else {
      web = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    }
    let nftListDetails = [];
    let nftArray = [];

    if (web) {
      const contract = new web.eth.Contract(mintABI, contractAddress);
      const nftData = await contract.methods.getTokenIdsByCollection(id).call();
      console.log(nftData, "nftData");
      // if (nftData.length > 0) {
      //   nftArray = [...nftArray];
      // }
      console.log(nftArray, "nft Array");

      let listedNfts = await getAllListNFTData(selectedNetwork, web);
      console.log(listedNfts, "listed nfts");
      nftData?.map((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });
    }
    console.log(nftListDetails, "nft List detais");
    return nftListDetails;
  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {
    let contractDataArray = [];

    for (const item of collections) {
      console.log(item, "item");
      let data = await fetchDataForContract(
        item?.data.contractAddress,
        item?.data.selectedNetwork,
        item?.documentId
      );
      if (data?.length > 0) {
        contractDataArray.push(data);
      }
    }

    // Flatten the contractDataArray into a single array
    const flattenedTokens = contractDataArray.flat();
    console.log(flattenedTokens, "flat tokens");

    // Pass the flattened array to getNftMetadata
    getNftMetadata(flattenedTokens);

  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    }
  }, [collections]);


  useEffect(() => {
    getUserCollections();
  }, [address]);































  useEffect(() => {

    console.log(nftList, "nft List");
    console.log(isChange, "is change");
    console.log(showModal, "show modal");
    console.log(showModal, "show modal");
    console.log(artModal, "show modal");
    console.log(reloadAfterTransfer, "reloadAfterTransfer")
    console.log(createdArtworks, "created");
    console.log(search, "updateSearch");
  }, [nftList, isChange, showModal, artModal, reloadAfterTransfer, createdArtworks, search])
























  return (
    <NFTContext.Provider value={{
      nftList, updateNftList, change, isChange, showModal, updateModal, createdArtworks,
      updateCreated, artModal, udpateArtModal, reloadAfterTransfer, updateTransfer, search, updateSearch, updateAddress,
      reloadProfile, reload
    }}>
      {children}
    </NFTContext.Provider>
  );
};
