import React, { useState, useRef, useEffect } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import option from "../../assets/icon/more-vertical.svg";
import sale from "../../assets/icon/list-for-sale.svg";
import transfer from "../../assets/icon/transfer.svg";
import copyLink from "../../assets/icon/link.svg";
import view from "../../assets/icon/eye.svg";
import eidt from "../../assets/icon/edit.svg";
import dropdown from "../../assets/icon/chevron-down-extra-small.svg";
import { Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import AsideFilterExplore from "../explore/asideFilterExplore";
import Dropdown from "../shared/dropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useAccount } from "wagmi";
import { getAllUsers } from "../../firebase/firebase";
import { Popover, Table } from "antd";

export default function UserCollectedRow(props) {
  const { address } = useAccount();
  const [show, setShow] = useState({});
  const [activePrice, setActivePrice] = useState("listPrice");
  const [priceShow, setPriceShow] = useState(false);
  const dropdownRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [collectedArts, setCollectedArts] = useState([]);

  // contract address from marked place

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [listedNfts, setListedNfts] = useState([]);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(null);
  const [tokenId, setTokenId] = useState();
  const [nftData, setNftData] = useState([]);

  const [web3, setWeb3] = useState(new Web3(window.ethereum));
  useEffect(() => {
    console.log(props?.loading, "loading........");
  }, [props?.loading]);
  // get best offer price from blockchain

  const fetchDataAndUpdateDetails = async () => {
    console.log(props?.listedNft);
    let bidding;
    if (props?.listedNft) {
      let data = props?.listedNft.flat();
      console.log(data, "data");
      try {
        const web3 = new Web3(window.ethereum);
        const updatedDetails = await Promise.all(
          data
            .filter((item) => item?.data.isOfferListed)
            .map(async (item) => {
              console.log(item, "item filter");
              const MarketplaceAddress =
                item?.metadata.data.selectedBlockchain === "Coston"
                  ? Marketplace_coston_contractAddress
                  : Marketplace_coston2_contractAddress;
              console.log(item?.metadata.data.selectedBlockchain);
              const contract = new web3.eth.Contract(
                marketplaceContractABI,
                MarketplaceAddress
              );
              try {
                console.log(item?.data.listCount, "list count");
                const biddingHistoryCoston = await contract.methods
                  .getBiddingHistory(item?.data.listCount)
                  .call();
                console.log(biddingHistoryCoston, "biddinghistoty");

                let maxPrice = null;
                if (biddingHistoryCoston && biddingHistoryCoston.length > 0) {
                  maxPrice = calculateMaxPrice(biddingHistoryCoston);
                  console.log(maxPrice, "maxPrice");
                }

                return { ...item, maxPrice: maxPrice ? maxPrice / 1e18 : null }; // Converting to ether or setting to null
              } catch (error) {
                console.error("Error fetching bidding history:", error);
                return { ...item, maxPrice: null }; // Handle error fetching bidding history by returning the item with null maxPrice
              }
            })
        );

        setNftData((prevDetails) => {
          const unchangedItems = prevDetails.filter(
            (prevItem) => !prevItem.data.isOfferListed
          );
          console.log(unchangedItems, "unchanged items");
          console.log(updatedDetails, "updated Details");
          const mergedDetails = [
            ...unchangedItems,
            ...updatedDetails.filter(Boolean),
          ];
          console.log(mergedDetails, "merged details");
          return mergedDetails;
        });
      } catch (error) {
        console.error("Error fetching data and updating details:", error);
        // Handle error fetching data and updating details
      }
    }
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (!isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };
  useEffect(() => {
    if (props?.listedNft) {
      setCollectedArts(props?.listedNft);
      const fetchdata = async () => {
        await fetchDataAndUpdateDetails();
      };
      fetchdata();
    }
  }, [props?.listedNft]);

  // setting rpc url

  useEffect(() => {
    if (collectionDetails?.selectedNetwork === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    } else {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [collectionDetails]);

  // toolip
  const tooltip = <Tooltip id="tooltip">List price: 4900929 FLR</Tooltip>;
  const tooltipLSP = (
    <Tooltip id="tooltipLSP">Last sale price: 576 FLR</Tooltip>
  );
  const tooltipBO = <Tooltip id="tooltipBO">Best offer: 9760929 FLR</Tooltip>;

  // price Filter handle
  const priceHandleShow = () => setPriceShow(true);
  const priceHandleHide = () => setPriceShow(false);

  const showOption = (index) => {
    setShow({ [index]: true });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  // ----------- Handle option menu end

  const handleIPriceTick = (tick) => {
    setActivePrice(tick);
  };

  const priceOptions = [
    { value: "listPrice", label: "List price" },
    { value: "lastPrice", label: "Last sale price" },
    { value: "bestOffer", label: "Best offer" },
  ];

  // navigate to single artwork

  const navigateToSingleArtwork = (item) => {
    try {
      let collectionId = item?.metadata?.data.selectedCollectionId;
      let tokenId = item?.data.tokenId;
      let network = item?.metadata?.data?.selectedBlockchain;

      navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  // retruns token id of nft

  const getUserNftsFromContract = async () => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    resolve(a.tokenId);
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // get token id of nft that are not listed

  const getTokenIdforNoList = async () => {
    try {
      let TokenId = await getUserNftsFromContract();
      let tokenId = TokenId;
      return tokenId;
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    const getToken = async () => {
      let res = await getTokenIdforNoList();
      console.log(res);
      setTokenId(res);
    };

    getToken();
  }, [collectionDetails]);
  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    console.log(props?.id);
    if (props?.id) {
      userData?.forEach((user) => {
        if (user?.id === props?.id) {
          artistName = user?.userName;
        }
      });
    } else return "You";

    return "@" + artistName;
  };
  const navigateToArt = (item) => {
    console.log(item);
    navigate(
      `/artwork/${item?.metadata?.data?.selectedBlockchain}/${item?.metadata?.data?.selectedCollectionId}/${item?.data?.listedData?.tokenId}`
    );
  };
  const navigateToProfile = () => {
    if (props?.id) {
      navigate(`/profile/${props?.id}`);
    } else {
      navigate(`/profile/${address}`);
    }
  };

  useEffect(() => {
    console.log(nftFilteredDetails, "nft filtered details");
  }, [[nftFilteredDetails]]);
  const columns = [
    {
      title: `${nftData?.length} ITEMS`,
      key: "items",
      width: "450px",
      render: (_, record) => (
        <div className="d-flex align-items-center">
          <div onClick={() => navigateToArt(record)} className="art-img">
            {record?.metadata?.data.image ? (
              <img
                src={record.metadata.data.image}
                alt="artwork image"
                className="img-100"
              />
            ) : (
              <img
                src={record.metadata?.data.previewImg}
                alt="artwork image"
                className="img-100"
              />
            )}
          </div>
          <div className="user-name d-flex flex-column">
            <label
              onClick={() => navigateToArt(record)}
              className="text-black text-capitalize hover-underline pointer"
            >
              {record?.metadata?.data?.artName.length > 20 ? (
                <>
                  {record?.metadata?.data?.artName.substring(0, 20) ||
                    "Art Name"}
                  ...
                </>
              ) : (
                <>{record?.metadata?.data?.artName || "Art Name"}</>
              )}
            </label>
            <p className="body-medium fw-bold v-center text-medium-grey hover-underline pointer show-artist-popup">
              {record?.data.isSaleListed ? (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.data?.listedData.seller}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.data?.listedData.seller)
                    .length > 18
                    ? getArtistNamebyAdress(
                      record?.data?.listedData.seller
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.data?.listedData.seller)}
                </span>
              ) : record?.data.isOfferListed ? (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.data?.newOwner}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.data?.newOwner)
                    .length > 18
                    ? getArtistNamebyAdress(
                      record?.data?.newOwner
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.data?.newOwner)}
                </span>
              ) : (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.data.artistAddress}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.data.artistAddress).length > 18
                    ? getArtistNamebyAdress(
                      record?.data.artistAddress
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.data.artistAddress)}
                </span>
              )}
              <img src={verified} alt="verified" className="ms-2 img-18" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "LIST PRICE",
      key: "LISTPRICE",
      width: "200px",
      render: (_, record) => (
        <div>
          <label className="text-black">
            {record?.data.isListed
              ? record?.data.isSaleListed
                ? record?.data.listedData?.price / 1000000000000000000
                : "--"
              : "--"}
          </label>
        </div>
      ),
    },
    {
      title: "Last Sale Price",
      key: "Last Sale Price",
      render: (_, record) => (
        <>
          <label className="medium text-black">
            {record?.metadata.data.lastPrice / 1000000000000000000 || "--"}
          </label>
        </>
      ),
    },
    {
      title: "Best Offer",
      key: "Best Offer",
      render: (_, record) => (
        <div>
          <label className="text-black">{record?.maxPrice || "--"}</label>
        </div>
      ),
    },
    {
      title: "",
      key: "options",
      render: (_, record, index) => (
        <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end ">
          <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown">
            <img
              src={option}
              alt=""
              className="dropdown-toggle no-after"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu no-border bg-transparent">
              <Dropdown
                listed={record?.data.isSaleListed ? "listed" : "noListed"}
                item={record}
                tokenId={record.data.tokenId}
                row="row"
                transfer={collectionDetails?.Approved}
              />
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex">
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={props.onSelectedFilterChange}
          />{" "}
        </div>

        <div className={`row-layout   ${props.flag ? "w-75 active" : "w-100"}`}>
          <div className="row-layout-head justify-content-between hide-on-desktop">
            <label className="small head-1 c1">{nftData?.length} Items</label>
            {/* <label className="small head-1 c2 hide-on-mobile">Artist Fee</label> */}
            <label
              className="small head-1 c4 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              List Type
            </label>
            <label
              className="small head-1 c3 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              List price
            </label>
            {props?.isOwner && (
              <label className="small head-1 c5 hide-on-mobile pe-4"></label>
            )}
            <label
              className="small text-black d-flex align-items-center justify-content-end hide-on-desktop w-50 "
              onClick={priceHandleShow}
            >
              {
                priceOptions.find((option) => option.value === activePrice)
                  ?.label
              }
              <img src={dropdown} alt="dropdown" />
            </label>
          </div>

          {/* row layout content */}

          <div className="row-layout-body flex-column hide-on-desktop">
            {props?.listedNft == undefined ? (
              <></>
            ) : (
              nftData &&
              nftData?.map((item, index) => {
                console.log("layout row item", item);
                return (
                  <div
                    className="row-content d-flex align-items-center justify-content-between "
                    key={index}
                  >
                    {/* Artwork Items */}
                    <div className="c1 d-flex align-items-center artwork-item">
                      <div
                        onClick={() => navigateToSingleArtwork(item)}
                        className="art-img"
                      >
                        {item.metadata.data.image ? (
                          <img
                            src={item.metadata.data.image}
                            alt="artwork image"
                            className="img-100"
                          />
                        ) : (
                          <img
                            src={item.metadata.data.previewImg}
                            alt="artwork image"
                            className="img-100"
                          />
                        )}
                      </div>

                      <div className="user-name d-flex flex-column">
                        {/* Artwork Name */}
                        {/* <label
                          onClick={() => navigateToSingleArtwork(item)}
                          className="text-black  text-capitalize hover-underline pointer"
                        >
                          {item?.metadata.data?.artName
                            ? item?.metadata.data?.artName
                            : "Artwork"}
                        </label> */}

                        <label
                          onClick={() => navigateToSingleArtwork(item)}
                          className="text-black  text-capitalize hover-underline pointer"
                        >
                          {item?.metadata?.data?.artName.length > 22 ? (
                            <Popover
                              title={item?.metadata?.data?.artName}
                              overlayClassName="table-popover"
                            >
                              {item?.metadata?.data?.artName.substring(0, 22) +
                                "..."}
                            </Popover>
                          ) : (
                            item?.metadata?.data?.artName || "Artwork"
                          )}
                        </label>

                        <p className="body-medium fw-bold v-center text-medium-grey  hover-underline pointer show-artist-popup">
                          <span onClick={navigateToProfile}>
                            {getArtistNamebyAdress(address)}
                          </span>
                          <img
                            src={verified}
                            alt="verified"
                            className="ms-2 img-18"
                          />
                        </p>
                      </div>
                    </div>

                    {/* List price */}
                    {/* <div className={` c3 pricing d-flex align-items-center`}>
                      <label className=" text-black ">
                        {item?.metadata.data?.artistFee}
                      </label>
                      <p>%</p>
                    </div> */}

                    {/* Last Sale Price */}

                    <div
                      className="c2 pricing d-flex align-items-center item-hide"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!item.data.isListed && (
                        <div className="offer my-4 mt-5">
                          <p className="body-medium fw-semibold ">Not Listed</p>
                        </div>
                      )}
                      {props.isOwner && !item?.data.isListed ? (
                        <></>
                      ) : (
                        <center>
                          <div className="offer ">
                            {item?.data.isSaleListed && (
                              <p className="body-medium fw-semibold ">
                                Listed For Sale
                              </p>
                            )}
                            {item?.data.isOfferListed && (
                              <p className="body-medium fw-semibold ">
                                Listed For Offer
                              </p>
                            )}
                          </div>
                        </center>
                      )}
                    </div>

                    {/* Best Offer */}
                    <div
                      className={` c3 pricing d-flex align-items-center `}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="text-black">
                        {item?.data.isListed
                          ? item?.data.isSaleListed
                            ? item?.data.listedData?.price / 1000000000000000000
                            : item?.data.isOfferListed
                              ? item?.data.listedData?.minimumBid
                              : "--"
                          : "--"}
                      </label>
                    </div>

                    <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end d-none">
                      <img
                        // className="pe-4"
                        src={option}
                        alt="option"
                        onClick={() => showOption(index)}
                      />

                      {/* Collected Artwork more option popup */}
                      {show[index] ? (
                        <div className="collection-option" ref={dropdownRef}>
                          <div className="collection-item">
                            <img src={sale} alt="sale" />
                            <label className="text-black no-text-transform">
                              List for sale
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={transfer} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Transfer{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={copyLink} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Copy link{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={view} alt="sale" />
                            <label className="text-black no-text-transform">
                              View item{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={eidt} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Edit item
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {props?.isOwner && (
                      <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown ">
                        <img
                          src={option}
                          alt=""
                          className="dropdown-toggle no-after"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul class="dropdown-menu no-border bg-transparent">
                          <Dropdown
                            listed={item?.isListed ? "listed" : "noListed"}
                            item={item}
                            tokenId={tokenId}
                            row="row"
                            transfer={collectionDetails?.Approved}
                          />
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>

          <div className="nft-table mt-4 hide-on-mobile">
            <Table columns={columns} dataSource={nftData} />
          </div>
        </div>
      </div>

      {/* List Price menu */}
      <Offcanvas
        show={priceShow}
        onHide={priceHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={priceHandleHide}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activePrice === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activePrice === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}
