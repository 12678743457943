import { Skeleton } from "antd";
import React from "react";

const AdminLoader = () => {
  return (
    <div>
      <div className="admin-dashbooard d-flex">
        <div className="left-content">
          <div className="admin-nav">
            <h6 className="text-capitalize admin-head v-center">
              <Skeleton.Avatar
                active
                round
                size="large"
                shape="button"
                style={{
                  width: "180px",
                  height: "32px",
                  borderRadius: "4px",
                }}
              />
            </h6>

            <div className="admin-menu-links">
              {/* Desktop aside bar */}
              <ul className=" menu-link for-desktop">
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "280px",
                    height: "64px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "280px",
                    height: "64px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "280px",
                    height: "64px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "280px",
                    height: "64px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "280px",
                    height: "64px",
                    borderRadius: "4px",
                  }}
                />
              </ul>

              {/* mobile */}

              <ul className=" menu-link flex-column for-mobile ps-3">
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />

                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3 mb-3"
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
        <div className="right-content w-100 hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "700px",
              height: "48px",
              borderRadius: "4px",
            }}
          />

          <div className="mt-5 pt-2">
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "300px",
                height: "32px",
                borderRadius: "4px",
              }}
            />
          </div>
          <div className="mt-5 d-flex flex-wrap gap-3">
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />{" "}
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />{" "}
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "160px",
                height: "160px",
                borderRadius: "4px",
              }}
            />
          </div>
          <div className="mt-5">
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "300px",
                height: "32px",
                borderRadius: "4px",
              }}
            />
          </div>

          <div className="mt-3">
            <Skeleton.Avatar
              active
              round
              size="large"
              shape="button"
              style={{
                width: "700px",
                height: "700px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoader;
