import React, { useEffect, useRef, useState, useContext } from "react";
import "../style/main.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import WallatModal from "./modal";
import logo from "../assets/icon/logo.svg";
import unread from "../assets/icon/circleRed.svg";

import searchIcon from "../assets/icon/search.svg";
import hamburger from "../assets/icon/hamburger-menu.svg";
import loader from "../assets/icon/loader-medium.svg";
import arrowRightSmall from "../assets/icon/arrow-right-small.svg";
import copy from "../assets/icon/copy.svg";
import sadface from "../assets/icon/sad-face.svg";
import close from "../assets/icon/close-small.svg";
import profileImg from "../assets/images/profile-img.png";
import placeholder from "../assets/icon/profile-picture.svg";
import resultcard from "../assets/images/artwork-example-3.png";
import resultcard2 from "../assets/images/artwork-example-5.png";
import art1 from "../assets/images/artwork-example-3.png";
import art2 from "../assets/images/artwork-example-3.png";
import art3 from "../assets/images/artwork-example-5.png";
import art4 from "../assets/images/artwork-example-6.png";
import imgg from "../assets/images/collectionPlaceholder.png";

import people1 from "../assets/images/profile-1.svg";

// import people1 from "../assets/images/face-6.png";
import people2 from "../assets/images/face-7.png";
import people3 from "../assets/images/face-8.png";
import people4 from "../assets/images/face-9.png";
import collection1 from "../assets/images/artwork-preview-1.png";
import collection2 from "../assets/images/artwork-preview-2.png";
import collection3 from "../assets/images/artwork-preview-3.png";
import collection4 from "../assets/images/artwork-preview-4.png";
import FLR from "../assets/icon/FLR.svg";
import songbird from "../assets/icon/SGB.svg";
import circleRed from "../assets/icon/circleRed.svg";
import backarrow from "../assets/icon/arrow-left-white.svg";
import star from "../assets/icon/spiked-circle/black/35px.svg";
import Button from "./button";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import marketplaceContractABI from "../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import axios from "axios";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useBalance, useNetwork } from "wagmi";
import _, { filter } from "lodash";
import wrappedAbi from "../abis/wrappedAbi.json";

import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from "@rainbow-me/rainbowkit";
import {
  checkBalance,
  checkWSgbBalance,
} from "../contractInteraction/interaction";
import CustomCheckBox from "./shared/customTags";
import { useConnect } from "wagmi";
import { useDisconnect } from "wagmi";
import { RefreshContext } from "./notificationContext";
import { useSwitchNetwork } from "wagmi";
import {
  getUserData,
  getAllArts,
  getCollections,
  getAllUsers,
  getNotifications,
} from "../firebase/firebase";
import { NFTContext } from "./context/appContext";

// import { WalletButton } from '@rainbow-me/rainbowkit';

export const CustomConnectButton = ({ connectWalletPressed, scrolled }) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <Button
            text="Sign In"
            className="btn-prime btn-primary btn-primaryA nav-btn hide-on-768"
          // onClick={handleBidModal}
          />
        );
      }}
    </ConnectButton.Custom>
  );
};

const Navbar = (props) => {
  const [userData, setUserData] = useState("");
  const [emailRegistered, setEmailRegistered] = useState("");
  const [reload2, setReload2] = useState(localStorage.getItem("reload2"));
  const [ref, setRef] = useState(localStorage.getItem("ref"));
  const [whitelisted, setWhitelisted] = useState(false);
  const [signInCalled, setSignInCalled] = useState(false);
  const [listedNfts, setListedNfts] = useState([]);
  const { nftList, updateSearch, updateAddress } = useContext(NFTContext);

  const signInButtonRef = useRef(null);
  useEffect(() => {
    console.log(nftList, "nft List");
    setListedNfts(nftList);
  }, [nftList]);

  useEffect(() => {
    if (props?.isSignInModalOpen && signInButtonRef.current) {
      signInButtonRef.current.click();
      props?.setIsSignInModalOpen(false);
    }
  }, [props?.isSignInModalOpen]);

  useEffect(() => {
    setRef(localStorage.getItem("ref"));
  }, [localStorage.getItem("ref")]);
  useEffect(() => {
    setReload2(localStorage.getItem("reload2"));
  }, [localStorage.getItem("reload2")]);

  const { address, isConnecting, isDisconnected } = useAccount();
  useEffect(() => {
    updateAddress(address);
  }, [address]);
  const getfirebasedata = async () => {
    if (address) {
      const data = await getUserData(address);
      console.log(data);
      if (data?.isWhiteListed) {
        setWhitelisted(true);
      }
      setUserData(data);

      // Check if userData contains an email property
      if (data && data?.userMail) {
        setEmailRegistered(true);
      } else {
        setEmailRegistered(false);
      }
    }
  };

  useEffect(() => {
    if (reload2) {
      getfirebasedata();
    } else {
      getfirebasedata();
    }
  }, [address]);

  useEffect(() => {
    if (reload2) {
      getfirebasedata();
      localStorage.removeItem("reload2");
    } else {
      getfirebasedata();
    }
  }, [reload2]);

  const { data, isError } = useBalance({
    address: address,
  });
  console.log(data);
  const { connect, connectors, error, pendingConnector, isLoading } =
    useConnect();
  const { disconnect } = useDisconnect();

  const { chain } = useNetwork();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();

  const [wflrs, setwflr] = useState("");
  const [wsgbs, setwsgb] = useState("");

  useEffect(() => {
    if (chain) {
      setNetworkModal(false);
    }
  }, [chain, chain?.network]);
  const checkWrappedBalance = async () => {
    if (address) {
      const wflr = await checkBalance(address);
      const roundedValue = Number(wflr); // Convert the rounded value back to a number
      setwflr(roundedValue.toFixed(4));
    }
  };
  const checkWrappedsgbBalance = async () => {
    if (address) {
      const wsgb = await checkWSgbBalance(address);
      const roundedValue = Number(wsgb); // Convert the rounded value back to a number
      setwsgb(roundedValue.toFixed(4));
    }
  };

  useEffect(() => {
    checkWrappedBalance();
  }, [address]);
  useEffect(() => {
    checkWrappedsgbBalance();
  }, [address]);

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  // to handle search bar at small screens
  const [isVisible, setIsVisible] = useState(true);

  // handle navbar postion on scroll
  const [scrolled, setScrolled] = useState(false);

  // Handle search input values
  const [inputValue, setInputValue] = useState("");

  // handle focus of search input
  const [focused, setFocused] = useState(false);
  const [DisplayResult, setDisplayResult] = useState(false);

  // show aside-menu
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showBidModal, setShowBidModal] = useState(false);
  const [Address, setAddress] = useState("");

  // close search bar close btn
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [networkModal, setNetworkModal] = useState(false);

  const handleNetworkModal = () => setNetworkModal(!networkModal);
  // handle asidebar menu
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [seletedArt, setSeletedArt] = useState([]);
  // windows width
  const [mobileWidth, setMobileWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setMobileWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [emailModal, setEmailModal] = useState(true);
  const showEmailModal = () => {
    setEmailModal(true);
  };

  useEffect(() => {
    if (mobileWidth < 1023) {
      setIsVisible(false);

      setFocused(true);
    } else {
      setIsVisible(true);
      setFocused(false);
    }
  }, [mobileWidth]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const Backend_url = process.env.REACT_APP_BACKEND_URL;

  const getNftMetadata = async (tokens_uri) => {
    console.log("url: " + `${Backend_url}/getNFTMetadata`);
    const response = await fetch(`${Backend_url}/getNFTMetadata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uri: tokens_uri,
      }),
    });
    const json = await response.json();
    return {
      data: json?.data?.data,
    };
  };

  const getAllListNFTDataCoston = async () => {
    try {
      const web3 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
      console.log("Connected to Coston explorer collection");

      const contract = new web3.eth.Contract(
        contractABI,
        Marketplace_coston_contractAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      console.log(contract.methods, "contract methods");
      console.log(data, "this is Coston data");

      return data;
    } catch (error) {
      console.error("Error fetching data from Coston:", error);
      return [];
    }
  };

  const getAllListNFTDataCoston2 = async () => {
    try {
      const web3 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
      console.log("Connected to Coston2 explorer collection");

      const contract = new web3.eth.Contract(
        contractABI,
        Marketplace_coston2_contractAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "this is Coston2 data");

      return data;
    } catch (error) {
      console.error("Error fetching data from Coston2:", error);
      return [];
    }
  };

  // const getAllListNFTData = async () => {
  //   try {
  //     const data1 = await getAllListNFTDataCoston();
  //     console.log(data1, "from Coston");
  //     const data2 = await getAllListNFTDataCoston2();
  //     console.log(data2, "from Coston2");

  //     if (data1 && data2) {
  //       console.log(data1[0]);
  //       console.log(data2[0]);

  //       const combinedData = [
  //         ...data1[1],
  //         ...data2[1],
  //         ...data1[0],
  //         ...data2[0],
  //       ];

  //       console.log(combinedData, "Combined list data");

  //       // Fetch metadata for each element
  //       const updatedData = await Promise.all(
  //         combinedData.map(async (item) => {
  //           console.log(item.uriData, "uri");
  //           const res = await getNftMetadata(item.uriData);
  //           console.log(res);
  //           return { ...item, metadata: res }; // Add metadata property to each item
  //         })
  //       );

  //       console.log(updatedData, "Updated list data");

  //       setListedNfts(updatedData);
  //       // setFilteredArts(updatedData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setListedNfts([]);
  //   }
  // };

  // useEffect(() => {
  //   getAllListNFTData();
  // }, [inputValue]);

  useEffect(() => {
    // if (inputValue == "abstract" || inputValue == "Abstract") {
    console.log("search");
    if (inputValue) {
      setSearchResults(true);
      // setDisplayResult(true);
      setTimeout(() => {
        setDisplayResult(true);
        setSearchResults(false);
      }, 3000);
    } else {
      console.log("no search");
      setDisplayResult(false);
    }

    if (inputValue === "" || inputValue === undefined) {
      console.log("no search");

      setShowCloseBtn(false);
    } else {
      console.log(inputValue);
      setShowCloseBtn(true);
    }
  }, [inputValue]);

  const cleanSearch = () => {
    console.log("clean search");

    setInputValue("");
  };
  const hideSearchBar = () => {
    console.log("hide search bar");
    setIsVisible(!isVisible);
  };

  const handleBidModal = () => {
    setShowBidModal((prev) => !prev);
  };

  // -------------------------------------------------
  // seacrh effects
  const [searchResults, setSearchResults] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      // Perform the search with the search term
      performSearch();
    }, 500);
    setTimeoutId(newTimeoutId);
    return () => {
      clearTimeout(newTimeoutId);
    };
  }, [inputValue]);
  const performSearch = () => {
    if (inputValue) {
      setSearchResults(true);
    } else {
      setSearchResults(false);
    }
  };

  const handleCopyClick = () => {
    // Copy text to clipboard
    navigator.clipboard.writeText(address);

    // Optional: Provide user feedback
    // alert("Text copied to clipboard!");
    toast.success("Address Copied");
  };

  const navigate = useNavigate();
  const location = useLocation();
  const pathsToHideNavBar = [
    "/create-art",
    "/mint-art",
    "/create",
    "/select",
    "/list-forSale",
    "/edit-collections",
    "/no-collection-exists",
    "/report-issue",
    "/list-forOffer",
  ];

  const setDarkNav = ["/spotlight"];
  const shouldHideNavBar = pathsToHideNavBar.some((path) =>
    location.pathname.includes(path)
  );

  const darkNav = setDarkNav.includes(location.pathname);

  const test = "http://localhost:3000/create-art/ZxZzV7stHtsE4OLn2DjN";

  const includesCreateArt = test.includes("/create-art");

  const handleNavigation = (link) => {
    console.log("link", link);
    navigate(link);
    setShow(false);
    setShow2(false);
  };

  const [status, setStatus] = useState(null);

  useEffect(() => {
    const test = localStorage.getItem("catalystSigner");
    console.log("catalystSigner", test);
    setStatus(test);
  }, [localStorage.getItem("catalystSigner")]);

  const reloadEffect = () => {
    const test = localStorage.getItem("catalystSigner");
    console.log("catalystSigner", test);
    if (test != null) {
      setStatus(true); // Set status to true directly
    }
    // alert("reloading!");
  };
  useEffect(() => {
    console.log(chains);
    const currentChainName = "Coston"; // Replace this with the name of the chain you want to check

    const isConnected = chains.some((chain) => chain.name === currentChainName);
    console.log(isConnected);
  }, [chains]);

  const [collectedArts, setCollectedArts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredArts, setFilteredArts] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    console.log(listedNfts, "listed nfts");

    // setFilteredArts(listedNfts);
    setFilteredCollections(collections);
    setFilteredUsers(users);
  }, [collectedArts, collections, users, listedNfts]);
  useEffect(() => {
    console.log(filteredArts);
  }, [filteredArts]);

  const fetchData = async () => {
    const userslist = await getAllUsers();
    console.log("Users Data: ", userslist);
    console.log("Users Data length: ", userslist?.length);
    setUsers(userslist);
  };

  const getUserCollections = async () => {
    const usercollections = await getCollections();
    const filterCollection = usercollections?.filter(
      (item) => item?.data?.isWhiteList === true
    );
    console.log("All Collection: ", filterCollection);
    setCollections(filterCollection);
  };

  const fetchAllArt = async () => {
    const allArts = await getAllArts();
    console.log("All Arts", allArts);
    const filterArts = allArts?.filter((item) => item?.data?.isMinted === true);
    // setCollectedArts(filterArts);
  };

  useEffect(() => {
    fetchData();
    getUserCollections();
    fetchAllArt();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (users) searchFilter();
  }, [inputValue, users, listedNfts]);

  const [allUserData, setAllUserData] = useState([]);

  const getArtistNamebyAdress = (ArtistAddress) => {
    let artistName = "";
    if (address === ArtistAddress) {
      return "You";
    }

    console.log("users", users);
    users?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistName = user?.userName;
      }
    });

    console.log("artist name", artistName, "address", ArtistAddress);
    return "@" + artistName;
  };

  const searchFilter = () => {
    console.log(selectedTags, "selected tags");
    if ((selectedTags && selectedTags.length > 0) || selectedTags) {
      setFilteredArts([]);
      setFilteredCollections([]);
      setFilteredUsers([]);
      return;
    }

    console.log(listedNfts, "listed Nfts");
    for (let a of listedNfts) {
      console.log(
        a?.metadata?.data?.artName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) ||
        getArtistNamebyAdress(a?.data?.listedData?.artist)?.toLowerCase() ||
        getArtistNamebyAdress(a?.data?.mintArtist)
          .toLowerCase()
          .includes(inputValue?.toLowerCase())
      );
    }

    console.log(filteredArts, "filteredArts");
    console.log("inputValue", inputValue);
    console.log("collectedArts", collectedArts);
    console.log("collections", collections);
    console.log("users", users);
    console.log(selectedTags, "selected tags");
    if (inputValue && !selectedTags) {
      const arts = listedNfts?.filter((item) => {
        console.log(item?.data?.listedData?.artist);
        item?.metadata?.data?.artName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) ||
          getArtistNamebyAdress(
            item?.data?.listedData?.artist
          )?.toLowerCase() ||
          getArtistNamebyAdress(item?.data?.mintArtist)
            .toLowerCase()
            ?.toLowerCase()
            .includes(inputValue?.toLowerCase());
      });

      let FilArts = [];

      for (let a of listedNfts) {
        const artNameMatch = a?.metadata?.data?.artName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase());
        let artistName;

        if (a.data?.isListed) {
          artistName = getArtistNamebyAdress(a?.data?.listedData?.artist)
            .toLowerCase()
            .includes(inputValue.toLowerCase());
          console.log(artistName, "artist name");
        } else {
          artistName = getArtistNamebyAdress(a?.data?.mintArtist)
            .toLowerCase()
            .includes(inputValue.toLowerCase());
          console.log(artistName, "artist name");
        }

        const tagsMatch = a?.metadata?.data?.selectedTags.some((tag) =>
          tag.toLowerCase().includes(inputValue?.toLowerCase())
        );
        console.log(artNameMatch, "art name matched");
        console.log(artistName, "artis name");
        console.log(tagsMatch, "tags match");
        if (artNameMatch || artistName || tagsMatch) {
          console.log(a, "matched data");
          FilArts.push(a);
        }
      }

      console.log(FilArts, "FilArts");

      console.log(FilArts);
      const filteredCollections = collections?.filter(
        (item) =>
          item?.data?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          item?.documentId === inputValue ||
          item?.data.address === inputValue
      );
      const filteredUsers = users?.filter(
        (item) =>
          item?.userName?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          item?.id === inputValue ||
          item?.address === inputValue
      );
      console.log("filteredArts", filteredArts);
      console.log("filteredCollections", filteredCollections);
      console.log("filteredUsers", filteredUsers);
      setFilteredArts(FilArts);
      setFilteredCollections(filteredCollections);
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredArts(filteredArts);
      setFilteredCollections([]);
      setFilteredUsers([]);
    }
  };

  const [seeMore, setSeeMore] = useState(true);
  const [seeArt, setSeeArt] = useState(true);
  const [displayedCollections, setDisplayedCollections] = useState([]);
  const [displayArt, setDisplayArt] = useState([]);

  useEffect(() => {
    console.log(filteredArts);
    const updatedArts = seeArt ? filteredArts.slice(0, 4) : filteredArts;
    setDisplayArt(updatedArts);
  }, [filteredArts, seeArt]);

  useEffect(() => {
    console.log(setFilteredCollections);
    const updatedCollections = seeMore
      ? filteredCollections.slice(0, 4)
      : filteredCollections;
    setDisplayedCollections(updatedCollections);
  }, [filteredCollections, seeMore]);

  const initialTags = [
    "3D",
    "Abstract",
    "Generative",
    "Animation",
    "Surreal",
    "Illustration",
    "Surrealism",
    "AI",
    "Painting",
    "Photography",
    "Portrait",
    "Psychedelic",
    "Digital Art",
    "Fantasy",
    "Landscape",
    "Audio",
    "Nature",
    "Drawing",
  ];

  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (e) => {
    const tag = e.target.value;

    if (e.target.checked) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      if (selectedTags) {
        setSelectedTags(selectedTags.filter((t) => t !== tag));
        // setInputValue(null);
      }
    }
  };

  const searchCategoryFilter = () => {
    console.log(selectedTags);
    if (selectedTags?.length > 0) {
      console.log("here");
      // Check if the item belongs to any selected category
      console.log(listedNfts, "listed nfts");
      const filtered = [];
      listedNfts?.forEach((item) => {
        const categories = item?.metadata?.data?.selectedTags;
        console.log(categories);

        if (
          categories &&
          selectedTags.some((tag) => categories.includes(tag))
        ) {
          const arrayInsideItem = Object.values(item)[0];
          setInputValue(selectedTags[0]);
          setDropdownVisible(false);

          filtered.push(item);
        } else {
          setDropdownVisible(false);
          setInputValue(selectedTags[0]);
        }
      });
      console.log(filtered);
      setDropdownVisible(false);
      updateSearch();

      navigate("/explore", { state: { art: filtered, tag: selectedTags[0] } });
      setFocused(false);
    } else {
      setDropdownVisible(false);
      if (selectedTags) {
        setInputValue(selectedTags[0]);
      }

      setSeletedArt([]);
    }
  };

  useEffect(() => {
    searchCategoryFilter();
  }, [selectedTags]);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const inputRef = useRef(null);
  const otherElementRef = useRef(null);

  const handleInputChange = (e) => {
    setDropdownVisible(true);
    setInputValue(e.target.value);
    setFocused(true);
  };

  const handleClickOutside = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      otherElementRef.current &&
      !otherElementRef.current.contains(e.target)
    ) {
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    if (dropdownVisible) {
      setInputValue("");
      setSelectedTags("");
    }
    setDropdownVisible(!dropdownVisible);
    setFocused(!focused);
  };

  const { unreadNotifications, setUnreadNotifications } =
    useContext(RefreshContext);

  useEffect(() => {
    console.log(unreadNotifications, "Unread notifications status in Navbar");
  }, [unreadNotifications]);

  useEffect(() => {
    console.log(
      dropdownVisible,
      filteredArts?.length,
      filteredCollections?.length,
      filteredUsers?.length,
      filteredArts?.length !== 0 ||
      filteredCollections?.length !== 0 ||
      filteredUsers?.length !== 0,
      "dropdownVisible"
    );
  }, [dropdownVisible]);
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        console.log(priceInUSD);
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        console.log(priceInUSD);
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  
  useEffect(() => {
    console.log(seletedArt, "filteredArts");
    console.log(seletedArt?.length);
  }, [seletedArt]);

  const [wflr, setWflr] = useState();
  const [wsgb, setWsgb] = useState();

  useEffect(() => {
    if(address){
      const web3s = new Web3('https://flare-api.flare.network/ext/C/rpc');
      const web3sgb = new Web3('https://songbird-api.flare.network/ext/C/rpc');
      const wflrAddress = '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d';
      const wsgbAddress = '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED';
      const wflrContract = new web3s.eth.Contract(wrappedAbi, wflrAddress);
      const wsgbContract = new web3sgb.eth.Contract(wrappedAbi, wsgbAddress);
      wflrContract.methods.balanceOf(address).call()
        .then(balance => {
          console.log('Balance:', balance);
          setWflr(balance);
        })
        .catch(err => {
          console.error('Error:', err);
        });
      wsgbContract.methods.balanceOf(address).call()
        .then(balance => {
          console.log('Balance:', balance);
          setWsgb(balance);
        })
        .catch(err => {
          console.error('Error:', err);
        });
  
  
    }
 
  }, [address])

                  

  return (
    <>
      <div className={darkNav ? "darkNav" : ""}>
        <header
          className={`catalyst-navbar ${props.hide}`}
          style={{
            position: scrolled ? " " : " ", // Change to "fixed" or "relative" based on your requirements
            display: shouldHideNavBar ? "none" : "",
            top: -1, // Add top property for fixed position
            left: 0, // Add left property for fixed position
            width: "100%", // Add width property for fixed position
            // zIndex: 1000, // Add zIndex property for fixed position
          }}
        >
          {/* Navbar */}
          <nav
            className="navbar desktop-nav"
            style={{
              paddingTop: scrolled ? "18px" : "24px",
              paddingBottom: scrolled ? "18px" : "24px",
            }}
          >
            <div
              className={
                focused ? " back-layers show-layer" : "back-layers hide-layer"
              }
            ></div>
            <div
              className="mobile-search-icon icon-shadow"
              onClick={hideSearchBar}
            >
              <img src={searchIcon} alt="search" />
            </div>
            <div className="left d-flex">
              {/* site logo */}

              <Link to="/" className="site-logo">
                <img src={logo} alt="fat cats logo" />
              </Link>

              <div
                // className=" site-search "
                className="site-search"
                style={{
                  // display: isVisible ? "flex" : "none",
                  transform: `translateX(${isVisible ? "0" : "-1200px"})`,
                }}
              >
                {/* arrow to hide search bar for mobile */}

                <div className="back-search" onClick={hideSearchBar}>
                  <img src={backarrow} alt="back" />
                </div>

                {/* Search bar */}
                <div className="search-form">
                  <img src={searchIcon} alt="search" className="search-icon" />
                  {/* <input
                  type="text"
                  placeholder="Search art, artists & categories…"
                  style={{ transform: "10px" }}
                  value={inputValue}
                  maxLength="26"
                  onChange={handleInputChange}
                  onFocus={() => {
                    setFocused(true);
                  }}
                  
                  // onBlur={() => {
                  //   setFocused(false);
                  // }}
                /> */}

                  <input
                    type="text"
                    placeholder="Search art, artists & categories…"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => {
                      toggleDropdown();
                    }}
                    ref={inputRef}
                  />
                  {showCloseBtn !== false ? (
                    <span
                      className="close-button cursor-pointer"
                      onClick={() => {
                        if (selectedTags.length > 0) {
                          updateSearch();
                        }

                        cleanSearch();
                      }}
                    >
                      <img
                        src={close}
                        alt="close"
                        onClick={() => toggleDropdown()}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  {/* Search-bar Categories */}

                  {/* When you focus search-bar */}

                  <div
                    // className="search-catergories"
                    className={
                      "search-catergories " +
                      (focused ? "scrollable-container" : "")
                    }
                    id="myDropdown"
                    style={{ display: focused ? "block" : "none" }}
                    ref={otherElementRef}
                  >
                    {/* Firstly categories will be shown static */}
                    {inputValue?.length > 0 ? (
                      <>
                        {/* Display selected tags */}
                        {dropdownVisible && selectedTags && (
                          <div className="searchbar-result artwork ">
                            <div className="result-box">
                              {/* Selected Art */}
                              {filteredArts?.length > 0 && (
                                <div className="result-box-head">
                                  <p className="body-medium">
                                    <span>
                                      Artwork ({seletedArt?.length} results)
                                    </span>
                                    <span>
                                      <button
                                        className="button"
                                        onClick={() => {
                                          navigate("/explore", {
                                            state: { tab: "drops" },
                                          });
                                          toggleDropdown();
                                        }}
                                      >
                                        See more
                                        <img
                                          src={arrowRightSmall}
                                          alt="right"
                                        />
                                      </button>
                                    </span>
                                  </p>
                                </div>
                              )}
                              <div className="result-box-content">
                                {seletedArt?.map((item, index) => {
                                  return (
                                    <div
                                      className="result-card"
                                      key={index}
                                      onClick={() => {
                                        navigate(
                                          `artwork/${item?.metadata?.data?.selectedBlockchain}/${item?.metadata?.data?.selectedCollectionId}/${item?.listedData.tokenId}`
                                        );
                                        toggleDropdown();
                                      }}
                                    >
                                      <div className="left">
                                        <img
                                          src={item?.metadata?.data?.image}
                                          alt=""
                                          className="img-63"
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <p
                                          className="body-medium"
                                          style={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "60%",
                                            display: "block",
                                          }}
                                        >
                                          {item?.metadata?.data?.artName}
                                        </p>
                                        <p
                                          className="body-medium"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            display: "block",
                                          }}
                                        >
                                          {item?.data?.isListed
                                            ? getArtistNamebyAdress(
                                              item?.data.listedData?.artist
                                            )
                                            : getArtistNamebyAdress(
                                              item?.data?.mintArtist
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Display filtered results */}
                        {dropdownVisible &&
                          (filteredArts?.length !== 0 ||
                            filteredCollections?.length !== 0 ||
                            filteredUsers?.length !== 0) && (
                            <>
                              {/* Filtered Art */}
                              <>
                                {filteredArts.length > 0 && (
                                  <div className="searchbar-result artwork ">
                                    <div className="result-box">
                                      {/* Filtered Art Head */}
                                      {filteredArts?.length > 0 && (
                                        <div className="result-box-head">
                                          <p className="body-medium">
                                            <span>
                                              Artwork ({filteredArts?.length}{" "}
                                              results)
                                            </span>
                                            <span
                                              onClick={() => {
                                                setSeeArt(!seeArt);
                                              }}
                                            >
                                              <button className="button">
                                                {seeArt
                                                  ? "See More"
                                                  : "See Less"}
                                                <img
                                                  src={arrowRightSmall}
                                                  alt="right"
                                                />
                                              </button>
                                            </span>
                                          </p>
                                        </div>
                                      )}
                                      {/* Filtered Art Content */}
                                      <div className="result-box-content">
                                        {displayArt?.map((item, index) => {
                                          return (
                                            <div
                                              className="result-card"
                                              key={index}
                                              onClick={() => {
                                                navigate(
                                                  `artwork/${item?.metadata?.data?.selectedBlockchain}/${item?.metadata?.data?.selectedCollectionId}/${item?.listedData.tokenId}`
                                                );
                                                toggleDropdown();
                                              }}
                                            >
                                              <div className="left">
                                                <img
                                                  src={
                                                    item?.metadata?.data?.image
                                                  }
                                                  alt=""
                                                  className="img-63"
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <p
                                                  className="body-medium"
                                                  style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    width: "60%",
                                                    display: "block",
                                                  }}
                                                >
                                                  {item?.metadata?.data?.artName
                                                    .length > 16
                                                    ? item?.metadata?.data?.artName.substring(
                                                      0,
                                                      16
                                                    ) + "..."
                                                    : item?.metadata?.data
                                                      ?.artName}
                                                </p>
                                                <p
                                                  className="body-medium"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    width: "100%",
                                                    display: "block",
                                                  }}
                                                >
                                                  {/* {item?.data?.isListed
                                                ? getArtistNamebyAdress(
                                                    item?.data.listedData
                                                      ?.artist
                                                  )
                                                : getArtistNamebyAdress(
                                                    item?.data?.mintArtist
                                                  )} */}
                                                  {item?.data?.isListed
                                                    ? (() => {
                                                      const name =
                                                        getArtistNamebyAdress(
                                                          item?.data
                                                            .listedData
                                                            ?.artist
                                                        );
                                                      return name !== "you"
                                                        ? name.length > 10
                                                          ? `${name.substring(
                                                            0,
                                                            10
                                                          )}...`
                                                          : `${name}`
                                                        : name;
                                                    })()
                                                    : (() => {
                                                      const name =
                                                        getArtistNamebyAdress(
                                                          item?.data
                                                            ?.mintArtist
                                                        );
                                                      return name !== "you"
                                                        ? name.length > 10
                                                          ? `${name.substring(
                                                            0,
                                                            10
                                                          )}...`
                                                          : `${name}`
                                                        : name;
                                                    })()}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                              {/* Filtered Users */}
                              {filteredUsers.length > 0 && (
                                <div className="searchbar-result people ">
                                  <div className="result-box">
                                    <div className="result-box-head">
                                      <p className="body-medium">
                                        <span>
                                          People ({filteredUsers?.length}{" "}
                                          results)
                                        </span>
                                      </p>
                                    </div>

                                    <div className="result-box-content">
                                      {filteredUsers?.map((item, index) => {
                                        return (
                                          <div
                                            className="result-card"
                                            key={index}
                                            onClick={() => {
                                              navigate(`/profile/${item?.id}`);
                                              toggleDropdown();
                                            }}
                                          >
                                            <div className="left">
                                              <img
                                                src={
                                                  item?.image
                                                    ? item?.image
                                                    : placeholder
                                                }
                                                alt=""
                                                className="img-63"
                                              />
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop: "10px",
                                              }}
                                            >
                                              <p className="body-medium">
                                                {item?.userName.length > 16
                                                  ? item?.userName.substring(
                                                    0,
                                                    16
                                                  ) + "..."
                                                  : item?.userName}
                                              </p>
                                              <p>
                                                {item?.id?.slice(0, 4)}...
                                                {item?.id?.slice(-4)}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {displayedCollections.length > 0 && (
                                <>
                                  {/* Filtered Collections */}
                                  <div className="searchbar-result collection ">
                                    <div className="result-box">
                                      {/* Filtered Collections Head */}
                                      <div className="result-box-head">
                                        <p className="body-medium">
                                          <span>
                                            Collection (
                                            {filteredCollections?.length}{" "}
                                            results)
                                          </span>
                                          <span>
                                            <button
                                              className="button"
                                              onClick={() => {
                                                setSeeMore(!seeMore);
                                                // navigate("/allcollections");.
                                                // toggleDropdown();
                                              }}
                                            >
                                              {seeMore
                                                ? "See More"
                                                : "See Less"}
                                              <img
                                                src={arrowRightSmall}
                                                alt="right"
                                              />
                                            </button>
                                          </span>
                                        </p>
                                      </div>
                                      {/* Filtered Collections Content */}
                                      <div className="result-box-content">
                                        {displayedCollections?.map(
                                          (item, index) => {
                                            console.log(
                                              item,
                                              "item in collection"
                                            );
                                            return (
                                              <div
                                                className="result-card"
                                                key={index}
                                                onClick={() => {
                                                  navigate(
                                                    `/explore-collections/${item?.documentId}`
                                                  );
                                                  toggleDropdown();
                                                }}
                                              >
                                                <div className="left">
                                                  <img
                                                    src={
                                                      item?.data?.image
                                                        ? item?.data?.image
                                                        : imgg
                                                    }
                                                    alt=""
                                                    className="img-63"
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <p className="body-medium">
                                                    {item?.data?.name.length >
                                                      16
                                                      ? item?.data?.name.substring(
                                                        0,
                                                        16
                                                      ) + "..."
                                                      : item?.data?.name}
                                                  </p>
                                                  <p>
                                                    {item?.data?.contractAddress?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    ...
                                                    {item?.data?.contractAddress?.slice(
                                                      -4
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                        {/* Display browsing category if no results */}
                        {dropdownVisible == true &&
                          filteredArts?.length === 0 &&
                          filteredCollections?.length === 0 &&
                          filteredUsers?.length === 0 &&
                          seletedArt.length === 0 && (
                            <div className="browsing-category">
                              <div className="noResults">
                                {/* <img src={sadface} alt="loading" /> */}
                                <p className="medium-body">
                                  Sorry, no results for{" "}
                                  <span>"{inputValue}"</span>
                                </p>
                              </div>
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            focused ? "category-opt" : "category-opt d-nones"
                          }
                        >
                          <CustomCheckBox
                            values={initialTags}
                            selectedTags={selectedTags}
                            onChange={toggleTag}
                          />
                        </div>
                        {/* <div
                        className="searchbar-result artwork "
                        style={{
                          paddingTop: "5px",
                        }}
                      >
                        <div className="result-box">
                          <div className="result-box-head">
                            <p className="body-medium">
                              <span>
                                Artwork ({filteredArts?.length} results)
                              </span>
                              <span>
                                <button
                                  className="button"
                                  onClick={() => {
                                    navigate("/drops");
                                    toggleDropdown();
                                  }}
                                >
                                  See more
                                  <img src={arrowRightSmall} alt="right" />
                                </button>
                              </span>
                            </p>
                          </div>
                          <div className="result-box-content">
                            {filteredArts?.map((item, index) => {
                              return (
                                <div
                                  className="result-card"
                                  key={index}
                                  onClick={() => {
                                    navigate("/drops");
                                    toggleDropdown();
                                  }}
                                >
                                  <div className="left">
                                    <img
                                      src={item?.data?.image}
                                      alt=""
                                      className="img-63"
                                    />
                                  </div>
                                  <div
                                    // className="right"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      // className="body-medium text-grey"
                                      className="body-medium"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "60%",
                                        display: "block",
                                      }}
                                    >
                                      {item?.data?.name}
                                    </p>
                                    <p
                                      // className="text-grey"
                                      className="body-medium"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "100%",
                                        display: "block",
                                      }}
                                    >
                                      @{item?.data?.artistName}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div> */}
                      </>
                      // <div
                      //   className={
                      //     focused ? "category-opt" : "category-opt d-nones"
                      //   }
                      // ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
              {/* Nav Links */}

              <ul className="navbar-nav ">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="explore"
                  >
                    Art
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="explore"
                    state={{ tab: "drops" }}
                  >
                    Drops
                  </Link>
                </li> */}
                <li className="nav-item">
                  {/* <Link className="nav-link" to="/allcollections">
                  PFPs
                </Link> */}
                  <Link className="nav-link" to="/">
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/spotlight">
                    Spotlight
                  </Link>
                </li>
                {/* <li className="nav-item">
                <a className="nav-link " href="#">
                  Gallery
                </a>
              </li> */}

                {/* if login, show img profile else Sign In btn */}

                <div className="logins ">
                  <div
                    className="mobile-menu-btn icon-shadow"
                    onClick={handleShow}
                  >
                    <img src={hamburger} alt="hamburger" />
                  </div>

                  {address && status ? (
                    // remove className "no-after" when there is no notifications

                    <div
                      className={`login-profile icon-shadow ${unreadNotifications ? "" : "no-after"
                        }`}
                      onClick={handleShow2}
                    >
                      {userData?.image ? (
                        <img src={userData?.image} alt="profile-img" />
                      ) : (
                        <img
                          src={placeholder}
                          alt="profile-img"
                          className="profile-placholder"
                        />
                      )}
                    </div>
                  ) : (
                    <div className="sign-btn">
                      <ConnectButton.Custom>
                        {({
                          account,
                          chain,
                          openAccountModal,
                          openChainModal,
                          openConnectModal,
                          authenticationStatus,
                          mounted,
                        }) => {
                          // Note: If your app doesn't use authentication, you
                          // can remove all 'authenticationStatus' checks
                          const ready =
                            mounted && authenticationStatus !== "loading";
                          const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                              authenticationStatus === "authenticated");

                          return (
                            <div
                              {...(!ready && {
                                "aria-hidden": true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: "none",
                                  userSelect: "none",
                                },
                              })}
                            >
                              {(() => {
                                if (!connected) {
                                  return (
                                    <button
                                      ref={signInButtonRef}
                                      className="btn-prime btn-primary btn-primaryA nav-btn hide-on-768"
                                      onClick={openConnectModal}
                                      type="button"
                                    >
                                      Sign In
                                    </button>
                                  );
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button
                                      onClick={openChainModal}
                                      type="button"
                                    >
                                      Wrong network
                                    </button>
                                  );
                                }

                                if (authenticationStatus == "authenticated") {
                                  <button
                                    className="btn-prime btn-primary btn-primaryA nav-btn hide-on-768"
                                    onClick={openConnectModal}
                                    type="button"
                                  >
                                    lock22 Sign In
                                  </button>;
                                  {
                                    setShowBidModal(true);
                                  }
                                }
                                return (
                                  <>
                                    <button
                                      className="btn-prime btn-primary btn-primaryA nav-btn hide-on-768"
                                      onClick={openConnectModal}
                                      type="button"
                                    >
                                      Sign In
                                    </button>
                                    {setShowBidModal(true)}
                                  </>
                                );
                              })()}
                            </div>
                          );
                        }}
                      </ConnectButton.Custom>
                    </div>
                  )}
                  {/* <WalletButton wallet="rainbow" /> */}

                  {/* or */}
                </div>
              </ul>
            </div>
            {/*Profile Asidebar menu offcanvas if user is connected  */}

            {/* Navbar navlinks offcanvas */}
            <Offcanvas
              show={show2}
              onHide={handleClose2}
              className="catalyst-sidebar "
              placement="end"
            >
              <Offcanvas.Header>
                <div className="hide-desktop"></div>
                <label id="offcanvasRightLabel" className="medium">
                  ACCOUNT
                </label>
                <button
                  type="button"
                  className="btn-close text-reset"
                  onClick={handleClose2}
                ></button>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul>
                  <li className="pointer">
                    <a onClick={() => handleNavigation(`/profile/${address}`)}>
                      Profile
                    </a>
                  </li>
                  {whitelisted && (
                    <li className="pointer">
                      <a onClick={() => handleNavigation("/create")}>Create</a>
                    </li>
                  )}
                  {/* <li className="pointer">
                  <a onClick={() => handleNavigation("/allcollections")}>
                    All Collections
                  </a>
                </li> */}
                  <li className="pointer">
                    <a onClick={() => handleNavigation("/notification")}>
                      Notifications
                    </a>
                  </li>
                  <li className="pointer">
                    <a onClick={() => handleNavigation("/account-settings")}>
                      Settings
                    </a>
                  </li>
                  <li className="pointer">
                    <a
                      onClick={() => {
                        disconnect();
                        setShow(false);
                        setShow2(false);
                        localStorage.clear(); // Clear all items from localStorage

                        localStorage.removeItem("catalystSigner");
                        navigate("/");
                      }}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>

                {/* Connected wallet address */}

                <div className="wallet-address">
                  <p className="body-medium">
                    WALLET
                    <span>
                      <img src={copy} alt="copy" onClick={handleCopyClick} />
                      {/* {addressWallet} */}
                      {address &&
                        address?.slice(0, 5) +
                        "..." +
                        address?.slice(address?.length - 6, address?.length)}
                    </span>
                  </p>
                </div>
                <div className="network-message">
                  {/* {chain && <div>Connected to {chain?.name}</div>} */}
                  <label
                    className="wrong-network br-30 text-black fw-normal medium v-center pointer "
                    onClick={() => {
                      if (chain?.name == "Flare Mainnet") {
                        handleNetworkModal();
                      } else if (chain?.name == "Songbird Mainnet") {
                        handleNetworkModal();
                      } else {
                        handleNetworkModal();
                      }
                    }}
                  >
                    {chain?.unsupported ? (
                      <>
                        <img src={circleRed} alt="error" className="me-2" />
                        Wrong Network
                      </>
                    ) : chain?.name === "Flare Mainnet" ? (
                      "Flare"
                    ) : chain?.name === "Songbird Mainnet" ? (
                      "Songbird"
                    ) : (
                      chain?.name
                    )}
                  </label>
                </div>

                {/* token values */}

                <div className="t-values">
                  {/* flr */}
                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium text-uppercase">
                        flr
                        {data?.symbol === "FLR" ? (
                          <span>{Number(data?.formatted).toFixed(2)}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {data?.symbol === "FLR"
                            ? (flr * data?.formatted).toFixed(2)
                            : "0"}{" "}
                          $
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* wflr */}
              
                  {/* SGB */}
                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium text-uppercase">
                        SGB
                        {data?.symbol === "SGB" ? (
                          <span>{Number(wsgbs).toFixed(2)}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>

                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {data?.symbol === "SGB"
                            ? (sgb * data?.formatted).toFixed(2)
                            : "0"}
                          $
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* WSGB */}
                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium">
                        CFLR
                        {data?.symbol === "CFLR" ? (
                          <span>{Number(data?.formatted).toFixed(2)}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {data?.symbol === "CFLR"
                            ? (sgb * data?.formatted).toFixed(2)
                            : "0"}{" "}
                          $
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium text-uppercase">
                        C2FLR
                        {data?.symbol === "C2FLR" ? (
                          <span> {Number(data?.formatted).toFixed(2)}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {data?.symbol === "C2FLR"
                            ? (flr * data?.formatted).toFixed(2)
                            : "0"}{" "}
                          $
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium text-uppercase">
                        WFlr
                        {wflr ? (
                          <span> {wflr}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {data?.symbol === "C2FLR"
                            ? (flr * wflr).toFixed(2)
                            : "0"}{" "}
                          $
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="cur-val">
                    <div className="left">
                      <p className="body-medium text-uppercase">
                        wsgb
                        {wsgb ? (
                          <span> {wsgb}</span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                      <p className="body-medium">
                        USD
                        <span>
                          {wsgb
                            ? (flr * wsgb).toFixed(2)
                            : "0"}{" "}
                          $
                        </span>
                      </p>
                    </div>
                  </div>


                </div>
              </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
              show={show}
              onHide={handleClose}
              className="mobile-navcanvas"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <label className="medium w-100 text-center">MENU</label>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="site-logo text-center">
                  <img src={logo} alt="logo" onClick={() => navigate("/")} />
                  <img src={star} alt="star" />
                </div>

                <div className="mobile-nav-links">
                  <ul>
                    <li>
                      <a onClick={() => handleNavigation("/explore")}>
                        Explore Art
                      </a>
                    </li>
                    {/* <li>
                    <a onClick={() => handleNavigation("/drops")}>
                      Explore Drops
                    </a>
                  </li> */}

                    {/* <li>
                      <a onClick={() => handleNavigation("/explore")}>
                        Explore Drops
                      </a>
                    </li> */}
                    <li>
                      <a onClick={() => handleNavigation("/spotlight")}>
                        Spotlight
                      </a>
                    </li>
                    {/* <li>
                    <a href="#">Gallery</a>
                  </li> */}
                  </ul>

                  <ul>
                    <li>
                      <a onClick={() => handleNavigation("/stats")}>
                        Trending Artists
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleNavigation("/stats")}>
                        New Artists
                      </a>
                    </li>
                    <li>
                      <a href="#">Live Auctions</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="https://discord.gg/zAQ9RAh7db" target="_blank">
                        Discord
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/thefatcatsNFT"
                        target="_blank"
                      >
                        Twitter
                      </a>
                    </li>
                  </ul>
                </div>

                {address ? (
                  <></>
                ) : (
                  <div
                    className="sign-btn hide-after-mobile768"
                    onClick={handleBidModal}
                    text="Sign In"
                  />
                )}
              </Offcanvas.Body>
            </Offcanvas>
          </nav>
        </header>
      </div>

      <WallatModal
        show={showBidModal}
        handleModal={handleBidModal}
        setShowBidModal={setShowBidModal}
        handleEmail={!emailRegistered}
        handleEmailSet={setEmailModal}
        onClose={reloadEffect}
      // disconnect={disconnect}
      />

      {/* switch network */}
      <Modal
        show={networkModal}
        onHide={handleNetworkModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              switch network
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="buy-now-modal w-431 m-auto  ">
            {/* <h1>test</h1> */}
            {
              // console.log(chains.name.some(value => valuesToCheck.includes(value)))
            }

            {chain?.unsupported ? (
              <p className="mt-5 text-center">
                This app does not support the current connected network.
                <strong> Switch </strong>
                networks or <strong> disconnect</strong> to continue.
              </p>
            ) : (
              <p className="mt-5 text-center">
                This app support the current connected network.
              </p>
            )}

            <div className="network-btns v-center h-center gap-4 mt-5">
              {chains.map((x, index) => (
                <>
                  <Button
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    onClick={() => switchNetwork?.(x.id)}
                    text={x?.name}
                    className="btn-prime rounded border-0"
                    width="156px"
                    height="63px"
                    imageSrc={index % 2 == 0 ? FLR : songbird}
                    imageClassName="me-2"
                  >
                    {isLoading && pendingChainId === x.id && " (switching)"}
                  </Button>
                </>
              ))}

              <div>{error && error.message}</div>
              {/* <Button
                text="Flare"
                className="btn-prime rounded border-0"
                width="156px"
                height="63px"
                onClick={(e) => toast("Flare network Selected")}
                imageSrc={FLR}
                imageClassName="me-2"
              />
              <Button
                text="Songbird"
                className="btn-prime rounded border-0"
                width="156px"
                height="63px"
                onClick={(e) => toast("Songbird network Selected")}
                imageSrc={songbird}
                imageClassName="me-2"
              /> */}
            </div>
            <div className="v-center h-center gap-3 mt-5 w-100">
              <Button
                text="Disconnect"
                className="btn-prime btn-secondary"
                width="156px"
                height="36px"
                onClick={() => {
                  disconnect();
                  handleNetworkModal();
                  setShow2(false);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
