import { Skeleton } from "antd";
import React from "react";

const NotificationLoader = () => {
  return (
    <div>
      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex flex-column gap-2">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "300px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="hide-on-mobile">
          <Skeleton.Avatar
            active
            round
            size="large"
            shape="button"
            style={{
              width: "120px",
              height: "18px",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationLoader;
