import React, { useState } from "react";
import createContract from "../../assets/images/createContract.webp";
// import createContract from "../../assets/images/create-artwork.png";
import Button from "../button";
import Header from "../header";
import { useNavigate } from "react-router-dom";

export default function NoCollectonArtwork() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const handleCreateArtwork = () => {
    navigate("/create-collections");
  };
  const handleImageLoad = () => {
    setLoaded(true);
  };
  const imageStyle = {
    opacity: loaded ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  };
  return (
    <div>
      <section className="create-artwork">
        <Header head="Create" />

        <div className="create-art-popup  ">
          <h4 className="medium-head mb-4">Create New Artwork</h4>
          <p className="mb-50 text-center">
            To create a new artwork, you first need to deploy a smart contract.
          </p>
          <div style={{ position: "relative" }}>
            {!loaded && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "#f0f0f0",
                  borderRadius: "8px",
                }}
              />
            )}

            <img
              src={createContract}
              alt="Your Image"
              onLoad={handleImageLoad}
              style={imageStyle}
            />
          </div>
          <p className="text-center mt-60 pt-3">
            Deploy a smart contract that you own, giving you the flexbility to
            use it anywhere across all of web3.
          </p>

          <Button
            text="Create"
            className="btn-primary text-medium-grey br-30 font-18 mt-60"
            width="100%"
            height="50px"
            onClick={handleCreateArtwork}
          />
        </div>
      </section>
    </div>
  );
}
