import React, { createContext, useState, useEffect } from 'react';
import {getNotifications,getUserData} from "../firebase/firebase";
import { useAccount } from 'wagmi';

export const RefreshContext = createContext();



export const RefreshProvider = ({ children }) => {
    const {address}=useAccount();
    const [unreadNotifications, setUnreadNotifications] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [notification , setNotification]=useState([]);
    const [username, setUserName]=useState(null);

    

    const fetchNotificationData = async () => {
        try {
        
          const data = await getUserData(address);
          setUserName(data?.userName);
          try {
            const notifications = await getNotifications(data?.userName);
            setNotification(notifications);
            const allRead = notifications.every(
              (notification) => notification.isRead
            );
         
          } catch (error) {
            console.error(error);
           
          }
  
        } catch (error) {
          console.error(error);

        }
      };
    
      useEffect(() => {
        fetchNotificationData();
      }, [address]);

      const fetchNotifications = async () => {
        try {
          console.log(notification, "notifiacation");
          const unread = notification.filter(
            (notification) =>
              notification?.isRead === false &&
              notification?.username !== "undefined"
          );
          console.log(unread, "unread....");
    
          if (unread.length > 0) {
            setUnreadNotifications(true);
          } else {
            setUnreadNotifications(false);
            localStorage.setItem("unread", false);
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };
    
      useEffect(() => {
        if (username) {
          fetchNotifications();
        }
      }, [username, notification]);






    useEffect(() => {
        console.log(unreadNotifications, "unread notifiacatiaojoaej");


    }, [unreadNotifications])

    return (
        <RefreshContext.Provider value={{ unreadNotifications, setUnreadNotifications, isAdmin, setIsAdmin }}>
            {children}
        </RefreshContext.Provider>
    );
};
