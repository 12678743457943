import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import Button from "../button";
import star24 from "../../assets/icon/spiked-circle/black/24px.svg";
import location from "../../assets/icon/location.svg";
import twitter from "../../assets/icon/twitter.svg";
import arrow from "../../assets/icon/chevron-down-extra-small.svg";
import EditProfile from "./editProfile";
import { getUserData } from "../../firebase/firebase";
import { useAccount } from "wagmi";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import Web3 from "web3";
import { useNetwork } from "wagmi";

const ProfileTab = ({
  id,
  accountAddress,
  userDataByUserName,
  setReload,
  reload,
}) => {
  console.log(userDataByUserName, "user data by username");

  // const { address: accountAddress } = useAccount();
  const [address, setAddress] = useState(accountAddress);
  useEffect(() => {
    console.log(id, "thisis id");
    if (!id) {
      setAddress(accountAddress);
    } else {
      setAddress(id);
    }
  }, [accountAddress, id]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [show1, setShow1] = useState(false);
  const [collected, setCollected] = useState(0);
  const [sold, setSold] = useState(0);
  const [userData, setUserData] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState(false);

  const handleBidModal = () => {
    setShowBidModal((prev) => !prev);
  };

  // network name
  const { chain } = useNetwork();

  // contract address of marketplace

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  // get nfts bought by connected user and sold by connected user

  const getCollected = async () => {
    if (chain) {
      console.log(chain?.name, "chain name");
      const web3_1 = new Web3(window.ethereum);

      try {
        const accounts = await web3_1.eth.getAccounts();
        let MarketplaceAddress;
        if (chain.name === "Coston") {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (chain.name === "Coston2") {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        } else {
          throw new Error("Unsupported chain name");
        }

        console.log(MarketplaceAddress);
        const contract = new web3_1.eth.Contract(
          contractABI,
          MarketplaceAddress
        );

        console.log(contract.methods, "contract methods");

        const result2 = await contract.methods.userSoldRecord(address).call();
        console.log(result2, "result of sold");
        setSold(result2);
        const result = await contract.methods.userBuyRecord(address).call();
        console.log(result, "result of collected");
        setCollected(result);
      } catch (error) {
        console.error("Error fetching user records:", error);
      }
    }
  };

  useEffect(() => {
    getCollected();
  }, [address]);

  const handleShowBio = () => {
    setShow1(!show1);
  };

  // getting data from firebase
  const getfirebasedata = async () => {
    console.log(address, "address");
    const data = await getUserData(address);
    console.log(data, "white listed");
    if (data) {
      console.log(data?.isWhiteListed);
      setIsWhitelisted(data?.isWhiteListed);
    }
    setUserData(data);
  };

  useEffect(() => {
    if (address) {
      getfirebasedata();
      setReload(false);
    }
  }, [address, userDataByUserName, reload]);

  // storing username in localstorage
  const username = userData?.userName;
  localStorage.setItem("userName", username);

  useEffect(() => {
    console.log(isWhitelisted, "is white listed");
  }, [isWhitelisted]);

  return (
    <div>
      <div className="profile-about">
        <div className="left-content">
          <div className="about-head">
            <label>
              about <img src={star24} alt="star" />
            </label>
            {userDataByUserName ? (
              <p className="body-small">
                {userDataByUserName?.userLocation === "" ? null : (
                  <>
                    {userDataByUserName?.userLocation}
                    <span>
                      <img src={location} alt="location" />
                    </span>
                  </>
                )}
              </p>
            ) : (
              <p className="body-small">
                {userData?.userLocation ? (
                  <>
                    {userData?.userLocation}
                    <span>
                      <img src={location} alt="location" />
                    </span>
                  </>
                ) : null}
              </p>
            )}
          </div>

          {/* if profile is not filled */}
          {userDataByUserName ? (
            <>
              <div className="about-details ">
                <p
                  className="body-medium"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {userDataByUserName?.userAbout == "" ? (
                    <>No Bio Added</>
                  ) : (
                    <>{userDataByUserName?.userAbout}</>
                  )}
                </p>
              </div>
            </>
          ) : userData?.userAbout ? (
            // userData.userAbout
            <div className="about-details ">
              {show1 ? (
                <>
                  <p
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                    className="body-medium"
                  >
                    {userData.userAbout}
                  </p>
                  <div className="readmore-layer ">
                    <div className="divider full">
                      <Button
                        text="READ LESS"
                        width="115px"
                        height="25px"
                        className="btn-prime btn-secondary read-btn"
                        imageSrc={arrow}
                        onClick={handleShowBio}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p
                    className="body-medium"
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {userData?.userAbout?.slice(0, 400)}
                  </p>
                  <div className="readmore-layer ">
                    <div className="divider truncated">
                      <Button
                        text="READ MORE"
                        width="115px"
                        height="25px"
                        className="btn-prime btn-secondary read-btn"
                        imageSrc={arrow}
                        onClick={handleShowBio}
                      />
                    </div>
                  </div>
                </>
              )}

              {userData?.userTwiter && (
                <div className="social-icons">
                  <a
                    href={userData?.userTwiter ? userData.userTwiter : "#"}
                    target="_blank"
                  >
                    <img src={twitter} alt="twitter" />
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className="user-bio">
              <p className="body-large ">You haven't added a bio yet.</p>
              <Button
                text="Add a bio"
                className="btn-prime btn-secondary"
                height="36px"
                width="126px"
                onClick={handleBidModal}
              />
            </div>
          )}
          {userDataByUserName?.userTwiter && (
            <div className="about-details m-0 p-0">
              <div className="social-icons">
                <a href={userDataByUserName?.userTwiter} target="_blank">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>
          )}
          {/* if profile is filled */}
        </div>

        {/* Total collect and sold arts */}

        <div className="right-content">
          <div className="counter created">
            <div className="value">
              <h5>0</h5>
            </div>
            <label className="small">Created</label>
          </div>{" "}
          <div className="counter">
            <div className="value">
              <h5>{collected}</h5>
            </div>
            <label className="small">collected</label>
          </div>
          <div className="counter">
            <div className="value">
              <h5>{sold}</h5>
            </div>
            <label className="small">sold</label>
          </div>
        </div>
      </div>

      <EditProfile
        show={showBidModal}
        handleModal={handleBidModal}
        setReload={setReload}
      />
    </div>
  );
};

export default ProfileTab;
