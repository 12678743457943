import React from "react";
import "../../style/main.scss";

const CustomCheckBox = ({
  values,
  selectedValues = {},
  disabled,
  onChange,
  onTagClick,
  type,
  cursor = "pointer",
}) => {
  return (
    <div>
      <div className="tag-check-boxes v-center">
        {values ? (
          values.map((value, index) => (
            <div className="tag-box" key={index}>
              <label className={cursor}>
                <input
                  type="checkbox"
                  className="d-none"
                  value={value}
                  checked={selectedValues[value]}
                  onChange={onChange}
                  // onClick={() => {
                  //   if (type === "singleArt") {
                  //     onTagClick(value);
                  //   }
                  // }}
                  disabled={type === "singleArt" ? false : disabled}
                />
                <p className="fw-500 body-medium">{value}</p>
              </label>
            </div>
          ))
        ) : (
          <p>No values available</p>
        )}
      </div>
    </div>
  );
};

export default CustomCheckBox;
