import React, { useState, useEffect, useContext } from "react";
import Header from "./header";
// import thumbnail from "../assets/icon/artwork.svg";
import FLR from "../assets/icon/FLR.svg";
import sgb from "../assets/icon/SGB.svg";
import add from "../assets/icon/plus.svg";
import checked from "../assets/icon/radio-selected.svg";
import unchecked from "../assets/icon/radio-unselected.svg";
import placeholder from "../assets/images/profile-1.svg";

import Input from "./inputs";
import Button from "./button";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import ImageUpload from "./imageUpload";
import {
  handleNotifications,
  saveCollectionStats,
  getCollectionStats,
  saveArtworkHistory,
  getArtIdByUrl,
  getUserData
} from "../firebase/firebase";
import { Modal } from "react-bootstrap";
import art from "../assets/images/artwork-example-4.png";
import { useLocation } from "react-router-dom";
import Web3 from "web3";
import marketplaceContractABI from "../abis/Marketplace/v3/abi.json";
import { handleCollectionHistory } from "../firebase/firebase";
import { useSwitchNetwork, useNetwork } from "wagmi";
import mintContractABI from "../abis/SafeMint/v2/abi.json";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { NFTContext } from "./context/appContext";
import useScrollToTop from "../customHooks/scrollToTop";

const ListforSale = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const location = useLocation();
  const contractABI = marketplaceContractABI;
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const web3 = new Web3(window.ethereum);
  const { address } = useAccount();

  const [isNewtworkConnected, setIsNewtworkConnected] = useState(false);

  const { showModal, updateModal } = useContext(NFTContext);
  useEffect(() => {
    console.log(location.state, "location state data");

  }, [location?.state])
  useEffect(() => {
    if (chain?.name !== location?.state?.data?.selectedBlockchain) {
      setIsNewtworkConnected(false);
    } else {
      setIsNewtworkConnected(true);
    }
  }, [chain, location?.state?.data?.selectedBlockchain]);

  const [selectSale, setSelectSale] = useState("Fixed price");
  const [createFinish, setCreateFinish] = useState(false);
  const [createFinishSuccess, setCreateFinishSuccess] = useState(false);
  const [nftDetail, setNftDetail] = useState({});
  const [nftUriData, setNftUriData] = useState({});
  const [price, setPrice] = useState();
  const [artistFee, setArtistFee] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [collectionDetail, setCollectionDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [uri, setUri] = useState(null);
  const [artId, setArtId] = useState(null);
  const [username, setUsername] = useState([]);


  useEffect(() => {
    const getData = async () => {
      let res = await getUserData(address);
      setUsername(res?.userName);
    }


    getData();

  }, [address])

  useEffect(() => {
    const fetchArtIdByUrl = async () => {
      let res = await getArtIdByUrl(uri);
      setArtId(res);
    }

    if (uri) {
      fetchArtIdByUrl();
    }
  }, [uri]);


  useEffect(() => {
    if (location.state) {
      console.log("LOCATION STATE: ", location?.state);


      console.log(location?.state?.nftDetails);
      setUri(location.state?.nftDetails?.uri);
      setNftDetail(location?.state?.nftDetails);
      setNftUriData(location?.state?.data);
      setArtistFee(location?.state?.data?.artistFee);
    }
  }, [location]);

  // const handleSaleType = (tag) => {
  //   setSelectSale(tag);
  // };
  // let userData = localStorage?.getItem("CatalystUserData");
  // let username = JSON.parse(userData)?.userName;
  const handleCreate = () => {
    if (!price) {
      setInputError(true);
      return;
    }
    // username = JSON.parse(userData)?.userName;

    setCreateFinish(true);
  };

  const handleSwitchChain = async () => {
    const switchTo = chains.find(
      (c) => c.name === location?.state?.data?.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };

  const handleFinish = async () => {
    setLoading(true);
    console.log(isNewtworkConnected, "isNewtworkConnected");
    const accounts = await web3.eth.getAccounts();

    console.log("accounts", accounts);
    let MarketplaceAddress;
    if (location?.state?.data?.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (location?.state?.data?.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    console.log("MarketplaceAddress", MarketplaceAddress);
    if (accounts.length === 0) {
      alert("Please connect your wallet");
      setLoading(false);

      return;
    }
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

    const mintContract = new web3.eth.Contract(
      mintContractABI,
      nftDetail?.listedData?.mintContract
    );
    console.log(
      nftDetail?.listedData?.mintContract,
      "nftDetail?.listedData?.mintContract"
    );
    const mintOwner = await mintContract.methods.owner().call();

    const owner = await contract.methods.owner().call();
    console.log("contract owner ", owner);
    console.log("mint owner ", mintOwner);
    console.log("contract", contract);
    console.log("nftDetail", nftDetail);
    console.log("nftUriData", nftUriData);
    console.log("price", price);
    const priceInWei = web3.utils.toWei(price.toString(), "ether");

    setCreateFinish(false);

    const transaction = contract.methods.ListNft(
      nftDetail?.listedData?.mintContract,
      priceInWei,

      nftDetail?.listedData?.tokenId,
      mintOwner,
      nftDetail?.listedData?.artistFeePerAge
    );
    console.log(accounts[0]);
    console.log(MarketplaceAddress);
    console.log(address);

    try {
      const result = await transaction.send({
        from: accounts[0],
        to: MarketplaceAddress,
        data: transaction.encodeABI(),
        gasLimit: 3000000,
      });
      let transactionHash = result.transactionHash;

      const data = {
        action: "listedSale",
        user: username,
        artworkUri: nftUriData,
        from: accounts[0],
        to: MarketplaceAddress,
        price: price,
        tokenId: nftDetail?.listedData?.tokenId,
        transactionHash: transactionHash,
      };
      toast.success("Item listed for sale successfully!");

      setLoading(false);
      console.log(nftUriData?.selectedCollectionId);
      handleCollectionHistory(nftUriData?.selectedCollectionId, data);
      handlecollectionStats(nftUriData?.selectedCollectionId, price);

      console.log("username", username);

      handleNotifications(
        username,
        "listed",
        price,
        location?.state.data.artName,
        transactionHash,
        location?.state?.data?.selectedBlockchain
      );

      let action = "Listed for sale";
      // setCreateFinishSuccess(true);
      // let artId = location?.state.data.artId;
      console.log(artId, "artid");


      // (transactionHash, address, action, username, artworkId, price, to
      let res = await saveArtworkHistory(
        transactionHash,
        address,
        action,
        username,
        artId,
        price,
        null
      );
      console.log(res);
      updateModal();
      navigate(
        `/artwork/${location?.state?.data?.selectedBlockchain}/${nftUriData?.selectedCollectionId}/${nftDetail?.listedData?.tokenId}`
      );
    } catch (error) {
      // toast.error("Tranaction Failed!");
      setLoading(false);

      console.log("errofofList", error);
    }
  };

  const handlecollectionStats = async (collectionId, price) => {
    console.log(price, "price");
    const collectionStats = await getCollectionStats(collectionId);
    console.log("collectionStats", collectionStats);
    if (collectionStats) {
      let floorprice = collectionStats?.floorPrice || []; // Default to empty array if floorPrice is undefined

      // Check if collectionStats.floorPrice is an array and has elements
      if (floorprice.length > 0) {
        // Check if the new price is lower than the current lowest floor price
        if (price < floorprice[floorprice.length - 1].split("-")[0]) {
          // Create a new floor price entry with current time
          floorprice = [...floorprice, `${price}-${new Date().getTime()}`];
        }
      } else {
        // If floorprice is empty, initialize it with the new price
        floorprice = [`${price}-${new Date().getTime()}`];
      }

      // Calculate minFloorPrice
      let minFloorPrice;

      if (floorprice.length === 1) {
        // If there is only one entry in floorprice, assign it to minFloorPrice
        minFloorPrice = Number(floorprice[0].split("-")[0]);
      } else {
        // Otherwise, calculate the minimum value
        minFloorPrice = Math.min(
          ...floorprice.map((item) => Number(item.split("-")[0]))
        );
      }

      console.log(floorprice, "floor price");

      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats.artworkCount,
        createdAt: collectionStats.createdAt,
        creatorEarning: collectionStats.creatorEarning,
        volume: collectionStats.volume,
        SGBvolume: collectionStats.SGBvolume,
        FLRvolume: collectionStats.FLRvolume,
        USDvolume: collectionStats.USDvolume,
        floorPrice: floorprice,
        minFloorPrice: minFloorPrice, // Assigning minFloorPrice here
        listedCount: (collectionStats.listedCount || 0) + 1, // Incrementing listedCount
        saleCount: collectionStats.saleCount,
        owners: collectionStats.owners,
      };
      console.log(data);
      saveCollectionStats(data);
    }
  };

  useEffect(() => { }, [nftDetail, nftUriData, price, artistFee, totalEarning]);

  const handlePrice = () => {
    if (parseInt(price) > 0) {
      let platfromFee = (parseInt(price) * 2) / 100;
      let artistFee = (parseInt(price) * nftUriData?.artistFee) / 100;
      let total = parseInt(price) - platfromFee - artistFee;
      setTotalEarning(total);
    } else {
      setTotalEarning(0);
    }
  };
  const handleModalClose = () => {
    setCreateFinish(false);
  };

  const handleCreateFinishSuccess = () => {
    setCreateFinishSuccess(false);
  };
  useEffect(() => {
    handlePrice();
  }, [price]);

  return (
    <>
      <section className="create-artwork">
        <Header head="List item" />

        <div className="list-forSale">
          <div className="create-artwork-content  ">
            <h4 className="medium-head">List for sale</h4>

            <div className="upload-file mt-40">
              {/* <p className="body-large fw-bold">Image</p> */}
              {location?.state?.data.image ? (
                <img
                  src={location?.state.data.image}
                  alt="art"
                  className="img-fluid mt-3"
                />
              ) : (
                <img
                  src={location?.state?.data.previewImg}
                  alt="art"
                  className="img-fluid mt-3"
                />
              )}
            </div>
            <div>
              <p className="body-large fw-bold mt-40 ">
                <i> {location?.state?.data.artName}</i>
              </p>
              <p className=" mt-25 ">
                {location?.state.data.selectedCollection}
              </p>
            </div>

            {/* Type of sale */}
            {/* <div className="art-collections">
              <p className="body-large fw-bold mt-40 ">Choose type of sale</p>
              <p
                className={`body-large h-64 fw-normal v-center justify-content-between cursor-pointer ${
                  selectSale === "fixed price" ? "active" : ""
                }`}
                onClick={() => handleSaleType("fixed price")}
              >
                <span>
                  <img
                    src={selectSale === "fixed price" ? checked : unchecked}
                    alt="checkbox"
                    className="me-3"
                  />
                  Fixed Price
                </span>
                <span className="text-medium-grey body-medium">
                  The item is listed at the price you set.
                </span>
              </p>

              <p
                className={`body-large h-64 fw-normal v-center justify-content-between cursor-pointer ${
                  selectSale === "auction" ? "active" : ""
                }`}
                onClick={() => handleSaleType("auction")}
              >
                <span>
                  <img
                    src={selectSale === "auction" ? checked : unchecked}
                    alt="checkbox"
                    className="me-3"
                  />
                  Auction
                </span>
                <span className="text-medium-grey body-medium">
                  The item is listed as a timed auction.
                </span>
              </p>
            </div> */}

            {/* Traits */}
            <div className="artwork-traits">
              <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                <span>Set a price</span>
              </p>

              <div className="add-traits mt-3 d-flex flex-column">
                <div className="d-flex pt-2 add-traits-content">
                  <div className="type left w-100">
                    <div className="input-box br-20 ">
                      <Input
                        type="Number"
                        placeholder="Amount"
                        className="inputtype1"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="delete  current-btn">
                    <Button
                      width="84px"
                      height="41px"
                      className="br-30 btn-prime  fw-normal border-1  v-center h-center off-border  "
                      imageSrc={
                        location?.state?.data?.selectedBlockchain === "Coston"
                          ? sgb
                          : FLR
                      }
                      text={
                        location?.state?.data?.selectedBlockchain === "Coston"
                          ? "SGB"
                          : "FLR"
                      }
                      imageClassName="me-1"
                      onClick={() => { }}
                    />
                  </div>
                </div>
                {inputError && (
                  <p className="warning w-100 mt-1">Price is required *</p>
                )}
              </div>
            </div>

            {/* Listing Summary */}
            <div className="listing-Summary">
              <p className="body-large fw-bold mt-60 d-flex justify-content-between">
                Listing Summary
              </p>

              <div className="summary-box v-center flex-column mt-3">
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Listing type
                    <span>{selectSale}</span>
                  </p>
                </div>
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Listing price
                    <span>
                      {price}{" "}
                      {location?.state?.data?.selectedBlockchain === "Coston"
                        ? "SGB"
                        : "FLR"}
                    </span>
                  </p>
                </div>
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Royalties <span>5%</span>
                  </p>
                </div>
              </div>

              <div className="summary-box v-center flex-column mt-5">
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Catalyst fee
                    <span>15%</span>
                  </p>
                </div>
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between fw-bold ">
                    Total potential earnings
                    <span>
                      {totalEarning.toFixed(4)}{" "}
                      {location?.state?.data?.selectedBlockchain === "Coston"
                        ? "SGB"
                        : "FLR"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Create artwork button */}

            <Button
              text="List for sale"
              className={`btn-prime   br-30 font-18 mt-50 ${price < 1 || loading ? "btn-ternary" : "btn-primary"
                }`}
              height="50px"
              width="100%"
              disabled={price < 1 || loading}
              onClick={handleCreate}
            />
          </div>
        </div>
      </section>

      <Modal
        show={createFinish}
        onHide={handleModalClose}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              approve listing
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done approve-listing-modal v-center flex-column">
            <div className="listed-item ">
              <div className="left">
                {nftUriData && nftUriData?.image ? (
                  <img src={nftUriData.image} alt="img" className="border-0" />
                ) : (
                  <img
                    src={nftUriData.previewImg}
                    alt="img"
                    className="border-0"
                  />
                )}
              </div>

              <div className="right">
                <label className="larger fw-bold text-black no-text-transform">
                  {nftUriData?.artName}
                </label>
                <p className="body-medium">
                  {nftUriData?.selectedCollection} Collection #
                  {nftUriData?.selectedCollectionId}
                </p>
                <h6 className="no-text-transform font-normal fw-bold mt-2 pt-1">
                  {price}{" "}
                  {location?.state?.data?.selectedBlockchain === "Coston"
                    ? "SGB "
                    : "FLR "}
                  {/* <span className="ms-2 body-medium text-medium-grey fw-normal">
                    ($178.6)
                  </span> */}
                </h6>
              </div>
            </div>

            <label className="mt-4 pt-1 no-text-transform text-black">
              Final step…
            </label>
            <p className="body-medium mt-2 pb-4">
              Open your wallet to review and confirm this listing.
            </p>
            {isNewtworkConnected ? (
              <Button
                text="Open your wallet"
                className="btn-prime br-30 font-14 mt-50 btn-primary px-4"
                height="50px"
                width="170px"
                disabled={price <= 0 || loading}
                onClick={handleFinish}
              />
            ) : (
              <Button
                text={`Switch Network to ${location?.state?.data?.selectedBlockchain}`}
                className="btn-prime br-30 font-14 mt-50 btn-primary px-4"
                width="170px"
                height="50px"
                onClick={handleSwitchChain}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={createFinishSuccess}
        onHide={handleCreateFinishSuccess}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3">All Done</h5>

            <p className="body-medium">
              You listed <span className="fw-500">{nftUriData?.artName}</span>{" "}
              from{" "}
              <span className="fw-500">{nftUriData?.selectedCollection}</span>
            </p>

            {nftUriData && nftUriData?.image ? (
              <img src={nftUriData.image} alt="img" />
            ) : (
              <img src={nftUriData.previewImg} alt="img" />
            )}

            <Button
              text="View Listing"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={() => navigate("/explore")}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListforSale;
