import React from "react";
import imgg from "../assets/images/collectionPlaceholder.png";
import { useNavigate } from "react-router-dom";

const CollectionCard = ({ data, maxWidth, cursor = "pointer" }) => {
  console.log(data, "data");
  const navigate = useNavigate();

  const navigateToCreatArt = async (name, sym) => {
    navigate("/create-art", {
      state: { CollectionName: name, symbol: sym, data },
    });
  };
  return (
    <>
      <div
        className={`create-new-collection v-center mt-2 ${cursor}`}
        onClick={() => navigateToCreatArt(data.name, data.symbol)}
        style={{ maxWidth: maxWidth }}
      >
        <div className="left">
          {data?.image ? (
            <img src={data.image} alt="add" className="img-100" />
          ) : (
            <img src={imgg} alt="add" className="img-100" />
          )}
        </div>
        <div>
          <label className="large text-black no-text-transform fw-bold  ">
            {data?.name}
          </label>
          <br />
          <label className="small no-text-transform fw-500  ">
            {data?.symbol}
          </label>
        </div>
        <span className="small"></span>
      </div>
    </>
  );
};

export default CollectionCard;
