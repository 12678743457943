import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import flr from "../../assets/icon/FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import userProfile from "../../assets/icon/profile-picture.svg";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import ArtistPopUp from "../shared/artistpopup";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getAllUsers,
  getCollectionDetailsFirebase,
} from "../../firebase/firebase";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";

import { useAccount } from "wagmi";
import CardSkelton from "../shared/cardSkelton";
import Likes from "./likes";
import { Popover } from "antd";

const LikesGrid = (props) => {
  // contract address of marketplace

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;

  const [collectedArts, setCollectedArts] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { address: accountAddress } = useAccount();
  const [address, setAddress] = useState(accountAddress);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const updateAddressFromProps = () => {
    // in case of provile view as (if id set address to id)
    if (props.id) {
      setAddress(props.id);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  // get best offer price
  const fetchDataAndUpdateDetails = async () => {
    if (props?.listedNft) {
      let data = props?.listedNft.flat();
      for (let a of data) {
      }
      try {
        const web3 = new Web3(window.ethereum);
        const updatedDetails = await Promise.all(
          data
            .filter((item) => item?.isOfferListed)
            .map(async (item) => {
              const MarketplaceAddress =
                item?.data.selectedBlockchain === "Coston"
                  ? Marketplace_coston_contractAddress
                  : Marketplace_coston2_contractAddress;

              const contract = new web3.eth.Contract(
                marketplaceContractABI,
                MarketplaceAddress
              );
              try {
                const biddingHistoryCoston = await contract.methods
                  .getBiddingHistory(item?.listCount)
                  .call();
                if (biddingHistoryCoston && biddingHistoryCoston.length > 0) {
                  const maxPrice = calculateMaxPrice(biddingHistoryCoston);
                  return { ...item, maxPrice: maxPrice / 1e18 }; // Converting to ether
                }
              } catch (error) {
                console.error("Error fetching bidding history:", error);
                // Handle error fetching bidding history
              }
            })
        );
        setNftData((prevDetails) => {
          const unchangedItems = data.filter(
            (prevItem) => !prevItem.isOfferListed
          );
          const mergedDetails = [
            ...unchangedItems,
            ...updatedDetails.filter(Boolean),
          ];
          return mergedDetails;
        });
      } catch (error) {
        console.error("Error fetching data and updating details:", error);
        // Handle error fetching data and updating details
      }
    }
  };
  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (!isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };
  useEffect(() => {
    if (props?.listedNft) {
      setCollectedArts(props?.listedNft);
      const fetchdata = async () => {
        await fetchDataAndUpdateDetails();
      };
      fetchdata();
    }
  }, [props?.listedNft]);

  const navigate = useNavigate();

  // get nft of connected address

  const getUserNftsFromContract = async (
    contractAddress,
    Address,
    uri,
    selectedBlockchain
  ) => {
    let web3_1;
    if (selectedBlockchain === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (selectedBlockchain === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }
    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenIdsByCollection(Address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri == uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // navigate to single art

  const navigateTo = async (item) => {
    const result = await getCollectionDetailsFirebase(
      item?.data.selectedCollectionId
    );

    try {
      let TokenId = await getUserNftsFromContract(
        result?.contractAddress,
        item?.data.selectedCollectionId,
        item.uri,
        item?.data?.selectedBlockchain
      );
      let collectionId = item?.data.selectedCollectionId;
      let network = item?.data?.selectedBlockchain;
      let tokenId = TokenId;
      navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  const [userData, setUserData] = useState([]);

  // get user from localstorage

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("Curent user", user);
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (adr) => {

    console.log(props?.address,"address");
    let artistName = "";
    console.log("address", address);
    console.log(adr,"adr");
    console.log("current user", accountAddress);
    if (accountAddress === adr) {
      return "You";
    } else {
      console.log("users", userData);
      userData?.forEach((user) => {
        if (user?.id === adr) {
          artistName = user?.userName;
        }
      });
    }

    console.log("artist name", artistName);
    return  "@"+artistName;
  };

  // get artist image by address

  const getArtistImage = (accountAddress) => {
    let artistImage = userProfile;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = userProfile;
        }
      }
    });
    return artistImage;
  };

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        {props.loading ? (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
          </div>
        ) : (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            {nftData &&
              nftData.map((item, index) => {
                if (item?.listedData?.listed === false || item === undefined) {
                  return (
                    <>
                      {/* <CardSkelton />
                      <CardSkelton />
                      <CardSkelton />
                      <CardSkelton /> */}
                    </>
                  );
                } else {
                  return (
                    <div className="collection-grid-card" key={index}>
                      <div className="card-head  ">
                        <div className="user-img">
                          <img
                            src={getArtistImage(item?.data?.artistAddress)}
                            alt="profile image"
                            className="img-100"
                          />
                        </div>

                        <div onClick={() => navigateTo(item, "listed")} className="user-name">
                          <p className="body-large hover-underline pointer">
                            {item?.data?.artName || "Art Name"}
                          </p>

                          <Popover
                            placement="top"
                            trigger="hover"
                            content={
                              <>
                                <ArtistPopUp
                                  userProfile={getArtistImage(
                                    item?.data?.artistAddress
                                  )}
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.data?.artistAddress
                                  )} // passing artist as prop
                                />
                              </>
                            }
                          >
                            <p className="fw-bold text-medium-grey hoverBlack   ">
                              
                              {getArtistNamebyAdress(item?.data?.artistAddress)}
                              <img
                                src={verified}
                                alt="verified"
                                className="img-18 ms-1"
                              />
                              {/* artsit pop up here */}
                            </p>
                          </Popover>
                        </div>
                      </div>

                      <div className="card-body">
                        <div
                          className="art-img"
                          onClick={() => navigateTo(item, "listed")}
                        >
                          {item?.data?.image ? (
                            <img
                              src={item?.data?.image}
                              alt="art"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          ) :  (
                            <img
                              src={item?.data?.previewImage}
                              alt="Preview"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          )}
                        </div>
                        {item?.data?.selectedBlockchain === "Coston" ? (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={sgb} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={flr} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="card-footer">
                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              <ArtistPopUp
                                userProfile={userProfile}
                                verified={verified}
                                artistName={getArtistNamebyAdress(
                                  item?.data?.artistAddress
                                )}
                              />
                            </>
                          }
                        >
                          <div className="owner">
                            <p className="body-medium text-medium-grey ">
                              Owner
                            </p>
                            <label className="medium text-black">
                              <img src={profile} alt="profile" />
                              {getArtistNamebyAdress(item?.data?.artistAddress)}
                              {/* artsit pop up here */}
                            </label>
                          </div>
                        </Popover>
                        {/* <div className="offer">
                        <p className="body-large text-bold-black ">
                          Listed For Sale
                        </p>
                      </div> */}
                        <div className="offer">
                          {item?.data.lastPrice && (
                            <p className="body-medium text-medium-grey ">
                              {item?.isOfferListed
                                ? "Best Offer"
                                : "Last Sale Price"}
                            </p>
                          )}
                          <label className="medium text-black">
                            {item?.data.lastPrice/1000000000000000000 ||
                              item.maxPrice ||
                              "--"}
                          </label>
                        </div>
                        {/* <div className="offer">
                      <p className="body-medium text-medium-grey ">
                        Best Offer
                      </p>
                      <label className="medium text-black">
                        <img src={sgb} alt="profile" />
                        {item.offer}
                      </label>
                    </div> */}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>

      <div className="content-loader rotate-360 d-none">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};

export default LikesGrid;
