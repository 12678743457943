import React, { createContext, useContext, useState } from 'react';

const TagContext = createContext();

export const useTagContext = () => useContext(TagContext);

export const TagProvider = ({ children }) => {
    const [tagName, setTagName] = useState('');

    return (
        <TagContext.Provider value={{ tagName, setTagName }}>
            {children}
        </TagContext.Provider>
    );
};
