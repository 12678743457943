import React, { useState } from "react";
import { Avatar, List, Skeleton, Switch } from "antd";
import { DotChartOutlined } from "@ant-design/icons";
const CardSkelton = () => {
  
  const [active, setActive] = useState(true);
  const [block, setBlock] = useState(false);

  return (
    <>
      <div className="card-skelton">
        <div className="header">
          <div>
            <Skeleton.Avatar active={active} size="large" shape="circle" />
          </div>
          <div className="ms-3">
            <Skeleton.Input
              active={active}
              size={20}
              shape="square"
              block={true}
              className=" skelton"
            />
            <Skeleton.Button
              active={active}
              size={20}
              shape="square"
              block={true}
              className="skelton skelton2 "
            />
          </div>
        </div>

        <div className="body wrapper">
          <div className="image"></div>
        </div>
        <div className="header gap-5">
          <div className="d-flex flex-column gap-2">
            <Skeleton.Button
              active={active}
              size={20}
              shape="square"
              block={true}
              className="skelton1 skelton"
            />
            <Skeleton.Button
              active={active}
              size={20}
              shape="square"
              block={true}
              className="skelton2 skelton"
            />
          </div>
          <div className="divider"></div>
          <div className=" d-flex flex-column gap-2">
            <Skeleton.Button
              active={active}
              size={20}
              shape="square"
              block={true}
              className="skelton3 skelton"
            />
            <Skeleton.Button
              active={active}
              size={20}
              shape="square"
              block={true}
              className="skelton4 skelton"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSkelton;
