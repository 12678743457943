// ClearAllContext.js
import React, { createContext, useState } from 'react';

// Create the context
const ClearAllContext = createContext();

// Create a provider component
const ClearAllProvider = ({ children }) => {
    const [clearAll, setClearAll] = useState(false);

    return (
        <ClearAllContext.Provider value={{ clearAll, setClearAll }}>
            {children}
        </ClearAllContext.Provider>
    );
};

export { ClearAllContext, ClearAllProvider };
