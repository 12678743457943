import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
const AsideCollectionFilter = () => {
  const [selectCurrency, setSelectCurrency] = useState({
    allChains: false,
    flr: false,
    sgb: false,
    coston: false,
    coston2: false,
  });
  const handleCurrencyFilter = (tag) => {
    setSelectCurrency((prevSelectedTags) => ({
      ...prevSelectedTags,
      allChains: false, // Deselect all currencies
      flr: false,
      sgb: false,
      coston: false,
      coston2: false,
      [tag]: true, // Select the clicked currency
    }));
  };
  return (
    <>
      <div className="collection-multi-filter">
        <div className="multi-filter-options">
          <Accordion>
            {/* curreny filter */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Currency
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-filter collection-status-filter">
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.allChains ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("allChains")}
                  >
                    <img
                      src={selectCurrency.allChains ? checked : unCheck}
                      alt="checkbox"
                    />
                    All chains
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.flr ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("flr")}
                  >
                    <img
                      src={selectCurrency.flr ? checked : unCheck}
                      alt="checkbox"
                    />
                    FLR
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.sgb ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("sgb")}
                  >
                    <img
                      src={selectCurrency.sgb ? checked : unCheck}
                      alt="checkbox"
                    />
                    SGB
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.coston ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("coston")}
                  >
                    <img
                      src={selectCurrency.coston ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.coston2 ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("coston2")}
                  >
                    <img
                      src={selectCurrency.coston2 ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON2
                  </p>
                  {/* <p className="body-large h-64 fw-normal v-center cursor-pointer">
                      <img src={unCheck} alt="checkbox" />
                      SGB
                    </p> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default AsideCollectionFilter;
