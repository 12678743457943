import React, { useState, useEffect } from "react";
// import "./news.scss";
import "../style/main.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder from "../assets/images/profile-1.svg";

import news1 from "../assets/images/artwork-preview-1.png";
import news2 from "../assets/images/artwork-preview-2.png";
import news3 from "../assets/images/artwork-preview-5.png";
import news4 from "../assets/images/artwork-preview-6.png";
import { useNavigate } from "react-router-dom";
import ArtistPopUp from "./shared/artistpopup";
import userProfile from "../assets/images/face-3.png";
import verified from "../assets/icon/verified-artist-small.svg";
import { Popover, Skeleton } from "antd";
import {
  getCollectionHistory,
  getAllUsers,
  getCollectionByAddress,
} from "../firebase/firebase";
import Carousel from "react-bootstrap/Carousel";
import mintContractABI from "../abis/SafeMint/abi.json";
import marketplaceContractABI from "../abis/marketplace.json";

import { useAccount } from "wagmi";
import Web3 from "web3";
const LatestNews = () => {

  const Backend_url = process.env.REACT_APP_BACKEND_URL;
  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;

  // Setting for latest news slider

  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getNamebyAddress = (accountAddress) => {
    console.log(accountAddress);
    if (address === accountAddress) {
      return "You";
    }

    const user = userData?.find((user) => user?.id === accountAddress);
    const artistName = user ? user?.userName : "Not Found";

    console.log(accountAddress, artistName, "account address");
    return "@" + artistName;
  };

  // get artist image by address

  const getImage = (accountAddress) => {
    const user = userData?.find((user) => user?.id === accountAddress);
    const artistImage = user && user?.image ? user?.image : placeholder;

    return artistImage;
  };

  const [latestNews, setLatestNews] = useState([]);
  const navigate = useNavigate();

  // Define the convertToUnixTimestamp function
  const convertToUnixTimestamp = (timestamp) => {
    // If the timestamp is a JavaScript Date object
    if (timestamp instanceof Date) {
      return Math.floor(timestamp.getTime() / 1000);
    }

    // If the timestamp is a Firestore Timestamp object
    if (timestamp.seconds) {
      return timestamp.seconds;
    }

    // If the timestamp is in ISO string format
    return Math.floor(new Date(timestamp).getTime() / 1000);
  };

  useEffect(() => {
    const fetchCollectionHistory = async () => {
      setLoading(true);
      const collectionHistory = await getCollectionHistory();
      console.log(collectionHistory, "fetched collection history");

      // Convert timestamps to Unix format and sort by it
      const sortedCollectionHistory = collectionHistory
        .map((item) => ({
          ...item,
          unixTimestamp: convertToUnixTimestamp(item.timestamp),
        }))
        .sort((a, b) => b.unixTimestamp - a.unixTimestamp);

      // Get the first 10 items from the sorted list
      const firstTenItems = sortedCollectionHistory.slice(0, 10);
      console.log(firstTenItems, "first ten items");

      setLatestNews(firstTenItems);
      setLoading(false);
    };

    fetchCollectionHistory();
  }, []);

  useEffect(() => {
    console.log(latestNews, "lated news");
  }, [latestNews]);

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const providedDate = new Date(timestamp * 1000); // Convert seconds to milliseconds

    const timeDifference = currentDate - providedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (days < 30) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (months < 12) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  var settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 3000,
    fade: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };






  const navigateToArt = async (item) => {
    console.log(item);


    const web3_1 = new Web3(window.ethereum);

    // const accounts = await web3.eth.getAccounts();
    if (item.artworkUri && web3_1 != null) {
      let MarketplaceAddress;
      if (item?.artworkUri?.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (item?.artworkUri?.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3_1.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      console.log(contract.methods, "contract methods in getlisted function");
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "get listed nfts data-==============");



      data[1]?.forEach((nft, index) => {

        // Adding index to track offer listing position
        if (nft?.uriData === item?.uri) {
          console.log(nft);
          console.log(nft?.listedData?.tokenId);
          navigate(
            `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.artworkUri?.selectedCollectionId}/${nft?.listedData?.tokenId}`
          );

        }
      });


    } else {
      console.log("eror");
    }

  }


  const navigateToProfile = (account) => {
    if (account === address) {
      navigate(`/profile/${address}`);
    } else {
      navigate(`/profile/${account}`);
    }
  };
  return (
    <>
      {latestNews?.length > 0 && <section className="news  ">
        <h5 className="section-head">
          <span>latest</span>
          news
        </h5>
        <div className="news-slider">
          {loading ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ maxWidth: "600px" }}
            >
              <Skeleton.Avatar active size={557} shape="square" />

              <Skeleton.Input
                block={false}
                active
                round
                size="large"
                shape="button"
                className=" mt-2"
                style={{
                  width: "200px",
                  height: "25px",
                  margin: "auto",
                  borderRadius: "4px",
                }}
              />
            </div>
          ) : (
            <Carousel indicators={true} controls={true} touch={true} fade>
              {latestNews.map((item, index) => (
                <Carousel.Item>
                  <div key={index}>
                    <div className="news-card">
                      <div className="news-header pointer">
                        {item.action === "listedOffer" ? (
                          <img
                            onClick={() => {
                              if (item.action === "Mint") {
                                navigateToArt(item); // Call your function with item data
                              } else {
                                navigate(
                                  `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                );
                              }
                            }}
                            src={
                              item?.artworkUri?.metadata?.data.image ||
                              item?.artworkUri?.metadata?.data.previewImg
                            }
                            alt="card image"
                            className="img-100"
                          />
                        ) : item.action === "Mint" ? (
                          <img
                            onClick={() => {
                              if (item.action === "Mint") {
                                navigateToArt(item); // Call your function with item data
                              } else {
                                navigate(
                                  `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                );
                              }
                            }}
                            src={
                              item?.artworkUri?.image ||
                              item?.artworkUri?.previewImg
                            }
                            alt="card image"
                            className="img-100"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              if (item.action === "Mint") {
                                navigateToArt(item); // Call your function with item data
                              } else {
                                navigate(
                                  `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                );
                              }
                            }}
                            src={
                              item?.artworkUri?.image ||
                              item?.artworkUri?.previewImg
                            }
                            alt="card image"
                            className="img-100"
                          />
                        )}
                      </div>

                      <div className="news-body">
                        {console.log(item, "test item")}
                        <p>
                          {/* sold */}
                          {item?.action === "sold" && (
                            <>
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.to)}
                                    artistName={getNamebyAddress(item?.to)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.to);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >

                                  {(() => {
                                    const name = getNamebyAddress(item?.to);
                                    return name.length > 14
                                      ? name.substring(0, 14) + "..."
                                      : name;
                                  })()}
                                </span>
                              </Popover>{" "}
                              purchased{" "}
                              <span className="hover-underline pointer">
                                <span
                                  className="hover-underline pointer"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.metadata?.data.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName}
                                </span>
                              </span>{" "}
                              for ${item?.price / 1000000000000000000}
                            </>
                          )}

                          {/* mint */}
                          {item?.action === "Mint" && (
                            <>
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.from)}
                                    artistName={getNamebyAddress(item?.from)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.from);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >

                                  {(() => {
                                    const name = getNamebyAddress(item?.from);
                                    return name.length > 14
                                      ? name.substring(0, 14) + "..."
                                      : name;
                                  })()}
                                </span>
                              </Popover>{" "}
                              minted{" "}
                              <span
                                className="hover-underline pointer show-artist-popup"
                                onClick={() => {
                                  navigateToArt(item);
                                }}
                              >
                                {/* {item?.artworkUri?.artName.length > 14
                                  ? item?.artworkUri?.artName + "..."
                                  : item?.artworkUri?.artName} */}
                                {item?.artworkUri?.artName &&
                                  item.artworkUri.artName.length > 14
                                  ? item.artworkUri.artName.substring(0, 14) +
                                  "..."
                                  : item?.artworkUri?.artName}
                              </span>{" "}
                            </>
                          )}

                          {/* listed sale */}
                          {item?.action === "listedSale" && (
                            <>
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.from)}
                                    artistName={getNamebyAddress(item?.from)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.from);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >

                                  {(() => {
                                    const name = getNamebyAddress(item?.from);
                                    return name.length > 14
                                      ? name.substring(0, 14) + "..."
                                      : name;
                                  })()}
                                </span>
                              </Popover>{" "}
                              listed{" "}
                              <span
                                className="hover-underline pointer show-artist-popup"
                                onClick={() =>
                                  navigate(
                                    `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  )
                                }
                              >
                                {item?.artworkUri?.artName &&
                                  item.artworkUri.artName.length > 14
                                  ? item.artworkUri.artName.substring(0, 14) +
                                  "..."
                                  : item?.artworkUri?.artName}
                              </span>{" "}
                              for sale
                            </>
                          )}

                          {/* listed offer */}
                          {item?.action === "listedOffer" && (
                            <>
                              {console.log(item)}
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.from)}
                                    artistName={getNamebyAddress(item?.from)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  className="hover-underline pointer show-artist-popup"
                                  onClick={() => {
                                    navigateToProfile(item?.from);
                                  }}
                                >

                                  {(() => {
                                    const name = getNamebyAddress(item?.from);
                                    return name.length > 14
                                      ? name.substring(0, 14) + "..."
                                      : name;
                                  })()}
                                </span>
                              </Popover>{" "}
                              listed{" "}
                              <span
                                className="hover-underline pointer"
                                onClick={() =>
                                  navigate(
                                    `/artwork/${item?.artworkUri?.metadata?.data.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  )
                                }
                              >
                                {item?.artworkUri?.artName}
                              </span>
                              for offer
                            </>
                          )}

                          {/* offers */}
                          {item?.action === "offer" && (
                            <>
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.from)}
                                    artistName={getNamebyAddress(item?.from)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.from);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >
                                  {getNamebyAddress(item?.from)}
                                </span>
                              </Popover>{" "}
                              made a bid of {item?.price / 1000000000000000000}{" "}
                              on{" "}
                              <span
                                className="hover-underline pointer"
                                onClick={() =>
                                  navigate(
                                    `/artwork/${item?.artworkUri?.metadata?.data.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  )
                                }
                              >
                                {item?.artworkUri?.artName}
                              </span>
                            </>
                          )}

                          {/* transfer */}
                          {item?.action === "transfer" && (
                            <>
                              {console.log(item)}
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <ArtistPopUp
                                    userProfile={getImage(item?.from)}
                                    artistName={getNamebyAddress(item?.from)}
                                    verified={verified}
                                  />
                                }
                              >
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.from);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >

                                  {(() => {
                                    const name = getNamebyAddress(item?.from);
                                    return name.length > 14
                                      ? name.substring(0, 14) + "..."
                                      : name;
                                  })()}
                                </span>
                              </Popover>{" "}
                              transferred {item?.artworkUri?.artName}{" "}
                              <span
                                onClick={() => {
                                  navigateToProfile(item?.to);
                                }}
                                className="hover-underline pointer"
                              >
                                {item?.user}
                              </span>{" "}
                              to{" "}
                              <span
                                onClick={() => {
                                  navigateToProfile(item?.from);
                                }}
                                className="hover-underline pointer show-artist-popup"
                              >
                                {getNamebyAddress(item?.to)}
                              </span>
                            </>
                          )}
                        </p>
                        <label className="small">
                          {timeAgo(item?.timestamp?.seconds)}
                        </label>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </section>}
    </>
  );
};

export default LatestNews;
