import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import sad from "../../assets/icon/sad-face.svg";
import metamask from "../../assets/icon/metamask.svg";
import wallet from "../../assets/icon/wallet-connect.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import loaderLarge from "../../assets/icon/loader-large.svg";
import art1 from "../../assets/images/artwork-example-5.png";
import art2 from "../../assets/images/artwork-example-3.png";
import art3 from "../../assets/images/artwork-example-2.png";
import userProfile from "../../assets/images/face-5.png";
import userProfile1 from "../../assets/images/face-2.png";
import userProfile2 from "../../assets/images/face-1.png";
import userProfile3 from "../../assets/images/face.svg";
import wantToKnow from "../../assets/icon/question-tooltip.svg";
import { Modal, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import wFLR from "../../assets/icon/wrapped-FLR.svg";
import SGB from "../../assets/icon/wrapped-SGB.svg";
import sortby from "../../assets/icon/sort-by.svg";
import verified from "../../assets/icon/verified-artist-small.svg";
import dropdown from "../../assets/icon/chevron-down-extra-small.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import placeholder from "../../assets/images/profile-1.svg";

import Button from "../button";
import ArtistPopUp from "../shared/artistpopup";
import NoContent from "../noContent";
import Web3 from "web3";
import {
  handleNotifications,
  getImageByUsername,
  getAllUsers,
} from "../../firebase/firebase";
import { toast } from "react-toastify";
import { Popover } from "antd";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import { useNetwork } from "wagmi";
import { useAccount } from "wagmi";
import { switchNetwork } from "@wagmi/core";
import { useSwitchNetwork } from "wagmi";
import _ from "lodash";
import axios from "axios";

const Offers = (props) => {
  const { chain } = useNetwork();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();

  const { address } = useAccount();

  const [selectedUser, setSelectedUser] = useState(null);

  const tooltip = <Tooltip id="tooltip">List price: 4900929 FLR</Tooltip>;
  const tipDifference = (
    <Tooltip id="differ">
      The difference between your listed price and the offer
    </Tooltip>
  );
  const [show, setShow] = useState(false);
  // transaction process states
  const [offerAlert, setOfferAllert] = useState(true);
  const [offerApproveAlert, setApproveAlert] = useState(false);
  const [offerProcessAlert, setProcessAlert] = useState(false);
  const [offerConfirmAlert, setConfirmAlert] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [reload, setReload] = useState(false);
  // click handles
  const handleShow = (offer) => {
    setSelectedOffer(offer);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setOfferAllert(true);
    setApproveAlert(false);
    setProcessAlert(false);
    setConfirmAlert(false);
    setSelectedOffer(null);
  };
  // getting username from localhost
  let username = localStorage?.getItem("userName");

  const handleOffer = () => {
    handleAcceptOffer();
  };

  const Backend_url = process.env.REACT_APP_BACKEND_URL;

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;
  const [offers, setOffers] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  // Initialize web3 with your Ethereum node URL
  // const web3 = new Web3(window.ethereum);
  const web3_coston = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
  const web3_coston2 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);

  const getNftMetadata = async (tokens_uri) => {
    const response = await fetch(`${Backend_url}/getNFTMetadata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uri: tokens_uri,
      }),
    });
    const json = await response.json();
    return {
      data: json?.data?.data,
    };
  };

  const getImageByUser = async (username) => {
    let res = await getImageByUsername(username);
    console.log(res, "response");
    console.log(res);
    return res;
  };

  const getSelectedUsersForOffers = async (listCount, selectedBlockchain) => {
    const web3_1 = new Web3(window.ethereum);
    const accounts = await web3_1.eth.getAccounts();

    let MarketplaceAddress;
    if (selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    const result = await contract.methods.SelectedUser(listCount).call();
    return result;
  };
  const getListedNfts = async () => {
    const contract_coston = new web3_coston.eth.Contract(
      contractABI,
      Marketplace_coston_contractAddress
    );
    const contract_coston2 = new web3_coston2.eth.Contract(
      contractABI,
      Marketplace_coston2_contractAddress
    );

    const res_coston = await contract_coston.methods.getAllListedNfts().call();
    const res_coston2 = await contract_coston2.methods
      .getAllListedNfts()
      .call();

    // Fetch and append bidding history to each offer
    const appendBiddingHistory = async (contract, items) => {
      return await Promise.all(
        items.map(async (item) => {
          const biddingHistory = await contract.methods
            .getBiddingHistory(item?.listCount)
            .call();
          return { ...item, biddingHistory };
        })
      );
    };

    const updatedResCoston1 = await appendBiddingHistory(
      contract_coston,
      res_coston[1]
    );
    const updatedResCoston2 = await appendBiddingHistory(
      contract_coston2,
      res_coston2[1]
    );

    const offer_res = [...updatedResCoston1, ...updatedResCoston2];
    console.log(offer_res, "offer response");

    // Filter offers by address

    const filteredOffers = offer_res.filter((item) => {
      const owner = item?.listedData?.owner;
      console.log("Owner:", owner, "Address:", address);
      return owner === address;
    });

    console.log(filteredOffers, "filtered offer response");

    let biddingHistoryWithMetadata = [];

    for (let item of filteredOffers) {
      console.log(item, "item data in filtered offers");
      const { uriData, biddingHistory, listedData, listCount } = item;
      console.log(uriData);
      console.log(biddingHistory, "bidding history");
      console.log(listedData);
      console.log(listCount);

      // Fetch the metadata for the uriData
      const metadata = await getNftMetadata(uriData);
      console.log(metadata, "metadata...");

      // Fetch the image for each user in the bidding history
      let historyWithMetadata = await Promise.all(
        biddingHistory.map(async (bid) => {
          const userImage = await getImageByUser(bid.userName);
          return {
            ...bid,
            listCount: listCount,
            metadata: metadata.data,
            userImage: userImage,
            listedData: listedData, // Include listedData in the bidding history
          };
        })
      );

      console.log(historyWithMetadata, "historyWithMetadata");
      // Get selected users for offers

      const selectedBlockchain = metadata.data.selectedBlockchain; // Assuming listedData has blockchain info
      console.log(selectedBlockchain);
      const selectedUserResult = await getSelectedUsersForOffers(
        listCount,
        selectedBlockchain
      );

      console.log(selectedUserResult, "selectedUserResult");
      // Add 'selected' field if applicable and filter the bids
      let selectedBidCount = selectedUserResult > 0 ? selectedUserResult : null;
      if (selectedBidCount) {
        historyWithMetadata = historyWithMetadata.map((bid) => {
          console.log(bid);
          if (bid.bidCount === selectedBidCount) {
            return {
              ...bid,
              selected: true,
              selectedBidCount: selectedBidCount,
            };
          }
          if (bid.bidCount !== selectedBidCount) {
            return {
              ...bid,
              selected: false,
              selectedBidCount: selectedBidCount,
            };
          }

          return bid;
        });
        historyWithMetadata = historyWithMetadata.filter(
          (bid) => bid.selected || bid.bidCount !== selectedBidCount
        );
      }
      historyWithMetadata = historyWithMetadata.filter(
        (bid) => bid.selected || bid.bidCount !== selectedBidCount
      );

      // Add the processed bidding history to the final result
      biddingHistoryWithMetadata.push(...historyWithMetadata);
    }

    console.log(
      biddingHistoryWithMetadata,
      "bidding history with metadata and user image"
    );

    setOffers(biddingHistoryWithMetadata);
  };

  useEffect(() => {
    getListedNfts();
    setReload(false);
  }, [reload === true]);

  // ---------------------------------------
  const priceOptions = [
    { value: "currentOffer", label: "Offer" },
    { value: "offerDiffernce", label: "Difference" },
    { value: "offerDate", label: "Date" },
    { value: "offerFrom", label: "From" },
    { value: "offerAction", label: "Action" },
  ];

  const [activeOffer, setActiveOffer] = useState("currentOffer");
  const [activeOfferFilter, setActiveOfferFilter] = useState("All offers");
  const [priceShow, setPriceShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offerMobileFilter, setOfferMobileFilter] = useState(false);

  const handleIPriceTick = (tick) => {
    setActiveOffer(tick);
    setPriceShow(false);
  };
  const handleOfferTick = (tick) => {
    setActiveOfferFilter(tick);
    setOfferMobileFilter(false);
  };

  // Offer mobile Filter handle
  const offerHandleShow = () => setPriceShow(true);
  const offerHandleHide = () => setPriceShow(false);
  //------------------

  const offerFilter = [
    { value: "Offers made", label: "Offers made" },
    { value: "Offers received", label: "Offers received" },
    { value: "Expired offers", label: "Expired offers" },
    { value: "All offers", label: "All offers" },
  ];

  const showMobileSortFilter = () => {
    setOfferMobileFilter(true);
    setPriceShow(false);
  };
  const hideMobileSortFilter = () => {
    setOfferMobileFilter(false);
    setPriceShow(false);
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    if (unixTimestamp) {
      // Convert Unix timestamp to milliseconds
      const milliseconds = parseInt(unixTimestamp) * 1000;

      // Create a new Date object
      const date = new Date(milliseconds);

      // Get the day and month
      const day = date.toLocaleDateString("en-US", { weekday: "short" });
      const month = date.toLocaleDateString("en-US", { month: "short" });
      // const month = date.toLocaleDateString('en-US', { month: 'short' });

      // Return the formatted date string
      return `${day}, ${month},${date.getFullYear()}`;
    }
  }

  const handleAcceptOffer = async () => {
    const web3_1 = new Web3(window.ethereum);
    // alert(bidCount);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;

    console.log(
      selectedOffer?.metadata.selectedBlockchain,
      "Selected Blockcain.."
    );
    if (selectedOffer?.metadata.selectedBlockchain === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (selectedOffer?.metadata.selectedBlockchain === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    const bidCount = selectedOffer?.bidCount;
    const auctionListId = selectedOffer?.listCount;

    console.log(bidCount, "Bid count...");
    console.log(auctionListId, "auction list id..");
    try {
      setLoading(true);
      const transaction = contract.methods.selectUser(auctionListId, bidCount);
      const result = await transaction.send({
        from: accounts[0],
      });

      console.log(transaction);

      setReload(true);

      // await getOwner();
      let transactionHash = result?.transactionHash;

      // notification for accepting offer
      handleNotifications(
        selectedOffer.userName,
        `offerAccepted`,
        ` ${selectedOffer?.price / 1000000000000000000}`,
        selectedOffer?.metadata?.artName,
        transactionHash,
        selectedOffer?.metadata.selectedBlockchain
      );
      setOfferAllert(false);
      setApproveAlert(true);

      setTimeout(() => {
        setApproveAlert(false);
        setProcessAlert(true);
      }, 2500);

      setTimeout(() => {
        setApproveAlert(false);
        setProcessAlert(false);
        setConfirmAlert(true);
      }, 7000);
      setLoading(false);
    } catch (error) {
      toast.error("Transaction Failed!");
      setLoading(false);
    }
  };
  // const handleSwitchChain = async () => {
  //   const switchTo = chains.find(
  //     (c) => c.name === nftData[0]?.metadata.data.selectedBlockchain
  //   );
  //   if (switchTo) {
  //     switchNetwork?.(switchTo.id);
  //   }
  // };

  const handleSwitchChain = async (item) => {
    console.log(item, "item........");
    const switchTo = chains.find(
      (c) => c.name === item?.metadata?.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };
  useEffect(() => {
    console.log(offers, "offer........");
  }, [offers]);
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        console.log(priceInUSD);
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=dce586e2c0645c68f731ede7370366f5a62b3475b9265d37b35d4cff562ad87b"
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        console.log(priceInUSD);
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = () => {
    if (address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;
      console.log(artistName);

      console.log(props?.id, artistName, "account address");
      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        return "@" + artistName + " hasn't ";
      }
    }
  };

  return (
    <div>
      <div className="offers pb-5">
        {offers.length < 1 && (
          <div className="no-content pt-5 mt-5">
            <p className="body-large">
              {getNamebyAddress()} received <span>offers </span>
              yet.
            </p>
          </div>
        )}
        {offers.length > 0 && (
          <div className="offer-table">
            {/* Sorting filter for mobile */}
            {/* 
            <div
              className="ofr-recieved  d-flex justify-content-end align-items-center
          filter hide-on-desktop"
            >
              <p className="body-medium " onClick={showMobileSortFilter}>
                <span className="me-1">
                  {
                    offerFilter.find(
                      (option) => option.value === activeOfferFilter
                    )?.label
                  }
                </span>

                <img src={sortby} alt="more" />
              </p>
            </div> */}

            {/* Sorting filter for destop */}
            {/* <div
              className="ofr-recieved d-flex justify-content-end align-items-center
          filter dropdown hide-on-mobile
          "
            >
              <p
                className="body-medium dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="me-1">
                  {
                    offerFilter.find(
                      (option) => option.value === activeOfferFilter
                    )?.label
                  }
                </span>

                <img src={sortby} alt="more" />
              </p>
              <ul className="dropdown-menu">
                {offers.map((option) => {
                  return (

                    <li
                      className="dropdown-item"
                      key={option.value}
                      onClick={() => handleOfferTick(option.value)}
                    >
                      <img
                        src={tick}
                        alt="tick"
                        className={`${activeOfferFilter === option.value
                          ? "active opacity-100"
                          : "opacity-0"
                          }`}
                      />
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div> */}

            <div className="offer-table-content">
              {/* table heading */}

              <div className="offer-table-head">
                <label className="small v-center c1 ">ITEM</label>
                <label className="small v-center c2 hide-on-mobile">
                  offer
                </label>

                {/* <OverlayTrigger
                  placement="top"
                  overlay={tipDifference}
                  id="differ"
                >
                  <label
                    className="small v-center c3 hide-on-mobile "
                    id="differ"
                  >
                    difference{" "}
                    <img
                      src={wantToKnow}
                      alt="question"
                      className="opacity-50"
                    />
                  </label>
                </OverlayTrigger> */}
                <label className="small v-center  c4 hide-on-mobile">
                  date
                </label>
                <label className="small  v-center c5 hide-on-mobile">
                  from
                </label>
                <label className="small v-center  c6 hide-on-mobile"></label>

                <label
                  className="small  text-black  v-center  hide-on-desktop"
                  onClick={offerHandleShow}
                >
                  {
                    priceOptions.find((option) => option.value === activeOffer)
                      ?.label
                  }

                  <img src={dropdown} alt="dropdown" className="ms-1" />
                </label>
              </div>

              <div className="offer-table-body ">
                {offers.map((item, index) => {
                  return (
                    <>
                      {item.selected !== false && (
                        <div className={`offer-table-content `} key={index}>
                          {/* Item Name */}
                          <div className="c1 art">
                            <div className="art-img">
                              {item?.metadata?.image ? (
                                <img
                                  src={item?.metadata?.image}
                                  alt="art"
                                  className="img-100"
                                />
                              ) : (
                                <img
                                  src={item?.metadata?.previewImg}
                                  alt="art"
                                  className="img-100"
                                />
                              )}
                            </div>
                            <label className="text-black ">
                              {item?.metadata?.artName}
                            </label>
                          </div>

                          {/* Offers */}
                          <div
                            className={`c2 offer d-flex align-items-center
                      ${activeOffer !== "currentOffer" && "item-hide"}
                      `}
                          >
                            {item?.metadata?.selectedBlockchain === "Coston" ? (
                              <img
                                src={wFLR}
                                alt="wFLR"
                                className="img-fluid "
                              />
                            ) : (
                              <img
                                src={SGB}
                                alt="wFLR"
                                className="img-fluid "
                              />
                            )}
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip}
                              id="tooltip"
                            >
                              <label className=" text-black">
                                {item?.price / 1000000000000000000}
                              </label>
                            </OverlayTrigger>
                            <p>
                              {item?.metadata?.selectedBlockchain === "Coston"
                                ? "SGB"
                                : "FLR"}
                            </p>
                          </div>

                          {/* Date */}
                          <div
                            className={`v-center c4  ${
                              activeOffer !== "offerDate" && " item-hide"
                            } `}
                          >
                            <label className="text-black text-capitalize">
                              {convertUnixTimestampToDate(item.biddingTime)}
                            </label>
                          </div>

                          {/*Offer from  */}

                          <div
                            className={`v-center c5 fromOffer  ${
                              activeOffer !== "offerFrom" && " item-hide"
                            } `}
                          >
                            <div className="art-img">
                              <img
                                src={item?.userImage || placeholder}
                                alt="art"
                                className="img-100 rounded-circle"
                              />
                            </div>

                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <>
                                  <ArtistPopUp
                                    userProfile={item?.userImage}
                                    verified={verified}
                                    artistName={item?.userName}
                                  />
                                </>
                              }
                            >
                              <p className="text-black fw-semibold cursor-pointer text-lowercase  ">
                                {item?.userName}
                              </p>
                            </Popover>
                          </div>

                          {/* Offer Status */}
                          {/* <div className="v-center c6 item-hide"> */}

                          <div className={`v-center c6  `}>
                            {item?.metadata?.selectedBlockchain ===
                            chain.name ? (
                              <Button
                                text={
                                  item.selected === true ? "Pending" : "Accept"
                                }
                                className={
                                  item.selected !== true
                                    ? "btn-prime btn-primary"
                                    : "btn-prime btn-ternary "
                                }
                                width="121px"
                                height="36px"
                                disabled={item.selected === true}
                                onClick={() => handleShow(item)}
                              />
                            ) : (
                              <Button
                                text={`Switch to ${item?.metadata?.selectedBlockchain}`}
                                className={"btn-prime btn-primary"}
                                width="121px"
                                height="36px"
                                onClick={() => handleSwitchChain(item)}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        className="offer-modal "
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* offer-accept-alert */}
            {offerAlert ? <label className="medium ">ACCEPT</label> : <></>}

            {/* While approving offer alert */}
            {offerApproveAlert ? (
              <label className="medium">approve</label>
            ) : (
              <></>
            )}

            {/* While Processing... offer alert */}
            {offerProcessAlert ? (
              <label className="medium ">Processing...</label>
            ) : (
              <></>
            )}

            {/* When offer is confirmed */}
            {offerConfirmAlert ? (
              <label className="medium ">confirmed</label>
            ) : (
              <></>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* offer-accept-alert */}
          {offerAlert ? (
            <div className="accept-alert ">
              <div className="offer-modal-top">
                <div className="labels d-flex justify-content-between">
                  <label className="medium text-black ">item</label>
                  <label className="medium text-black ">subtotal</label>
                </div>

                <div className="d-flex justify-content-between align-items-center top-content">
                  <div className="collections d-flex align-items-center">
                    <div className="collection-img me-3">
                      <img
                        src={selectedOffer?.metadata?.image}
                        alt="collection"
                        className="img-100"
                      />
                    </div>
                    <div className="collection-name d-flex flex-column">
                      <label className="">
                        {selectedOffer?.metadata?.selectedCollection}
                      </label>
                      <label className="text-black">
                        {selectedOffer?.metadata?.artName}
                      </label>
                    </div>
                  </div>

                  <div className="value-wrapper">
                    <label htmlFor="" className="text-black">
                      {selectedOffer?.metadata?.selectedBlockchain ===
                      "Coston" ? (
                        <img src={wFLR} alt="wFLR" className="img-fluid " />
                      ) : (
                        <img src={SGB} alt="wFLR" className="img-fluid " />
                      )}
                      {selectedOffer?.price / 1000000000000000000}
                    </label>
                    <p className="body-medium mt-1 text-medium-grey">
                      {selectedOffer?.metadata?.selectedBlockchain === "Coston"
                        ? `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            sgb
                          ).toFixed(2)})`
                        : `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            flr
                          ).toFixed(2)})`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="offer-fee">
                <label className="medium text-black ">fees</label>
                <div className="fee">
                  <label className="medium text-light-grey text-capitalize w-100">
                    Catalyst Fee
                    <img src={wantToKnow} alt="question" />
                    <div className="divider"></div>
                  </label>
                  <label className="medium text-light-grey">5%</label>
                </div>
                <div className="fee">
                  <label className="medium text-light-grey text-capitalize w-100">
                    Creator Royalty
                    <img src={wantToKnow} alt="question" />
                    <div className="divider2"></div>
                  </label>
                  <label className="medium text-light-grey">10.0%</label>
                </div>
              </div>
              <div className="offer-modal-bottom">
                <label className="text-black">Total Earnings</label>
                <p className="body-extra-small text-light-grey">
                  Your earnings after fees
                </p>
                <div className="value-wrapper">
                  {selectedOffer?.metadata?.selectedBlockchain === "Coston" ? (
                    <img src={wFLR} alt="wFLR" className="img-fluid " />
                  ) : (
                    <img src={SGB} alt="wFLR" className="img-fluid " />
                  )}{" "}
                  <label htmlFor="" className="text-black">
                    {selectedOffer?.price / 1000000000000000000}
                  </label>
                  <p className="body-medium mt-1 text-medium-grey">
                    <p className="body-medium mt-1 text-medium-grey">
                      {selectedOffer?.metadata?.selectedBlockchain === "Coston"
                        ? `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            sgb
                          ).toFixed(2)})`
                        : `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            flr
                          ).toFixed(2)})`}
                    </p>
                  </p>
                </div>
                <div className="accept-offer">
                  <Button
                    text="Accept offer"
                    className="btn-prime btn-primary"
                    width="146px"
                    height="36px"
                    loading={loading}
                    onClick={handleOffer}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* While approving offer alert */}

          {/* ------------------------------------------------------------------------- */}

          {/* This modal should only show if this hasn’t already been confirmed earlier? 
          Need to check with developers how this logic should work and when these approvals 
          should happen. */}

          {/* ------------------------------------------------------------------------- */}

          {offerApproveAlert ? (
            <div className="offer-approve-alert">
              <label htmlFor="" className="medium text-black">
                Approve your WFLR for transfer
              </label>
              <p className="approve-offer-details body-medium">
                To approve The Catalyst to trade this token, you must first
                complete a free (plus gas) transaction. Confirm it in your
                wallet and keep this tab open. You might notice a very large
                number being requested for approval - this is simply the maximum
                amount, meaning you’ll never have to do this approval again. It
                also doesn’t allow The Catalyst to transfer that amount to you -
                the amount you sign in the next step is all that can be traded
                on your behalf.
                <span className="text-medium-grey "> Read more</span>
                <a href="" className="body-medium text-link-blue">
                  {" "}
                  here.
                </a>
              </p>
              <hr />
              <div className="approve-wallets">
                <img src={metamask} alt="metamask" />
                <img src={wallet} alt="wallet" />
              </div>
              <label className="text-black w-100 text-center">
                Waiting for blockchain confirmation…
              </label>
              <Button
                className="btn-prime btn-primary m-auto loader-btn  "
                width="138px"
                height="36px"
                imageSrc={loader}
              />
            </div>
          ) : (
            <></>
          )}

          {/* When offer is in process */}
          {offerProcessAlert ? (
            <div className="offer-processing-alert ">
              <div></div>
              <div className="text-center">
                <img src={loaderLarge} alt="loader" className="rotate-360 " />
              </div>
              <div>
                <p className="text-medium0grey body-medium text-center">
                  Please wait…
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* When offer is confirmed */}
          {offerConfirmAlert ? (
            <div className="offer-confirmed ">
              <p className="text-center">
                Success! You just sold{" "}
                <label className="text-black">
                  {" "}
                  {selectedOffer?.metadata?.artName}.
                </label>
              </p>
              <div className="artwork-img">
                <img
                  src={selectedOffer?.metadata?.image}
                  alt="artwork"
                  className="img-100"
                />
              </div>
              <div className="transaction">
                <p className="body-medium text-center text-link-blue  text-decoration-underline fw-500">
                  View transaction on the blockchain
                </p>
                <p className="body-small text-center text-link-blue fw-500 wallet-address">
                  0x566c…0687
                </p>
                <Button
                  text="Finished"
                  className="btn-prime btn-primary "
                  width="146px"
                  height="36px"
                  onClick={handleClose}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      {/* List Price menu */}
      <Offcanvas
        show={priceShow}
        onHide={offerHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={offerHandleHide}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no - text - transform h - 64 text - black ${
                  activeOffer === option.value ? "fw-bold" : "fw-normal"
                } `}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${
                    activeOffer === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                  } `}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>

      {/* Offer Sorting Filter mobile menu */}
      <Offcanvas
        show={offerMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {offerFilter.map((option) => (
              <label
                key={option.value}
                className={`no - text - transform h - 64 text - black ${
                  activeOfferFilter === option.value ? "fw-bold" : "fw-normal"
                } `}
                onClick={() => handleOfferTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${
                    activeOfferFilter === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                  } `}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default Offers;
