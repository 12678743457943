import sad from "../../assets/icon/sad-face.svg";
import Button from "../button";

import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import { useNavigate } from "react-router-dom";
import {
  getCollectionDetailsFirebase,
  getCollections,
  getArtLastPriceFromFirebase,
  getAllUsers,
} from "../../firebase/firebase";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintABI from "../../abis/SafeMint/v2/abi.json";
import Filter from "../../assets/icon/filter.svg";
import grid from "../../assets/icon/display-grid-active.svg";
import row from "../../assets/icon/display-row-active.svg";
import artwork from "../../assets/icon/display-artwork-active.svg";
import sortby from "../../assets/icon/sort-by.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import { NFTContext } from "../context/appContext";

import UserCollectedArtwork from "./userCollectedArtwork";
import UserCollectedRow from "./userCollectedRow";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { Offcanvas } from "react-bootstrap";
import Web3 from "web3";
import { useAccount, useConnect } from "wagmi";
import { ClearAllContext } from "../explore/ClearAllContext";

import UserCollectionGrid from "./userCollectionGrid";

const CreatedArtwork = (props) => {
  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const [hide, setHide] = useState(false);
  const { isChange, artModal, udpateArtModal } = useContext(NFTContext);
  const [filter, setFilter] = useState(true);
  const { address: user } = useAccount();
  const { createdArtworks, updateCreated } = useContext(NFTContext);

  const clearFilter = () => {
    setClearAll(true);
  };
  const [activeLayout, setactiveLayout] = useState("grid");
  const [mobileLayout, setMobileLayout] = useState(false);

  // multi filters

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  //  handle mobile filters
  const handleClose = () => setMobileLayout(false);
  const handleShow = () => setMobileLayout(true);

  // layout tabs handles

  const handleActiveLayout = (image) => {
    setactiveLayout(image);
  };

  // let layout handle the icon of currently selected layout
  let layout;
  if (activeLayout == "grid") {
    layout = grid;
  } else if (activeLayout == "row") {
    layout = row;
  } else if (activeLayout == "artwork") {
    layout = artwork;
  }

  const [activeCollection, setActiveCollection] = useState("Recently Listed");

  const handleIPriceTick = (tick) => {
    setActiveCollection(tick);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  const closeMobileSideFilter = () => setShowSideFilter(false);

  // mobile sort filter
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollectionFilter, setActiveCollectionFilter] =
    useState("Recently Listed");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };
  const handleCollectionTick = (tick) => {
    setActiveCollectionFilter(tick);
    setCollectionMobileFilter(false);
  };
  const Backend_url = process.env.REACT_APP_BACKEND_URL;

  // const { address: accountAddress } = useAccount();
  const [accountAddress, setAccountAddress] = useState();
  useEffect(() => {
    console.log(props?.address, "445455");
    setAccountAddress(props?.address);
  }, [props]);
  const [address, setAddress] = useState(props?.address);
  const [nfts, setNfts] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [collections, setCollections] = useState([]);
  const [web3, setWeb3] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [nftMetadata, setNftMetadata] = useState([]);
  const [owner, setOwner] = useState(false);

  const updateAddressFromProps = () => {
    console.log(props, "propf..........");
    if (props?.id) {
      // alert("id addded");

      setAddress(props?.id);
      setOwner(false);
    } else {
      setAddress(accountAddress);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  //  marketplace contract address

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  const [listedNfts, setListedNfts] = useState([]);

  // check nft owner by address

  const checkOwner = async () => {
    const owner = collectionDetails?.address;
    if (address === owner) {
      setIsOwner(true);
    } else {
    }
  };

  useEffect(() => {
    if (collectionDetails) {
      checkOwner();
    }
  }, [collectionDetails]);

  const navigate = useNavigate();
  // getting collections from firebase

  let wallet = localStorage?.getItem("wagmi.store");
  wallet = JSON.parse(wallet);

  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      // Filter user collections where userAddress doesn't match with the provided address
      const filteredCollections = usercollections.filter(
        (collection) => collection.address !== address
      );

      setCollections(filteredCollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };

  useEffect(() => {
    getUserCollections();
  }, [address]);

  const getAllListNFTData = async (selectedNetwork, web3) => {
    // const accounts = await web3.eth.getAccounts();
    if (collectionDetails && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // alert("in listed");
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "data in listred art");
      if (data.length > 0) {
        setFilter(true);
      }
      if (data?.length === 0) {
        setFilter(false);
      }
      setListedNfts(data);
      return data;
    } else {
      // setFilter(true);
      setListedNfts([]);
    }
  };

  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork) => {
    let web;
    if (selectedNetwork == "Coston2") {
      web = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    } else {
      web = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    }
    let nftListDetails = [];

    if (web) {
      const contract = new web.eth.Contract(mintABI, contractAddress);
      const nftData = await contract.methods.getTokenId(address).call();
      console.log(nftData, "nft Data");

      let listedNfts = await getAllListNFTData(selectedNetwork, web);
      console.log(listedNfts, "listed nfts");
      nftData?.map((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });
    }
    console.log(nftListDetails, "nft list details");
    return nftListDetails;
  };

  // get nft meta data
  const getNftMetadata = async (tokens_uri, network) => {
    // Reset or initialize state variables
    setNfts([]);
    const nftMetadata = [];

    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri; // Extract URI from the current token
        const response = await fetch(`${Backend_url}/getNFTMetadata`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uri: uri,
          }),
        });
        const json = await response.json();

        // Check if the data object is already in nftMetadata array
        const existingIndex = nftMetadata.findIndex((item) => item.uri === uri);
        if (existingIndex === -1 && json.data && json.data.data) {
          // Push the data object into the nftMetadata array along with the original token
          nftMetadata.push({
            data: token,
            metadata: {
              uri: uri,
              data: json.data.data,
            },
          });
        }
      })
    );

    console.log(nftMetadata, "metadata");
    console.log(address, "address");

    const filteredMetadata = nftMetadata.filter((item) => {
      const artistAddress = item.metadata.data.artistAddress.toLowerCase();
      console.log(address, "address");
      console.log(
        "Artist Address:",
        artistAddress,
        "Address:",
        address.toLowerCase()
      );
      return artistAddress === address.toLowerCase();
    });

    filteredMetadata.map(async (e) => {
      console.log(e, "test");
      console.log(e?.data.uriData, "id0-------f0as-d");
      let lastPrice = await getArtLastPriceFromFirebase(e?.data.uriData);

      if (lastPrice !== null) {
        e.metadata.data.lastPrice = lastPrice;
        console.log(`Updated element with last price: `, e);
      } else {
        e.metadata.data.lastPrice = 0;
        console.log(`No last price found for artId`);
      }
    });

    console.log(filteredMetadata);
    if (filteredMetadata.length === 0) {
      setFilter(false);
    }
    console.log(filteredMetadata, "filtered metadata");

    // Update the state with the metadata and original tokens
    setItemsNumber(filteredMetadata?.length);
    updateCreated(filteredMetadata?.length);
    setNftFilteredDetails(filteredMetadata);
    setNfts(filteredMetadata);
    // setLoading(false);
  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {
    let contractDataArray = [];

    for (const item of collections) {
      let data = await fetchDataForContract(
        item?.data.contractAddress,
        item?.data.selectedNetwork
      );
      if (data?.length > 0) {
        contractDataArray.push(data);
      }
    }

    // Flatten the contractDataArray into a single array
    const flattenedTokens = contractDataArray.flat();
    console.log(flattenedTokens, "flattens tokens");
    setLoading(false);

    // Pass the flattened array to getNftMetadata
    getNftMetadata(flattenedTokens);
    if (collections.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    } else {
      // setLoading(false);
    }
  }, [collections]);

  const getCollectionDetails = async () => {
    try {
      const result = await getCollectionDetailsFirebase();
      setCollectionDetails(result);
    } catch (error) {
      console.log("Error fetching collection details:", error);
    }
  };

  useEffect(() => {
    // check wallet is connect or not
    if (window.ethereum) {
      window.ethereum.enable();
    } else {
      alert("Please install MetaMask to use this dApp!");
    }

    getCollectionDetails();
  }, []);

  useEffect(() => {
    console.log(loading, "loading");
  }, [loading]);

  // Filters
  // Filters
  const collectionSortFilter = [
    { value: "Lowest price", label: "Lowest price" },
    { value: "Highest price", label: "Highest price" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  // on filter change
  const handleSelectedFilterChange = (newFilter) => {
    setFilter(true);
    let filteredData = [];

    if (newFilter.selectStatus.buyNow) {
      const ListedNfts = nfts.filter((item) => item.data.isSaleListed === true);

      ListedNfts.filter((item) => {
        const isCategorySelected = Object.values(
          newFilter.selectCategories
        ).some((category) => category);
        let categoriesFilter;
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item.metadata.data.selectedTags.includes(category)
                : true
          );
        } else {
          categoriesFilter = true;
        }

        const selectedCurrency = Object.values(newFilter.selectCurrency).some(
          (currency) => currency
        );
        let currencyFilter = true;

        if (selectedCurrency) {
          currencyFilter =
            selectedCurrency &&
            (newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "flare network") ||
              (newFilter.selectCurrency.sgb &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "songbird network") ||
              (newFilter.selectCurrency.coston &&
                item.data.selectedBlockchain.toLowerCase() === "coston") ||
              (newFilter.selectCurrency.coston2 &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "coston2"));
        }

        // Filter by price
        let priceFilter;
        if (
          newFilter.selectPrice.min === "" &&
          newFilter.selectPrice.max === ""
        ) {
          priceFilter = true;
        } else {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.data.listedData.price) / 1000000000000000000 >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.data.listedData.price) / 1000000000000000000 <=
              parseFloat(newFilter.selectPrice.max));
        }

        if (categoriesFilter && currencyFilter && priceFilter) {
          filteredData.push(item);
        }
      });
    } else if (newFilter.selectStatus.onOffer) {
      const offerNftData = nfts.filter(
        (item) => item.data.isOfferListed === true
      );

      offerNftData.filter((item) => {
        // Filter by categories
        // Check if any category is selected
        const isCategorySelected = Object.values(
          newFilter.selectCategories
        ).some((category) => category);
        let categoriesFilter;
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item.metadata.data.selectedTags.includes(category)
                : true
          );
        } else {
          categoriesFilter = true;
        }

        const selectedCurrency = Object.values(newFilter.selectCurrency).some(
          (currency) => currency
        );
        let currencyFilter = true;

        if (selectedCurrency) {
          currencyFilter =
            selectedCurrency &&
            (newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr &&
                item.metadata.data.selectedBlockchain?.toLowerCase() ===
                "flare network") ||
              (newFilter.selectCurrency.sgb &&
                item.metadata.data.selectedBlockchain?.toLowerCase() ===
                "songbird network") ||
              (newFilter.selectCurrency.coston &&
                item.data.selectedBlockchain?.toLowerCase() === "coston") ||
              (newFilter.selectCurrency.coston2 &&
                item.metadata.data.selectedBlockchain?.toLowerCase() ===
                "coston2"));
        }

        // Filter by price
        let priceFilter;
        if (
          newFilter.selectPrice.min === "" &&
          newFilter.selectPrice.max === ""
        ) {
          priceFilter = true;
        } else {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.data.listedData.minimumBid) >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.data.listedData.minimumBid) <=
              parseFloat(newFilter.selectPrice.max));
        }

        // Return item if all filters pass
        if (categoriesFilter && currencyFilter && priceFilter) {
          filteredData.push(item);
        }
      });
    } else {
      nfts.filter((item) => {
        // Filter by categories
        // Check if any category is selected
        const isCategorySelected = Object.values(
          newFilter.selectCategories
        ).some((category) => category);
        let categoriesFilter;
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item.metadata.data.selectedTags.includes(category)
                : true
          );
        } else {
          categoriesFilter = true;
        }

        const selectedCurrency = Object.values(newFilter.selectCurrency).some(
          (currency) => currency
        );
        let currencyFilter = true;

        if (selectedCurrency) {
          currencyFilter =
            selectedCurrency &&
            (newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "flare network") ||
              (newFilter.selectCurrency.sgb &&
                item.data.selectedBlockchain.toLowerCase() ===
                "songbird network") ||
              (newFilter.selectCurrency.coston &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "coston") ||
              (newFilter.selectCurrency.coston2 &&
                item.metadata.data.selectedBlockchain.toLowerCase() ===
                "coston2"));
        }

        // Filter by price
        let priceFilter;
        if (
          newFilter.selectPrice.min === "" &&
          newFilter.selectPrice.max === ""
        ) {
          priceFilter = true;
        } else {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.data.listedData?.price) / 10000000000000000 >=
              parseFloat(newFilter.selectPrice?.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.data.listedData?.price) / 10000000000000000 <=
              parseFloat(newFilter.selectPrice.max));
        }

        // Return item if all filters pass
        if (categoriesFilter && currencyFilter && priceFilter) {
          filteredData.push(item);
        }
      });
    }
    setItemsNumber(filteredData?.length);

    setNftFilteredDetails(filteredData);
  };

  // Filters  (higest price, lowest price, recently listed)
  useEffect(() => {
    console.log(nfts, "nft data");
    if (nfts) {
      // Sorting by highest price
      if (activeCollection === "Highest price") {
        const sortByHighestPrice = [...nfts].sort((a, b) => {
          const aListed = a?.data && a?.data.listedData;
          const bListed = b?.data && b?.data.listedData;

          if (aListed && bListed) {
            return b.data.listedData.price - a.data.listedData.price; // Both listed, compare prices
          }
          if (bListed) {
            return 1; // b is listed, a is not, place a after b
          }
          if (aListed) {
            return -1; // a is listed, b is not, place b after a
          }
          return 0; // Both unlisted, keep their order
        });
        setNftFilteredDetails(sortByHighestPrice);
      }

      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const sortByLowestPrice = [...nfts].sort((a, b) => {
          if (a?.data && a?.data.listedData) {
            if (b?.data && b?.data.listedData) {
              return a.data.listedData.price - b.data.listedData.price;
            }
            return -1; // b is unlisted, place it after a
          }
          return 1; // a is unlisted, place it after b
        });
        console.log(sortByLowestPrice, "sort by lowest");
        setNftFilteredDetails(sortByLowestPrice);
      }

      // Getting the first 5 recently listed items
      else if (activeCollection === "Newest") {
        const recentlyAdded = [...nfts].sort((a, b) => {
          return b?.data
            ? b?.data?.mintTime -
            (a?.data && a?.data.mintTime ? a?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);
        console.log("Newest", recentlyAdded);
        setNftFilteredDetails(recentlyAdded);
      } else if (activeCollection === "Oldest") {
        const oldestAdded = [...nfts].sort((a, b) => {
          return a?.data
            ? a?.data?.mintTime -
            (b?.data && b?.data.mintTime ? b?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);

        console.log("Oldest", oldestAdded);
        setNftFilteredDetails(oldestAdded);
      }
    }
    // setLoading(false);
  }, [nfts, activeCollection]);

  const createArtwork = () => {
    navigate("/create-art/");
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = () => {
    console.log(props?.id, "543555555555");
    if (props?.address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;
      console.log(artistName);

      console.log(props?.id, artistName, "account address");
      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        return "@" + artistName + " hasn't ";
      }
    }
  };
  useEffect(() => {
    console.log(nftFilteredDetails, "nftFilteredDetails");
  }, [nftFilteredDetails]);

  useEffect(() => {
    console.log(filter, "filter");
  }, [filter]);
  return (
    <>
      {!loading && nftFilteredDetails?.length == 0 && !filter ? (
        <div className="no-content mt-5 pt-5">
          <p className="body-large">
            {getNamebyAddress().length > 18
              ? getNamebyAddress().substring(0, 18) + "..."
              : getNamebyAddress()}{" "}
            <span>created </span>
            any artwork yet.
          </p>
          <Button
            text="Create artwork"
            width="166px"
            height="36px"
            className="btn-prime btn-primary"
            onClick={() => {
              navigate("/create");
            }}
          />
        </div>
      ) : (
        <div>
          <div className="collected-arts ">
            <div className="collection-filter">
              <div className="left">
                <div
                  className="show-filter cursor-pointer "
                  onClick={showMobileSideFilter}
                >
                  <span>
                    <img src={Filter} alt="filter" id="filter" />
                  </span>
                  <label
                    htmlFor="filter"
                    className="medium text-black cursor-pointer ms-1 hide-on-mobile"
                  >
                    {hide ? "Hide Filters" : "Show Filters"}
                  </label>
                </div>
                <div className="t-items">
                  {hide && isChange ? (
                    <label
                      onClick={clearFilter}
                      className="medium ms-4 pointer"
                    >
                      Clear All
                    </label>
                  ) : (
                    <label
                      className={` medium ms-4 ${activeLayout === "row" && "d-none"
                        }`}
                    >
                      {itemsNumber} Items
                    </label>
                  )}
                </div>
              </div>

              <div className="right">
                {/* Sorting filter dropdown desktop*/}
                <div className="collection-grid hide-on-mobile">
                  <img
                    src={grid}
                    alt="grid"
                    className={
                      activeLayout === "grid" ? "active opacity-100 " : ""
                    }
                    onClick={() => handleActiveLayout("grid")}
                  />
                  <div className="divider"></div>
                  <img
                    src={row}
                    alt="grid"
                    className={
                      activeLayout === "row" ? "active opacity-100" : ""
                    }
                    onClick={() => handleActiveLayout("row")}
                  />
                  <div className="divider"></div>
                  <img
                    src={artwork}
                    alt="grid"
                    className={
                      activeLayout === "artwork" ? "active opacity-100" : ""
                    }
                    onClick={() => handleActiveLayout("artwork")}
                  />
                </div>

                {/* layout tabs for  mobile menu */}
                <div className="collection-grid-sm">
                  <span
                    className="hide-on-desktop d-flex align-items-center me-1"
                    onClick={handleShow}
                  >
                    <img
                      src={layout}
                      alt="layout"
                      className={activeLayout === "grid" ? "active " : ""}
                    />
                  </span>
                </div>
                <div className="recent-collection filter dropdown hide-on-mobile ">
                  <p
                    className="body-medium dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="hide-on-mobile">
                      {
                        collectionSortFilter.find(
                          (option) => option.value === activeCollection
                        )?.label
                      }
                    </span>
                    <span>
                      <img src={sortby} alt="sortby" />
                    </span>
                  </p>
                  <ul className="dropdown-menu">
                    {collectionSortFilter.map((option) => {
                      return (
                        <li
                          className="dropdown-item"
                          key={option.value}
                          onClick={() => handleIPriceTick(option.value)}
                        >
                          <img
                            src={tick}
                            alt="tick"
                            className={`${activeCollection === option.value
                              ? "active opacity-100"
                              : "opacity-0"
                              }`}
                          />
                          {option.label}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="hide-on-desktop ">
                  <span onClick={() => setCollectionMobileFilter(true)}>
                    <img src={sortby} alt="sortby" />
                  </span>
                </div>
              </div>
            </div>

            {activeLayout == "grid" ? (
              <UserCollectionGrid
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
                setLoading={setLoading}
              />
            ) : activeLayout == "row" ? (
              <UserCollectedRow
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
              />
            ) : activeLayout == "artwork" ? (
              <UserCollectedArtwork
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
              />
            ) : (
              <></>
            )}
          </div>
          <Offcanvas
            show={showSideFilter}
            onHide={closeMobileSideFilter}
            placement="bottom"
            className="sub-menu-offcanvas collection-multi-filter aside-filter-offcanvas"
          >
            <div className="more-menu-sm price-more-menu ">
              <div className="menu-head">
                <label htmlFor="">
                  {nftFilteredDetails?.length
                    ? nftFilteredDetails?.length
                    : "0"}{" "}
                  Items
                </label>
                <label className="text-black multi-filter-head">Filters</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={closeMobileSideFilter}
                  />
                </div>
              </div>

              {/* <AsideFilterExplore /> */}
              <AsideFilterExplore
                onSelectedFilterChange={handleSelectedFilterChange}
              />
            </div>
          </Offcanvas>

          <Offcanvas
            show={collectionMobileFilter}
            onHide={hideMobileSortFilter}
            placement="bottom"
            className="sub-menu-offcanvas"
          >
            <div className="more-menu-sm price-more-menu">
              <div className="menu-head">
                <label className="text-black">sort by</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={hideMobileSortFilter}
                  />
                </div>
              </div>

              <div className="share">
                {collectionSortFilter.map((option) => (
                  <label
                    key={option.value}
                    className={`no-text-transform h-64 text-black ${activeCollectionFilter === option.value
                      ? "fw-bold"
                      : "fw-normal"
                      }`}
                    onClick={() => handleCollectionTick(option.value)}
                  >
                    {option.label}
                    <img
                      src={tick}
                      alt="tick"
                      className={`${activeCollectionFilter === option.value
                        ? "active opacity-100"
                        : "opacity-0"
                        }`}
                    />
                  </label>
                ))}
              </div>
            </div>
          </Offcanvas>

          <Offcanvas
            show={mobileLayout}
            onHide={handleClose}
            placement="bottom"
            className="sub-menu-offcanvas"
          >
            <div className="more-menu-sm price-more-menu ">
              <div className="menu-head">
                <label className="text-black">layout</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={handleClose}
                  />
                </div>
              </div>

              <div className="share">
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("grid")}
                >
                  <div>
                    <img src={grid} alt="grid" className="me-3 " />
                    Grid Layout
                  </div>

                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "grid"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("row")}
                >
                  <div className="cursor-pointer">
                    <img src={row} alt="row" className="me-3 " />
                    Row Layout
                  </div>
                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "row"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("artwork")}
                >
                  <div className="cursor-pointer">
                    <img src={artwork} alt="artwork" className="me-3 " />
                    Artwork Layout
                  </div>

                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "artwork"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
              </div>
            </div>
          </Offcanvas>
        </div>
      )}
    </>
  );
};

export default CreatedArtwork;
