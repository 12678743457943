import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import FLR from "../../assets/icon//FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import chainImage from "../../assets/icon/SGB.svg";
import userProfile from "../../assets/images/face-3.png";
import vidIcon from "../../assets/icon/video-fill.svg";
import thumb from "../../assets/icon/profile-picture.svg";
import ArtistPopUp from "../shared/artistpopup";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import Web3 from "web3";
import CardSkelton from "../shared/cardSkelton";
import {
  getAllUsers,

  getArtLastPriceFromFirebase,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { Popover } from "antd";

const LayoutArtwork = (props) => {
  // market place contract address

  const Marketplace_coston_contractAddress =
    process.env.REACT_APP_COSTON_MARKEPLACE_CONTRACTADDRESS;
  const Marketplace_coston2_contractAddress =
    process.env.REACT_APP_COSTON2_MARKEPLACE_CONTRACTADDRESS;
  const contractABI = marketplaceContractABI;

  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [web3, setWeb3] = useState(null);
  const { address } = useAccount();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(collectionData);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  // setting rpc url

  useEffect(() => {
    if (collectionDetails?.selectedNetwork === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    }
    if (collectionDetails?.selectedNetwork === "Coston2") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [collectionDetails]);

  // get all listed data from blockchain

  const getAllListNFTData = async () => {
    if ( web3 !== null) {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "listed nfts data");

      setListedNfts(data);
    }
  };

  useEffect(() => {
    setNftDetails(props?.nftDetails);
    getAllListNFTData();
  }, [props?.nftDetails, web3]);

  // get connected users nfts from blockchain

  const getUserNftsFromContract = async (uri) => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        console.log(contract, "contract --------");
        const contractOwner = await contract.methods.owner().call();
        

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri === uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  // mapping listed data to nfts

  useEffect(() => {
    if (props?.nfts !== undefined) {
      let nftListDetails = [];
      console.log(listedNfts, "listed nfts");
  
      props?.nfts.forEach((item) => {
        let isSaleListed = false;
        let isOfferListed = false;
  
        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });
  
        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );
  
            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });
  
        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });
  
      console.log(nftListDetails, "nftdetails");
  
      // Fetch and set last price for each NFT
      nftListDetails.forEach(async (e) => {
        console.log(e?.data?.artId, "artid");
        let Price = await getArtLastPriceFromFirebase(e?.data?.artId);
        if (Price) {
          e.data.lastPrice = Price;
        }
      });
  
      bid(nftListDetails);
  
      setCollectionData(nftListDetails);
    } else {
      setCollectionData([]);
      setNftFilteredDetails([]);
    }
  }, [nftDetails, listedNfts, props?.nfts]);
  
  // calculating maximum offer price

  const bid = async (nfts) => {
    let data = nfts;
    let offerListed = nfts.filter((item) => item.isOfferListed);
    let MarketplaceAddress;

    if (collectionDetails?.selectedNetwork === "Coston") {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    // getting bidding history from blockchain-

    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
    const updatedDetails = await Promise.all(
      offerListed.map(async (item) => {
        const biddingHistoryCoston = await contract.methods
          .getBiddingHistory(item?.listCount)
          .call();

        // Calculate max price from bidding history
        const maxPrice = calculateMaxPrice(biddingHistoryCoston);

        return { ...item, maxPrice: maxPrice / 1000000000000000000 }; // Assuming maxPrice is in wei, converting to ether
      })
    );

    setNftFilteredDetails((prevDetails) => {
      const unchangedItems = data.filter((prevItem) => !prevItem.isOfferListed);
      const mergedDetails = [
        ...unchangedItems,
        ...updatedDetails.filter(Boolean),
      ];
      return mergedDetails;
    });
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (currentPrice && !isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };

  // Filters (Recently Minted, Recently Listed, Lowest Price, Highest Price)
  useEffect(() => {
    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = collectionData.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = collectionData
          .filter((item) => {
            return item.isListed;
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {
        if (collectionData?.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed;
            })
            .sort((a, b) => {
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(a?.listedData?.price) -
                  parseFloat(b?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(a?.listedData?.minimumBid) -
                  parseFloat(b?.listedData?.minimumBid)
                );
              }
            });
        }
      } else if (props?.sortFilter === "Highest price") {
        if (collectionData.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed; // Only keep listed items
            })
            .sort((a, b) => {
              // Prioritize sale listings over offer listings
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(b?.listedData?.price) -
                  parseFloat(a?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(b?.listedData?.minimumBid) -
                  parseFloat(a?.listedData?.minimumBid)
                );
              }
            });
        }
      }
      setNftFilteredDetails(sortedData);
    }
  }, [props?.sortFilter]);

  // side filter (buy now, offer now , price, category, network)
  const handleSelectedFilterChange = (newFilter) => {
    let filteredData = [];
    collectionData.filter((item) => {
      const isCategorySelected = Object.values(newFilter.selectCategories).some(
        (category) => category
      );
      let categoriesFilter;
      if (isCategorySelected) {
        categoriesFilter = Object.keys(newFilter.selectCategories).every(
          (category) =>
            newFilter.selectCategories[category]
              ? item.data.selectedTags.includes(category)
              : true
        );
      } else {
        categoriesFilter = true;
      }

      // Filter by currency
      const selectedCurrency = Object.values(newFilter.selectCurrency).some(
        (currency) => currency
      );
      let currencyFilter = true;

      if (selectedCurrency) {
        currencyFilter =
          selectedCurrency &&
          (newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr &&
              item.data.selectedBlockchain.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb &&
              item.data.selectedBlockchain.toLowerCase() ===
                "songbird network") ||
            (newFilter.selectCurrency.coston &&
              item.data.selectedBlockchain.toLowerCase() === "coston") ||
            (newFilter.selectCurrency.coston2 &&
              item.data.selectedBlockchain.toLowerCase() === "coston2"));
      }

      // Filter by price
      let priceFilter;
      if (
        newFilter.selectPrice.min === "" &&
        newFilter.selectPrice.max === ""
      ) {
        priceFilter = true;
      } else {
        if (item.isSaleListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.price / 1000000000000000000) >=
                parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.price / 1000000000000000000) <=
                parseFloat(newFilter.selectPrice.max));
        }
        if (item.isOfferListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.minimumBid) >=
                parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.minimumBid) <=
                parseFloat(newFilter.selectPrice.max));
        }
      }
      let statusFilter = false;

      if (
        newFilter.selectStatus.buyNow ||
        newFilter.selectStatus.onOffer ||
        newFilter.selectStatus.new
      ) {
        statusFilter =
          (newFilter.selectStatus.buyNow && item.isSaleListed) ||
          (newFilter.selectStatus.onOffer && item.isOfferListed) ||
          (newFilter.selectStatus.new && !item.isListed);
      } else {
        statusFilter = true;
      }
      // Return item if all filters pass
      if (categoriesFilter && currencyFilter && priceFilter && statusFilter) {
        filteredData.push(item);
      }
    });

    setNftFilteredDetails(filteredData);
  };

  const navigateToSingleArtwork = async (item) => {
    let collectionId = item?.data.selectedCollectionId;
    let network = item?.data.selectedBlockchain;
    let tokenId;
    if (item?.listedData) {
      tokenId = item?.listedData.tokenId;
    } else {
      tokenId = await item?.tokenId;
    }
    navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
  };

  const [userData, setUserData] = useState([]);

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@"+artistName;
  };



  // Filters (highest price, lowest , price, recently listed)
  useEffect(() => {
    let activeCollection = props?.activeCollection;

    if (nftFilteredDetails) {
      // Sorting by highest price
      if (activeCollection === "Highest price") {
        const sortByHighestPrice = [...nftFilteredDetails].sort((a, b) => {
          return b?.data && b?.data.listedData
            ? b?.data.listedData.price -
                (a?.data && a.data.listedData ? a?.data.listedData.price : 0)
            : 0;
        });
        setNftFilteredDetails(sortByHighestPrice);
      }
      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const sortByLowestPrice = [...nftFilteredDetails].sort((a, b) => {
          return (
            (a?.data && a?.data.listedData ? a?.data.listedData.price : 0) -
            (b?.data && b?.data.listedData ? b?.data.listedData.price : 0)
          );
        });
        setNftFilteredDetails(sortByLowestPrice);
      }
      // Getting the first 5 recently listed items
      else if (activeCollection === "Recently listed") {
        const recentlyAdded = [...nftFilteredDetails]
          .sort((a, b) => {
            return b?.data && b?.data?.listedData
              ? b?.data?.listedData.listTime -
                  (a?.data && a?.data.listedData
                    ? a?.data.listedData.listTime
                    : 0)
              : 0;
          })
          .slice(0, 5);
        setNftFilteredDetails(recentlyAdded);
      }
    }
  }, [nftFilteredDetails, props, props?.activeCollection]);

  // set number of total nfts

  useEffect(() => {
    props.setItemNumber(nftFilteredDetails.length);
  }, [nftFilteredDetails]);

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          {/* Filter */}
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
          />
        </div>
        <div
          className={`grid-display layout-artwork  ${
            props.flag ? "w-75 active" : ""
          }`}
        >
          {/* nfts */}
          {nftFilteredDetails.map((item, index) => {
            return (
              <div     onClick={() => {
                navigateToSingleArtwork(item);
              }} className="collection-grid-card" key={index}>
                <div className="card-body">
                  <div className="art-img">
                    {item?.data?.image ? (
                      <img
                        src={item?.data?.image}
                        className="img-100 artwork-hover"
                      />
                    ) : (
                      <img
                      src={item?.data?.previewImg}
                      className="img-100 artwork-hover"
                    />
                    )}
                    <img src={item.chainimg} className="chainImage" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LayoutArtwork;
